import ServerCookies from 'cookies'
import { type GetServerSidePropsContext } from 'next'
import { apiGet, apiPost } from '@/features/shared/utils/dataFetching'
import { API_URL } from '@/features/shared/utils/dataFetching/apiUrl'
import { type QueryFunctionContext } from '@tanstack/react-query'
import { getForwardedForIp } from '@/features/shared/serverUtils/ssrHelpers/utils'
import {
  type RegulationCategoryStateAndReason,
  type OptoutRestrictionsResponse,
} from '@/features/account/services/DataPrivacy/types'
import { type OptoutRestrictionsQueryKey } from '@/features/account/services/DataPrivacy/queries'
import { isOnServer } from '@shared/constants/util'
import { setOptOutRestrictionServerCookies } from '@/features/shared/serverUtils/data-privacy/utils'

export const fetchHandleOptOutCookies = (
  data?: RegulationCategoryStateAndReason
) =>
  apiPost({
    config: { url: '/opt-out', data },
    options: { apiUrl: API_URL.SELF_API },
    fetcherName: 'fetchHandleOptOutCookies',
  })

export const fetchOptOutRestrictions = async (
  context: QueryFunctionContext<OptoutRestrictionsQueryKey>
) => {
  const { state, zip_code } = context.queryKey[1]
  const ssrContext = context.meta?.ssrContext

  try {
    const ip = getForwardedForIp(ssrContext)

    const restrictionsResponse =
      await apiGet<OptoutRestrictionsResponse | void>({
        config: {
          url: '/data-rights/v1/privacy-compliance/opt-out-restrictions/',
          params: {
            state,
            zip_code,
            'x-user-ip': ip,
          },
        },
        context,
        fetcherName: 'fetchOptOutRestrictions',
      })

    const restrictionObject = restrictionsResponse?.restrictions?.find((r) => {
      return r.active && r.opt_out === 'do_not_share_sell_ta'
    })

    if (restrictionObject) {
      const regulationParams: RegulationCategoryStateAndReason = {
        regulation_category: restrictionObject.regulation_category,
        regulation_reason: restrictionObject.regulation_reason,
        regulation_state: restrictionObject.regulation_state,
      }
      await handleCookieBasedOnEnv(regulationParams, ssrContext)
    }

    return restrictionsResponse?.restrictions ?? []
  } catch (e) {
    // setting the cookies based on the environment and throwing the error
    await handleCookieBasedOnEnv(
      {
        regulation_category: '',
        regulation_reason: 'service_error',
        regulation_state: '',
      },
      ssrContext
    )
    throw e
  }
}

const handleCookieBasedOnEnv = async (
  regulationParams: RegulationCategoryStateAndReason,
  ssrContext?: GetServerSidePropsContext
) => {
  if (isOnServer()) {
    if (ssrContext) {
      setOptOutRestrictionServerCookies(
        new ServerCookies(ssrContext.req, ssrContext.res),
        regulationParams
      )
    }
  } else {
    // set the global SHIPT_DO_NOT_SHARE_SELL_TA variable
    // to true so that the Google Tag Manager scripts can be blocked.
    window.SHIPT_DO_NOT_SHARE_SELL_TA = true

    await fetchHandleOptOutCookies(regulationParams)
  }
}

export const setClientOptOutState = async () => {
  if (isOnServer()) return
  window.SHIPT_DO_NOT_SHARE_SELL_TA = true

  await fetchHandleOptOutCookies()
}
