import { type GetServerSidePropsContext } from 'next'
import axios, { type AxiosError } from 'axios'
import isEmpty from 'lodash/isEmpty'
import {
  type GetAccessTokenOptions,
  type AuthClient,
  type LogoutOptions,
} from '@/features/authentication/utils/authentication/client'
import {
  safeAsyncDebounce,
  isRequestUnauthorized,
  AuthenticationError,
} from '@/features/authentication/utils/authentication/utils'
import {
  _getAuthData,
  _setAuthData,
  _deleteAuthData,
} from '@/features/authentication/utils/authentication/client/legacy/persistence'
import { type OauthTokenAPI } from '@/features/account/services/User/types'
import {
  getIsTokenExpired,
  getAccessTokenCache,
} from '@/features/authentication/utils/authentication/client/legacy/utils'
import { logError } from '@/features/shared/utils/logger'
import { resetUser } from '@/features/authentication/utils/authentication'
import { legacyApiGatewayURL } from '@/features/shared/utils/dataFetching/url'
import { stringify } from '@/features/shared/utils/queryString'
import { isOnServer } from '@shared/constants/util'

const commitSHA = process.env.NEXT_PUBLIC_COMMIT_SHA

// DO NOT export and use this function directly
const _getAccessToken = async ({
  ssrContext,
  forceRefresh = false,
}: GetAccessTokenOptions = {}): Promise<string> => {
  return _getAuthData(ssrContext)
    .then((authData) => {
      return _validateAndRefreshAuthData(authData, forceRefresh, ssrContext)
    })
    .then((authData) => {
      if (!authData.access_token) {
        return Promise.reject(new Error('Missing access token'))
      }
      return authData.access_token
    })
}

// DO NOT export and use this function directly
const _serverGetAccessToken = ({
  ssrContext,
  forceRefresh = false,
}: GetAccessTokenOptions = {}): Promise<string> => {
  if (!ssrContext) {
    return Promise.reject(
      new Error('Missing ssrContext in _serverGetAccessToken')
    )
  }
  if (!isOnServer()) {
    return Promise.reject(
      new Error('_serverGetAccessToken must only be called on the server')
    )
  }
  const cache = getAccessTokenCache()
  let tokenPromise = cache.get(ssrContext.req)
  if (!tokenPromise) {
    tokenPromise = _getAccessToken({ ssrContext, forceRefresh })
    // NOTE: This cache does not take into account differences in forceRefresh
    // Ignoring this property reduces complexity, and is permissable because forceRefresh is so rare
    // and is only used in client code once, upon finishing auth0 signup. If this ever changes,
    // we will need to rethink this cache strategy
    cache.set(ssrContext.req, tokenPromise)
  }
  return tokenPromise
}

// DO NOT export and use this function directly
const _clientGetAccessToken = safeAsyncDebounce(function ({
  ssrContext,
  forceRefresh = false,
}: GetAccessTokenOptions = {}): Promise<string> {
  return _getAccessToken({ ssrContext, forceRefresh })
})

const _logout = async (options: LogoutOptions = {}) => {
  const { refresh_token: refreshToken } = await _getAuthData()
  if (!refreshToken) return resetApplication()
  const url = `${legacyApiGatewayURL}/auth/v2/refresh_tokens/revoke?segway_version=${commitSHA}`
  return axios(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
    },
    data: stringify({ refresh_token: refreshToken }),
  })
    .catch((err: Error) => {
      logError(err, {
        apiUrl: url,
        callLocation: 'utils/authentication-logout',
      })
    })
    .then(() => {
      return resetApplication(options)
    })
}

const _validateAndRefreshAuthData = async function (
  authData: Partial<OauthTokenAPI>,
  forceRefresh = false,
  ssrContext?: GetServerSidePropsContext
) {
  try {
    if (isEmpty(authData)) return {}
    if (
      forceRefresh ||
      !authData.access_token ||
      getIsTokenExpired(authData.expires_at)
    ) {
      const refreshedAuthData = await refreshAuthData(authData.refresh_token)
      await _setAuthData(refreshedAuthData, ssrContext)
      return refreshedAuthData
    }
    return authData
  } catch (err) {
    // If the refresh auth call failed we CANNOT continue. We must clear any auth data and logout.
    logError(new Error('Validating and refresh auth data error:'), {
      customGroupingHash: 'auth-data-error',
      err,
    })
    _logout()
    throw err
  }
}

const refreshAuthData = (refreshToken?: string) => {
  if (!refreshToken) {
    return Promise.reject(new Error('Missing refresh token'))
  }
  const body = stringify({
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
  })
  const url = `${legacyApiGatewayURL}/auth/v3/oauth/token?segway_version=${commitSHA}`
  return axios<OauthTokenAPI>(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-User-Type': 'Customer',
    },
    data: body,
  })
    .then((response) => {
      return response.data
    })
    .catch((error: AxiosError) => {
      if (error.response) {
        if (
          isRequestUnauthorized(
            error.response.status,
            error.response.config?.url
          )
        ) {
          return Promise.reject(new AuthenticationError())
        }
      }
      return Promise.reject(error)
    })
}

const _getIsTokenExpired = async () => {
  try {
    const { expires_at } = await _getAuthData()
    return getIsTokenExpired(expires_at)
  } catch {
    return false
  }
}

export const resetApplication = async (options: LogoutOptions = {}) => {
  await _deleteAuthData()
  resetUser(options)
}

export const _createClient = (): AuthClient => {
  return {
    getAccessToken: isOnServer()
      ? _serverGetAccessToken
      : _clientGetAccessToken,
    getIsTokenExpired: _getIsTokenExpired,
    logout: _logout,
    persistence: {
      getAuthData: _getAuthData,
      setAuthData: _setAuthData,
      deleteAuthData: _deleteAuthData,
    },
  }
}
