import styled, { css } from 'styled-components'
import { shade } from 'polished'
import { BaseButton } from '@/features/shared/elements/Buttons/ButtonBase'
import { LoadingIndicatorDot } from '@/features/shared/elements/LoadingIndicator'
import {
  staticColors,
  type StaticColor,
} from '@/features/shared/theme/staticColors'

/**
 * @deprecated Use { Button } from '@shipt/design-system-buttons' instead
 */
export const ColorButton = styled(BaseButton)<{
  color: Exclude<StaticColor, 'social'>
}>`
  color: ${staticColors.white};
  background-color: ${({ theme, color }) => theme[color]};
  border: none;

  &:hover,
  &:focus {
    color: ${staticColors.white};
    background-color: ${({ theme, color }) => shade(0.2, theme[color])};
  }

  &:active {
    color: ${staticColors.white};
    background-color: ${({ theme, color }) => shade(0.4, theme[color])};
  }

  ${({ theme, isLoading, color, disabled }) =>
    isLoading &&
    disabled &&
    css`
      &,
      &:hover,
      &:focus,
      &:active {
        color: ${staticColors.white};
        background-color: ${shade(0.4, theme[color])};
      }

      ${LoadingIndicatorDot} {
        background-color: ${theme.primaryContrast};
      }
    `}

  ${({ theme, disabled, isLoading }) =>
    !isLoading &&
    disabled &&
    css`
      &,
      &:hover,
      &:focus,
      &:active {
        color: ${theme.gray600};
        background-color: ${theme.gray300};
      }
    `}
`
