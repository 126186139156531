import { type RetailerLogosData } from '@/features/cms/components/CMS/types'
import { CMSErrorHandler } from '@/features/cms/components/CMS/CMSErrorHandler'
import { CMSContainer } from '@/features/cms/components/CMS/CMSContainer'
import { screenSizes } from '@shipt/design-system-themes'
import styled from 'styled-components'
import { Headline } from '@shipt/design-system-typography'
import { routes } from '@shared/constants/routes'
import { useCMSTrackingEvents } from '@/features/cms/components/CMS/CMSContext'
import { useMutationSelectStore } from '@/features/shop/services/ShoppingStore/mutations'
import {
  useUserDefaultShoppingAddressId,
  useUserId,
} from '@/features/account/services/User/hooks'
import { Image } from '@/features/shared/elements/Image/NextImage'
import { useRouter } from 'next/router'
import { useGuestUserContext } from '@/features/shared/context/GuestUserContext'
import { type GuestAddressOnSubmitParams } from '@/features/account/hooks/address/useOnSubmitGuestAddress'
import { useHandleDialog } from '@/features/shared/state/Dialog/useHandleDialog'
import { DynamicGuestAddressModal } from '@/features/account/components/Modal/GuestAddressModal/DynamicGuestAddressModal'
import { DynamicZipNotCoveredModal } from '@/features/account/components/Modal/ZipNotCoveredModal/DynamicZipNotCoveredModal'
import { NextLink } from '@/features/shared/elements/Link'
import { TransparentButton } from '@/features/shared/elements/Buttons'

export const RetailerLogos = ({
  content_type_id,
  id,
  data: {
    heading,
    heading_hypertext,
    stores,
    dynamic_store_query,
    disable_links,
  },
}: RetailerLogosData) => {
  const { trackCMSElementClicked } = useCMSTrackingEvents()
  const { openDialog } = useHandleDialog()
  const router = useRouter()
  const userId = useUserId()
  const { mutate: selectStore, isPending } = useMutationSelectStore()
  const defaultShoppingAddressId = useUserDefaultShoppingAddressId()
  const { setGuestStoreCookie } = useGuestUserContext() ?? {}

  // The heading_hypertext property is only set on dynamic stores responses
  const isDynamicStores = !!heading_hypertext
  const dynamicHeadingText =
    dynamic_store_query?.[0]?.data?.dynamic_heading.trim()

  const routeToGlobalHomepage = () => router.push(routes.GLOBAL_HOMEPAGE.url)

  const handleTrackElementClicked = () =>
    trackCMSElementClicked({
      type: 'button',
      content: 'select_delivery_address',
    })

  const handleDeliveryAreaClicked = () => {
    handleTrackElementClicked()
    openDialog(DynamicGuestAddressModal, {
      isAddStoreFlow: true,
      onSubmit: routeToGlobalHomepage,
    })
  }

  const handleOnSubmit = ({
    deliveryStores,
    retailerId,
  }: GuestAddressOnSubmitParams & { retailerId: number }) => {
    const store = deliveryStores.find((s) => s.id === retailerId)
    // navigate to the Retailer Homepage if store is available else navigate to the Global Homepage
    if (store) {
      setGuestStoreCookie?.(store)
      router.push(routes.RETAILER_HOMEPAGE.url)
    } else {
      routeToGlobalHomepage()
    }
  }

  const handleStoreClicked = (retailerId: number) => {
    const message_goal = isDynamicStores
      ? 'zip_auto_detect_stores'
      : 'static_marketing_stores'
    trackCMSElementClicked({
      type: 'link',
      content: 'retailer_icon_clicked',
      message_goal,
    })
    if (userId) {
      if (defaultShoppingAddressId) {
        return selectStore({
          store: { id: retailerId },
          addressId: defaultShoppingAddressId,
          isRoutingHome: true,
        })
      }
      return router.push(routes.GLOBAL_HOMEPAGE.url)
    }
    openDialog(DynamicGuestAddressModal, {
      contentLabel: 'Enter your delivery address',
      isAddStoreFlow: true,
      selectedStoreId: retailerId,
      onError: (zip: string) => openDialog(DynamicZipNotCoveredModal, { zip }),
      onSubmit: (args) => handleOnSubmit({ ...args, retailerId }),
    })
  }
  try {
    const DynamicLocationHeading = () => (
      <LocationHeading size="xl">{heading_hypertext}</LocationHeading>
    )
    const Heading = () => {
      if (disable_links) {
        return <Headline size="xl">Local gems. National favorites.</Headline>
      }
      if (isDynamicStores) {
        return (
          <DynamicHeading>
            <Headline size="xl">{dynamicHeadingText}&nbsp;</Headline>
            {userId ? (
              <NextLink
                href={routes.GLOBAL_HOMEPAGE.url}
                onClick={handleTrackElementClicked}
              >
                <DynamicLocationHeading />
              </NextLink>
            ) : (
              <TransparentButton
                onClick={handleDeliveryAreaClicked}
                aria-label="Click to change delivery area"
              >
                <DynamicLocationHeading />
              </TransparentButton>
            )}
          </DynamicHeading>
        )
      }
      return <Headline size="xl">{heading}</Headline>
    }

    return (
      <Container contentTypeId={content_type_id} id={id}>
        <InfoContainer>
          <Heading />
        </InfoContainer>
        <StoreLogoList>
          {stores.map(({ data }) => {
            const { logo, retailer_id: retailerId = 0 } = data ?? {}
            if (!logo?.src) return null
            return (
              <StoreLogoWrapper
                key={logo.src}
                {...(!disable_links && {
                  as: TransparentButton,
                  disabled: isPending,
                  onClick: () => handleStoreClicked(retailerId),
                })}
              >
                <Image
                  src={logo.src}
                  alt={logo.alt}
                  fill
                  style={{ objectFit: 'contain' }}
                  sizes="114px"
                  quality={100}
                />
              </StoreLogoWrapper>
            )
          })}
        </StoreLogoList>
      </Container>
    )
  } catch (error) {
    return (
      <CMSErrorHandler error={error} contentTypeId={content_type_id} id={id} />
    )
  }
}

const Container = styled(CMSContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DynamicHeading = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;

  @media ${screenSizes.tablet} {
    flex-direction: row;
    gap: 0;
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;

  @media ${screenSizes.tablet} {
    margin-bottom: 2rem;
  }
`

const LocationHeading = styled(Headline)`
  cursor: pointer;
  text-decoration: underline;
  color: ${({ theme }) => theme.purple500};
`

const StoreLogoList = styled.div`
  display: flex;
  gap: 1rem;
  width: fit-content;
  max-width: 100%;
  overflow: auto;
  justify-content: start;

  @media ${screenSizes.tablet} {
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
  }

  @media ${screenSizes.smDesktop} {
    gap: 1.5rem;
  }

  /* Hide overflow scrollbar */
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

const StoreLogoWrapper = styled.div`
  flex-shrink: 0;
  height: 4rem;
  width: 4rem;
  margin-bottom: 1rem;
  position: relative;

  @media ${screenSizes.tablet} {
    height: 5rem;
    width: 5rem;
  }

  @media ${screenSizes.smDesktop} {
    height: 6.25rem;
    width: 6.25rem;
  }

  @media ${screenSizes.lgDesktop} {
    height: 7.125rem;
    width: 7.125rem;
  }
`
