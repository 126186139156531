// eslint-disable-next-line no-restricted-imports -- Needed for util for NextImage and loader
import { type ImageLoader } from 'next/image'
import { logError } from '@/features/shared/utils/logger'
import { objectStorageUrl } from '@/features/shared/utils/objectStorageUrl'

export const fallbackSrcTypes = {
  generic: objectStorageUrl('fallback-image.webp'),
  store: objectStorageUrl('default-store.png'),
  product: objectStorageUrl('product-placeholder.png'),
  category: objectStorageUrl('category-backup-image.svg'),
}

export const fastlyAllowedHosts = new Set([
  'dsom-imager-prod.shipt.com',
  'dsom-imager.staging.shipt.com',
  'object-storage.shipt.com',
  'object-storage-staging.shipt.com',
])

type Props = Parameters<ImageLoader>[0] & { lossless?: boolean }

export const fastlyLoader = ({
  src,
  width,
  quality,
  lossless = false,
}: Props): ReturnType<ImageLoader> => {
  try {
    const url = new URL(src)
    quality = quality || 75

    // Custom param to enable fastly image optimizer in shipt Edge code
    url.searchParams.set('fio', 'true')

    // Browsers that support webp will automatically receive webp versions of images
    url.searchParams.set('auto', 'webp')

    // By default, deliver lossy WebP images if it is supported, otherwise deliver a progressive JPEG.
    // Passing lossless=true will deliver lossless WebP images if it is supported, otherwise deliver a PNG.
    // https://www.fastly.com/documentation/reference/io/auto/#examples
    if (lossless) {
      url.searchParams.set('quality', quality.toString())
    } else {
      // https://www.fastly.com/documentation/reference/io/quality/#examples
      url.searchParams.set('format', 'pjpg')
      url.searchParams.set('quality', `${quality},${quality}`)
    }

    url.searchParams.set('width', width.toString())
    return url.href
  } catch (e) {
    logError(
      new Error(`Fastly loader was called with a malformed src`),
      { imgSrc: src },
      { cause: e }
    )
    // We couldn't add the transform query params, return the orginal url
    return src
  }
}
