import ShiptAnalytics, {
  AnalyticsEvent,
  type FirstOrderPlaced,
} from '@shipt/analytics-member-web'
import { track } from '@/features/shared/analytics/trackingQueue'
import { formatPlanType } from '@/features/shared/analytics/deliveryPlans'
import {
  type Subscription,
  type SubscriptionPlan,
} from '@/features/account/services/MembershipPlan/types'
import { UserQuery } from '@/features/account/services/User/constants'
import { getUser } from '@/features/account/services/User/utils'
import { useQueryEnrolledBenefit } from '@/features/account/services/VisaBenefits/queries'
import { type VisaBenefitDetails } from '@/features/account/services/VisaBenefits/types'
import { getQueryClient } from '@/features/shared/utils/dataFetching/reactQuery/SegwayQueryClient'
import { isOnServer } from '@shared/constants/util'
import {
  getGuestTrait,
  getUserTraits,
  identify,
  shouldDisableAnalytics,
  urlPathnameToLocationName,
} from '@/features/shared/analytics/utils'
import { type ErrorMessage } from '@/features/shared/utils/dataFetching/types'
import {
  type CustomerBaseResponse,
  type User,
} from '@/features/account/services/User/types'
import { getDataRightsTrait } from '@/features/account/services/DataPrivacy/utils'

export type UserTraitsEvent = Parameters<typeof ShiptAnalytics.identify>[0] & {
  first_name: string
  last_name: string
  session_id?: number
  phone: string | null
  universal_id: string
  exempt: boolean
  do_not_share_sell_ta?: boolean
  subscription_status?: string
  zip?: string
  plan_type?: string
  marketing_phone?: string | null
}

export const trackRegistrationCompleted = (authSource: string | null) =>
  track({
    eventName: AnalyticsEvent.RegistrationCompleted,
    properties: {
      auth_source: authSource ?? '',
      location: urlPathnameToLocationName(),
    },
  })

export const trackLoginCompleted = (authSource: string | null) =>
  track({
    eventName: AnalyticsEvent.LoginCompleted,
    properties: {
      auth_source: authSource ?? '',
    },
  })

export const trackUserLoginError = (error: Maybe<ErrorMessage>) =>
  track({
    eventName: AnalyticsEvent.LoginError,
    properties: { error_context: error?.message || '' },
  })

export const identifyUser = async (user: CustomerBaseResponse) => {
  if (isOnServer() || shouldDisableAnalytics()) return
  const userTraits = {
    ...getUserTraits(user),
    ...getDataRightsTrait(),
    ...getGuestTrait(),
  }
  identify(user?.id, userTraits)
  ShiptAnalytics.identify(userTraits)
}

export const trackUserFirstOrderPlaced = (
  eventProperties: FirstOrderPlaced['properties']
) => {
  const user = getUser(getQueryClient())
  if (!user) return

  const userHasPlacedFirstOrder = Boolean(user.has_placed_first_order)
  if (userHasPlacedFirstOrder === false) {
    track({
      eventName: AnalyticsEvent.FirstOrderPlaced,
      properties: eventProperties,
      eventOptions: {
        sendImmediately: true,
      },
    })
    getQueryClient().setQueryData<User>([UserQuery], {
      ...user,
      has_placed_first_order: true,
    })
  }
}

export const trackFirstPurchased = (
  plan?: Maybe<SubscriptionPlan | Subscription>
) => {
  const user = getUser(getQueryClient())
  if (!user) return

  // track only if the user has never purchased ppo packs, subscription and order
  if (!user.has_purchased) {
    const queryClient = getQueryClient()
    if (plan) {
      const visaBenefits = queryClient.getQueryData<VisaBenefitDetails>(
        useQueryEnrolledBenefit.getKey(user.id)
      )
      const { planTerm, planType } = formatPlanType(plan, visaBenefits)
      const isTrial = Boolean(
        'trialDays' in plan ? plan.trialDays : plan.trial_period_days
      )
      track({
        eventName: AnalyticsEvent.FirstPurchased,
        properties: {
          is_ppo: false,
          is_trial: isTrial,
          plan_term: planTerm,
          plan_type: planType,
        },
        eventOptions: {
          sendImmediately: true,
        },
      })
    } else {
      track({
        eventName: AnalyticsEvent.FirstPurchased,
        properties: {
          is_ppo: true,
          is_trial: false,
          plan_type: 'one_time_ppo',
        },
        eventOptions: {
          sendImmediately: true,
        },
      })
    }
    queryClient.setQueryData<User>([UserQuery], {
      ...user,
      has_purchased: true,
    })
  }
}
