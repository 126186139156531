import { useEffect } from 'react'
import { routes } from '@shared/constants/routes'
import { useUser } from '@/features/account/services/User/hooks'
import { useRouter } from 'next/router'
import { useSession } from '@/features/authentication/utils/authentication/hooks'
import { type PageRouteData } from '@/features/shared/utils/setPageProps'

let hasChecked = false

export const useRoutingFlow = (routeData: PageRouteData) => {
  const user = useUser()
  const router = useRouter()
  const isUserLoaded = Boolean(user?.id)

  const requireGlobalHomepage =
    isUserLoaded && routeData?.storeRequired && !user?.store_location_id

  const { isSessionValid, isSessionLoading } = useSession()

  useEffect(() => {
    if (hasChecked) return
    if (isUserLoaded || (!isSessionLoading && !isSessionValid)) {
      hasChecked = true
    }

    if (routeData?.authentication && requireGlobalHomepage) {
      router.replace(
        `${routes.GLOBAL_HOMEPAGE.url}?next=${encodeURIComponent(
          router.asPath
        )}`
      )
    }
  }, [
    isUserLoaded,
    requireGlobalHomepage,
    router,
    isSessionLoading,
    isSessionValid,
    routeData?.authentication,
  ])

  // Reset check on location change
  useEffect(() => {
    return () => {
      hasChecked = false
    }
  }, [router.pathname])
}
