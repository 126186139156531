import { useStoreParams } from '@/features/shared/utils/dataFetching/storeParams'
import { useEffect, useRef } from 'react'
import { csSetStoreParams } from '@/lib/contentsquare'
import { type CSTrackStoreParams } from '@/lib/contentsquare/types'

export const useContentsquareSyncVars = () => {
  const storeParams = useStoreParams()
  const previousStoreParams = useRef<CSTrackStoreParams>(storeParams)
  useEffect(() => {
    // Defined individually so our useEffect triggers when one value changes
    // Also, if the StoreParams type changes this params var will complain
    // forcing that developer to think about how to update this code to
    // continue to work with any new fields.
    const params: CSTrackStoreParams = {
      user_id: storeParams.user_id,
      store_id: storeParams.store_id,
      store_location_id: storeParams.store_location_id,
      zip: storeParams.zip,
      metro_id: storeParams.metro_id,
    }
    csSetStoreParams(params, {
      previousStoreParams: previousStoreParams.current,
    })
    previousStoreParams.current = params
  }, [
    storeParams.user_id,
    storeParams.store_id,
    storeParams.store_location_id,
    storeParams.zip,
    storeParams.metro_id,
  ])
}
