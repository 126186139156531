import { type DeliveryPlanQueryKey } from '@/features/shop/services/DeliveryPlans/queries'
import { type FetchPackagesResponse } from '@/features/shop/services/DeliveryPlans/types'
import {
  type DowngradePlansQuery,
  type UpgradePlansQueryKey,
} from '@/features/account/services/MembershipPlan/queries'
import {
  type AuxEligiblePlansFetcherNames,
  type AuxMembershipPlan,
} from '@/features/account/services/SubscriptionAux/types'
import { normalizeSubAuxDeliveryPlans } from '@/features/account/services/SubscriptionAux/utils'
import { apiGet } from '@/features/shared/utils/dataFetching'
import { type QueryFunctionContext } from '@tanstack/react-query'

export const fetchSubscriptionAuxEligiblePlans = async (
  context: QueryFunctionContext<
    UpgradePlansQueryKey | DowngradePlansQuery | DeliveryPlanQueryKey
  >,
  isSubscriptionAux: boolean,
  fetcherName: AuxEligiblePlansFetcherNames
) => {
  if (isSubscriptionAux) {
    const response = await apiGet<AuxMembershipPlan[]>({
      config: {
        url: 'subscription-auxiliary/v1/plans',
      },
      context,
      fetcherName: 'fetchSubscriptionAuxEligiblePlans',
    })

    let plans = response
    if (fetcherName === 'useQueryUpgradePlans') {
      plans = response.filter((plan) => plan.plan_type === 'upgrade')
    } else if (fetcherName === 'useQueryDowngradePlans') {
      plans = response.filter((plan) => plan.plan_type === 'downgrade')
    }

    return normalizeSubAuxDeliveryPlans(plans)
  }

  const prepaidUrlsRecord = {
    useQueryDowngradePlans: 'prepaid-shops/v2/packages/downgrades',
    useQueryUpgradePlans: 'prepaid-shops/v2/packages/upgrades',
    useQueryDeliveryPlans: 'prepaid-shops/v2/packages',
  } as const
  const params = context.queryKey[1]
  const url = prepaidUrlsRecord[fetcherName]

  return apiGet<FetchPackagesResponse>({
    config: { url, params },
    context,
    fetcherName,
  })
}
