import { type Auth0Server, initAuth0 } from '@auth0/nextjs-auth0'
import { logDebug } from '@/features/shared/utils/logger'
import { getSessionSecret } from '@/features/shared/serverUtils/auth/utils'

export const isAuth0Enabled = () => process.env.AUTH0_ENABLED === 'true'

const getBaseURL = () => process.env.AUTH0_BASE_URL

let _auth0SDK: Auth0Server | undefined
export const getAuth0SDK = () => {
  if (!_auth0SDK) {
    try {
      _auth0SDK = initAuth0({
        baseURL: getBaseURL(),
        secret: getSessionSecret(),
        session: {
          absoluteDuration: false,
          // 6 months in seconds
          rollingDuration: 15778800,
          // Save space in the session cookie by not storing id_token
          storeIDToken: false,
        },
      })
    } catch {
      logDebug('Auth0 SDK failed to initialize. Are the env vars set?')
    }
  }
  return _auth0SDK
}

export * from './handlers'
