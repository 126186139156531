import {
  type GetRequestLocation,
  makeFetchGraphQL,
} from '@/features/shared/utils/dataFetching/reactQuery/graphql'
import { apiGatewayURL } from '@/features/shared/utils/dataFetching/url'

const getLegacyRequestLocation: GetRequestLocation = () => ({
  baseURL: apiGatewayURL,
  url: '/graphql',
})

export const fetchGraphql = makeFetchGraphQL({
  getRequestLocation: getLegacyRequestLocation,
})
