import { apiGet } from '@/features/shared/utils/dataFetching'
import { mfaPath } from '@/features/authentication/services/TwoFactorAuth/constants'
import { type TwoFactorChannelsResponse } from '@/features/authentication/services/Authentication/types'
import { type TwoFactorAuthPreferenceResponse } from '@/features/authentication/services/TwoFactorAuth/types'

export const fetchTwoFactorAuthPreference = () =>
  apiGet<TwoFactorAuthPreferenceResponse>({
    config: { url: `${mfaPath}/preference` },
    options: { forceLegacyGateway: true },
    fetcherName: 'fetchTwoFactorAuthPreference',
  })

export const fetchTwoFactorAuthChannels = () =>
  apiGet<TwoFactorChannelsResponse>({
    config: { url: `${mfaPath}/channels` },
    options: { forceLegacyGateway: true },
    fetcherName: 'fetchTwoFactorAuthChannels',
  })
