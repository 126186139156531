import { screenSizes } from '@shipt/design-system-themes'
import styled from 'styled-components'
import * as Accordion from '@radix-ui/react-accordion'
import { type FooterSection } from '@/features/shared/components/Footers/MarketingFooter/types'
import { ChevronDownIcon } from '@shipt/design-system-icons'
import { Callout1 } from '@/features/shared/styles/typography'
import { MarketingFooterLinks } from '@/features/shared/components/Footers/MarketingFooter/MarketingFooterLinks'
import { Column } from '@shipt/design-system-layouts'

export const MobileFooterAccordion = ({
  sections,
}: {
  sections: FooterSection[]
}) => {
  return (
    <Wrapper type="multiple">
      {sections.map(({ title, links, subSections }) => (
        <AccordionItem value={title} key={title}>
          <AccordionHeader>
            <Title>{title}</Title>
            <AccordionChevron />
          </AccordionHeader>
          <Accordion.Content>
            <Column spacing="xl">
              <MarketingFooterLinks links={links} />
            </Column>
            {subSections?.map(({ title: subTitle, links: subLinks }) => (
              <SubSection key={subTitle}>
                <Title>{subTitle}</Title>
                <Column spacing="xl">
                  <MarketingFooterLinks links={subLinks} />
                </Column>
              </SubSection>
            ))}
          </Accordion.Content>
        </AccordionItem>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled(Accordion.Root)`
  display: block;

  @media ${screenSizes.tablet} {
    display: none;
  }
`

const AccordionItem = styled(Accordion.Item)`
  border-bottom: 0.0625rem solid ${({ theme }) => theme.gray300};

  [data-state='open'] {
    padding-bottom: 1.1875rem;
  }

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 3rem;
  }
`

const AccordionHeader = styled(Accordion.Trigger)`
  color: inherit;
  padding: unset;
  padding-top: 1.1875rem;
  padding-bottom: 1.1875rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background: unset;
  border: unset;

  [data-state='open'] & {
    padding-bottom: 0;
  }
`

const Title = styled(Callout1)`
  text-transform: uppercase;
  margin-bottom: 1.5rem;
`

const AccordionChevron = styled(ChevronDownIcon)`
  [data-state='open'] & {
    transform: rotate(180deg);
  }
`

const SubSection = styled.div`
  margin-top: 1.5rem;
`
