import styled, { keyframes, css } from 'styled-components'
import { type StaticColor } from '@/features/shared/theme/staticColors'

type Props = {
  className?: string
  size?: 'xs' | 'sm' | 'md'
  color?: StaticColor
  isButton?: boolean
}

export const LoadingIndicator = ({
  isButton,
  size,
  color,
  className,
}: Props) => (
  <AnimationContainer isButton={isButton}>
    <DotContainer size={size} className={className}>
      <LoadingIndicatorDot color={color} />
      <LoadingIndicatorDot color={color} />
      <LoadingIndicatorDot color={color} />
      <LoadingIndicatorDot color={color} />
    </DotContainer>
  </AnimationContainer>
)

export const AnimationContainer = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.25rem;
  margin: ${({ isButton }) => (isButton ? '0 .5rem 0 0 ' : 'auto')};
`

const fade = keyframes`
0% {
    opacity: 20%
  }

  15% {
    opacity: 20%
  }

  50% {
    opacity: 100%
  }

  85% {
    opacity: 20%
  }

  100% {
    opacity: 20%
  }
`

export const LoadingIndicatorDot = styled.div<Props>`
  background-color: ${({ theme, color }) =>
    color ? theme[color] : theme.gray900};
  border-radius: 100%;
  opacity: 0.8;
  position: absolute;
  animation: ${fade} 1.3s ease-in-out infinite;
  width: 33%;
  height: 33%;
`

const DotContainer = styled.div<Props>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ size }) => {
    switch (size) {
      case 'xs':
        return css`
          width: 0.5rem;
          height: 0.5rem;
        `
      case 'sm':
        return css`
          width: 1.25rem;
          height: 1.25rem;
        `
      case 'md':
        return css`
          width: 1.75rem;
          height: 1.75rem;
        `
      default:
        return css`
          width: 3rem;
          height: 3rem;
        `
    }
  }}

  ${LoadingIndicatorDot}:nth-child(1) {
    transform: translateX(50%);
    top: 0%;
    right: 50%;
    animation-delay: 0s;
  }

  ${LoadingIndicatorDot}:nth-child(2) {
    transform: translateY(-50%);
    top: 50%;
    right: 0%;
    animation-delay: 0.375s;
  }

  ${LoadingIndicatorDot}:nth-child(3) {
    transform: translateX(50%);
    bottom: 0%;
    right: 50%;
    animation-delay: 0.75s;
  }

  ${LoadingIndicatorDot}:nth-child(4) {
    transform: translateY(-50%);
    top: 50%;
    left: 0%;
    animation-delay: 1.125s;
  }
`
