import { getQueryClient } from '@/features/shared/utils/dataFetching/reactQuery/SegwayQueryClient'
import { CouponsSavingsQuery } from '@/features/shop/services/Coupons/queries'
import {
  type Coupon,
  type CouponsById,
} from '@/features/shop/services/Coupons/types'

export const refreshCoupons = () => {
  return getQueryClient().invalidateQueries({ queryKey: [CouponsSavingsQuery] })
}

export const toCouponsById = (coupons: Coupon[]): CouponsById =>
  Object.fromEntries(coupons.map((coupon) => [coupon.couponId, coupon]))

export const resetCouponsUsageData = (coupons: CouponsById): CouponsById => {
  return Object.values(coupons).reduce<CouponsById>((acc, coupon) => {
    return { ...acc, [coupon.couponId]: resetCouponUsageData(coupon) }
  }, {})
}

export const resetCouponUsageData = (coupon: Coupon): Coupon => ({
  ...coupon,
  isFulfilled: false,
  completionPercent: 0,
  qualifiedProductIdsInOrder: [],
})
