import { useEffect, useState, type ElementType } from 'react'
import { TextButton } from '@shipt/design-system-buttons'
import styled from 'styled-components'
import { type ValidAddress } from '@/features/account/services/Addresses/types'
import { PillInput } from '@/features/shared/elements/Inputs/PillInput'
import { screenSizes } from '@shipt/design-system-themes'
import { Body1, Body2, Callout1 } from '@/features/shared/styles/typography'
import { fontWeights } from '@/features/shared/styles/typography/constants'
import {
  NoteEditIcon,
  SearchIcon,
  AddressIcon,
} from '@shipt/design-system-icons'
import { useCombobox } from '@/features/shared/hooks/a11y/useCombobox'
import { useLiveAnnouncer } from '@/features/shared/hooks/a11y/useLiveAnnouncer'
import { useAutocompleteSession } from '@/features/shop/services/GeoLocation/hooks'
import { type AutocompleteSuggestion } from '@/features/shop/services/GeoLocation/types'
import { useMutationFetchPlaceDetails } from '@/features/shop/services/GeoLocation/mutations'
import { useQueryAutocompleteSuggestions } from '@/features/shop/services/GeoLocation/queries'
import { useAsyncDebounce } from '@/features/shared/hooks/useAsyncDebounce'
import { useHandleDialog } from '@/features/shared/state/Dialog/useHandleDialog'
import {
  AnimationContainer,
  LoadingIndicator,
} from '@/features/shared/elements/LoadingIndicator'
import { Row } from '@shipt/design-system-layouts'
import { DynamicAddressModal } from '@/features/account/components/Modal/AddressModal/DynamicAddressModal'

const ENTER_DELIVERY_ADDRESS_BUTTON = 'Enter Delivery Address Button'

type Props = {
  id: string
  className?: string
  onSubmit: (address: ValidAddress) => void
  isModalFlow?: boolean
  isGlobalAddressModal?: boolean
  handleOnFocus?: () => void
  handleOnBlur?: () => void
  handleOnSelect?: () => void
  isSubmitting?: boolean
  inputLabel?: string
  icon?: ElementType
  isGrayBackground?: boolean
}

export const AddressAutocomplete = ({
  id,
  onSubmit,
  className,
  isModalFlow = false,
  isGlobalAddressModal = false,
  handleOnFocus,
  handleOnBlur,
  handleOnSelect,
  isSubmitting,
  inputLabel = 'Enter delivery address',
  icon: Icon = AddressIcon,
  isGrayBackground = false,
}: Props) => {
  const { openDialog } = useHandleDialog()
  const [query, setQuery] = useState('')
  const [debouncedQuery, setDebouncedQuery] = useState('')
  const [isTyping, setIsTyping] = useState(false)
  const { setLiveAnnouncer } = useLiveAnnouncer()
  const { refreshSessionToken, sessionToken } = useAutocompleteSession()
  const { mutate: fetchPlaceDetails } = useMutationFetchPlaceDetails()
  const { data: suggestions = [] } = useQueryAutocompleteSuggestions(
    debouncedQuery,
    sessionToken
  )
  const debouncedSetQuery = useAsyncDebounce(setDebouncedQuery, 500)

  useEffect(() => {
    const queryParts = query.split(' ')
    // Only call debouncedSetQuery when the query has two or more parts (e.g. - "123 A")
    if (queryParts.length > 1 && queryParts[1]?.length) {
      debouncedSetQuery(query)
    }
  }, [debouncedSetQuery, query])

  const handleOnSubmit = (submitQuery: string) => {
    setQuery(submitQuery)
    const [street1 = '', city = '', stateAndZip = ''] = submitQuery.split(', ')
    const [state = '', zip_code = ''] = stateAndZip.split(' ')
    return onSubmit({ street1, city, state, zip_code })
  }

  const onClear = () => {
    setIsTyping(false)
    setQuery('')
  }

  const onChange = (changedQuery: string) => {
    setIsTyping(Boolean(changedQuery))
    setQuery(changedQuery)
  }

  const onBlur = () => {
    handleOnBlur?.()
    setIsTyping(false)
  }

  const onFocus = async () => {
    setIsTyping(true)
    if (isGlobalAddressModal) {
      handleOnFocus?.()
    }
    if (query) {
      setDebouncedQuery(query)
    }
  }

  const onSuggestionSelect = async (description: string) => {
    handleOnSelect?.()
    refreshSessionToken()
    setIsTyping(false)
    if (description === ENTER_DELIVERY_ADDRESS_BUTTON) {
      openDialog(DynamicAddressModal)
      return
    }
    const suggestion = suggestions?.find((s) => s.description === description)

    if (suggestion) {
      fetchPlaceDetails(
        { placeId: suggestion.place_id, sessionToken },
        {
          onSuccess: (result) => {
            const { street1, city, state, zip_code } = result ?? {}
            handleOnSubmit(`${street1}, ${city}, ${state} ${zip_code}, USA`)
          },
        }
      )
    } else {
      handleOnSubmit(description)
    }
  }

  const itemToString = (item: AutocompleteSuggestion | string | undefined) =>
    (typeof item === 'string' ? item : item?.description) ?? ''

  const {
    activeIndex,
    getWrapperProperties,
    getContainerProperties,
    getInputProperties,
    getListboxProperties,
    getOptionProperties,
  } = useCombobox<AutocompleteSuggestion, HTMLDivElement>({
    id,
    items: suggestions ?? [],
    inputValue: query,
    inputOnChange: onChange,
    loop: true,
    initiallyExpanded: true,
    inputOnFocus: onFocus,
    inputOnBlur: onBlur,
    onSelect: onSuggestionSelect,
    itemToString,
  })

  useEffect(() => {
    if (activeIndex !== undefined && suggestions[activeIndex]?.description) {
      setLiveAnnouncer(suggestions[activeIndex].description)
    }
  }, [suggestions, activeIndex, setLiveAnnouncer])

  const { ref } = getWrapperProperties()
  const maxWidth = ref.current?.clientWidth ?? 0

  return (
    <div className={className} ref={ref} {...getContainerProperties()}>
      {isSubmitting ? (
        <LoadingWrapper>
          <Row spacing="sm" align="center">
            <LoadingIndicator size="md" />
            Saving address...
          </Row>
        </LoadingWrapper>
      ) : (
        <PillInput
          type="text"
          icon={isGlobalAddressModal ? SearchIcon : Icon}
          isGrayBackground={isGrayBackground || isGlobalAddressModal}
          hasSubmitButton={false}
          showError={false}
          handleClear={onClear}
          label={inputLabel}
          {...getInputProperties()}
        />
      )}
      {isTyping && !isSubmitting && (
        <AutocompleteSuggestionList
          suggestionsLength={suggestions.length}
          maxWidth={maxWidth}
          {...getListboxProperties()}
        >
          {suggestions.map((suggestion, index) => {
            const isActive = activeIndex === index
            return (
              <AutocompleteSuggestionItem
                key={suggestion.place_id}
                isActive={isActive}
                {...getOptionProperties(index)}
              >
                <InnerWrapper>
                  {(!isModalFlow || isGlobalAddressModal) && (
                    <div>
                      <AddressIcon width={25} height={25} />
                    </div>
                  )}
                  <div>
                    <Callout1 color="gray900" isPII>
                      {suggestion.structured_formatting.main_text}
                    </Callout1>
                    <LocationDetail isPII>
                      {suggestion.structured_formatting.secondary_text}
                    </LocationDetail>
                  </div>
                </InnerWrapper>
              </AutocompleteSuggestionItem>
            )
          })}
          {isGlobalAddressModal && (
            <>
              <Title>Don't see your address?</Title>
              <AutocompleteSuggestionItem isActive={false}>
                <TextButton
                  onClick={() =>
                    onSuggestionSelect(ENTER_DELIVERY_ADDRESS_BUTTON)
                  }
                >
                  <NoteEditIcon />
                  Enter Delivery Address
                </TextButton>
              </AutocompleteSuggestionItem>
            </>
          )}
        </AutocompleteSuggestionList>
      )}
    </div>
  )
}

const AutocompleteSuggestionList = styled.ul<{
  suggestionsLength: number
  maxWidth: number
}>`
  width: 100%;
  padding: 0;
  background-color: ${({ theme }) => theme.white};
  position: absolute;
  border-color: ${({ theme }) => theme.gray600};
  z-index: 5;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  border-radius: 1rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  overflow: auto;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  display: ${({ suggestionsLength }) =>
    suggestionsLength > 0 ? 'block' : 'none'};

  @media ${screenSizes.tablet} {
    max-width: ${({ maxWidth }) => `${maxWidth}px`};
  }
`

const AutocompleteSuggestionItem = styled.li<{ isActive: boolean }>`
  color: ${({ theme }) => theme.gray900};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.plum100 : theme.white};
  list-style-type: none;
  padding: 1rem;
  width: 100%;
  cursor: pointer;

  @media ${screenSizes.tablet} {
    padding: 1rem;
  }

  &:first-child {
    border-radius: 8px 8px 0 0;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${({ theme }) => theme.gray100};
  }
`
const InnerWrapper = styled.div`
  display: flex;

  svg {
    margin-right: 1rem;
  }
`
const LocationDetail = styled(Body2)`
  font-weight: ${fontWeights.book};
  color: ${({ theme }) => theme.gray600};
`
const Title = styled(Body1)`
  color: ${({ theme }) => theme.gray600};
  font-size: 1rem;
  padding-left: 1rem;
  cursor: default;
  margin-bottom: 0;
  margin-top: 0.25rem;
`

const LoadingWrapper = styled(Row)`
  height: 3rem;
  justify-content: center;

  ${AnimationContainer} {
    margin: 0;
  }
`
