import { type QueryFunctionContext } from '@tanstack/react-query'
import { apiGet } from '@/features/shared/utils/dataFetching'
import {
  type CheckUserEmailResponse,
  type User,
} from '@/features/account/services/User/types'
import { type UserQueryKey } from '@/features/account/services/User/queries'
import {
  getStoreLocationId,
  mapGqlUserToUser,
} from '@/features/account/services/User/utils'
import { useBaseUserQuery } from '@/features/account/services/User/graphql/BaseUser.generated'
import Bugsnag from '@bugsnag/js'

export const fetchUser = async (
  context: QueryFunctionContext<UserQueryKey>
): Promise<User> => {
  const response = await apiGet<User>({
    config: { url: 'api/v1/customer.json' },
    context,
    fetcherName: 'fetchUser',
  })
  const storeLocationId = getStoreLocationId(response)
  const user = {
    ...response,
    ...(storeLocationId && { store_location_id: storeLocationId }),
  }
  return user
}

export const fetchUserGraphqlQuery = async (
  context: QueryFunctionContext
): Promise<User> => {
  const data = await useBaseUserQuery.fetcher()(context, {
    // only throw if data is null. This is meant to make this query more resilient
    // to partial failures
    throwOnGqlError: false,
  })

  if (!data.customer) {
    Bugsnag.leaveBreadcrumb('Debug: no customer found error', { data })
    throw new Error('customer is not found')
  }

  return mapGqlUserToUser(data.customer)
}

export const checkUserEmail = (email: string) => {
  return apiGet<CheckUserEmailResponse>({
    config: {
      url: 'api/v1/customers/check.json',
      params: { email },
    },
    fetcherName: 'checkUserEmail',
  })
}
