import {
  fetchAutocompleteSuggestions,
  fetchCurrentLocation,
  fetchGeoLocationByIp,
} from '@/features/shop/services/GeoLocation/fetchers'
import { type GeoCoordinates } from '@/features/shop/services/GeoLocation/types'
import { queryOptions, useQuery } from '@tanstack/react-query'

const ReverseGeocodeQuery = 'Reverse geocode'
export type ReverseGeocodeQueryKey = [
  typeof ReverseGeocodeQuery,
  GeoCoordinates
]
export const reverseGeocodeQueryOptions = (coordinates: GeoCoordinates) =>
  queryOptions({
    queryKey: [ReverseGeocodeQuery, coordinates],
    queryFn: fetchCurrentLocation,
  })

const AutocompleteSuggestionsQuery = 'Autocomplete suggestions'
const getAutocompleteSuggestionsQueryKey = (query: string) =>
  [AutocompleteSuggestionsQuery, { query }] as const
export type AutocompleteSuggestionsQueryKey = ReturnType<
  typeof getAutocompleteSuggestionsQueryKey
>

export const useQueryAutocompleteSuggestions = (
  query: string,
  sessionToken: string
) => {
  return useQuery({
    queryKey: getAutocompleteSuggestionsQueryKey(query),
    queryFn: (context) => fetchAutocompleteSuggestions(context, sessionToken),
    enabled: Boolean(query),
    staleTime: Infinity,
    placeholderData: (previousData) => previousData,
  })
}

export const useQueryGeoLocationByIp = ({
  ip,
  enabled,
}: {
  ip: string
  enabled: boolean
}) => {
  return useQuery({
    queryKey: ['Geo location', ip],
    queryFn: () => fetchGeoLocationByIp(ip),
    enabled: Boolean(enabled),
    retryOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })
}
