import styled from 'styled-components'
import { HeaderLogoLink } from '@/features/shared/components/Headers/HeaderLogoLink'
import { GridLayoutContainer } from '@/features/shared/styles/layout'
import { Row } from '@shipt/design-system-layouts'
import { HeaderUserLinks } from '@/features/shared/components/Headers/HeaderUserLinks'

export const LogoOnlyHeader = () => {
  return (
    <GridLayoutContainer contentMaxWidth>
      <Wrapper className="content">
        <HeaderLogoLink />
        <HeaderUserLinks />
      </Wrapper>
    </GridLayoutContainer>
  )
}

const Wrapper = styled(Row)`
  align-items: center;
  justify-content: space-between;
  height: 5.25rem;
`
