import { type OauthTokenAPI } from '@/features/account/services/User/types'
import { logError } from '@/features/shared/utils/logger'
import { type ClientSafeSsrContext } from '@/features/shared/utils/setPageProps'
import { cookieName } from '@shared/constants/auth'
import Cookies from 'js-cookie'
import { type GetServerSidePropsContext } from 'next'

/**
 * A WeakMap is a great way of implementing a cache based on unique object keys.
 * The WeakMap holds a WeakRef to the key (the ssr request object), which means
 * it does not interfere with garbage collection. Furthermore, once a key is garbage
 * collected (ssr request is done and has been deleted), the corresponding value in
 * the WeakMap is also pruned and garbage collected, preventing memory leaks.
 */
let _accessTokenCache: WeakMap<
  GetServerSidePropsContext['req'],
  Promise<string>
>

export const getAccessTokenCache = () => {
  if (!_accessTokenCache) {
    _accessTokenCache = new WeakMap()
  }
  return _accessTokenCache
}

export const getIsTokenExpired = (expiresAt?: string): boolean => {
  // preempt the expiration by 60 seconds
  // (i.e. consider the token expired if it has less than 60 seconds of validity remaining)
  // this is to help prevent tokens from expiring in-flight
  if (!expiresAt) {
    return true
  }
  const preemptionInterval = 60000
  const expirationTimestamp = new Date(expiresAt).getTime()

  // Treat token as expired if not a valid date
  if (isNaN(expirationTimestamp)) {
    return true
  }

  return expirationTimestamp < Date.now() + preemptionInterval
}

export function getServerAuthData(
  ssrContext: ClientSafeSsrContext
): OauthTokenAPI {
  try {
    const cookies = ssrContext.req.cookies
    const stringifiedCookie: string =
      cookies?.[cookieName]?.replace('j:', '') || '{}'
    return JSON.parse(stringifiedCookie)
  } catch (e) {
    logError(e)
    // @ts-expect-error return empty object, it's ok
    return {}
  }
}

/**
 * @deprecated - Do not use this function; use `authContext.isAuthenticated` provided in the createServerSidePropsHandler callback
 * @param ssrContext
 * @returns
 */
export function hasServerAuthData(ssrContext: ClientSafeSsrContext): boolean {
  return Boolean(getServerAuthData(ssrContext)?.access_token)
}

/**
 * @deprecated - Do not use this function; use the `isSessionValid` property returned from the useSession() hook
 * @returns
 */
export const hasLegacyClientAuthData = () =>
  Cookies.get(process.env.NEXT_PUBLIC_AUTH_PRESENT_COOKIE_KEY!) === 'true'
