import {
  trackLoginCompleted,
  trackUserLoginError,
} from '@/features/shared/analytics/users'
import {
  type ChannelInfoType,
  type TwoFactorAuthPreferenceResponse,
  type ValidateCodePayload,
} from '@/features/authentication/services/TwoFactorAuth/types'
import { type OauthTokenAPI } from '@/features/account/services/User/types'
import { getAuthClient } from '@/features/authentication/utils/authentication/client'
import { apiDelete, apiPost } from '@/features/shared/utils/dataFetching'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useFetchUserQuery } from '@/features/account/services/User/hooks'
import {
  mfaPath,
  TwoFactorPreferenceQuery,
  TwoFactorChannelsQuery,
} from '@/features/authentication/services/TwoFactorAuth/constants'
import { useStoreParams } from '@/features/shared/utils/dataFetching/storeParams'
import { toError } from '@/features/shared/utils/dataFetching/utils'
import { useHandleDialog } from '@/features/shared/state/Dialog/useHandleDialog'
import { DynamicTwoFactorVerifyCodeModal } from '@/features/authentication/components/Modal/TwoFactorVerifyCodeModal/DynamicTwoFactorVerifyCodeModal'

export const useMutationEnableTwoFactorAuth = () => {
  const { openDialog } = useHandleDialog()
  const queryClient = useQueryClient()
  const storeParams = useStoreParams()

  return useMutation({
    mutationFn: (channelInfo: ChannelInfoType) =>
      apiPost<TwoFactorAuthPreferenceResponse>({
        config: {
          url: `${mfaPath}/preference`,
          data: { channel: channelInfo },
        },
        options: { storeParams, forceLegacyGateway: true },
        fetcherName: 'useMutationEnableTwoFactorAuth',
      }),
    onSuccess: (data) => {
      openDialog(DynamicTwoFactorVerifyCodeModal, {
        isTwoFactorSetting: true,
        channelId: data.mfa.channels?.[0]?.id ?? '',
      })

      queryClient.invalidateQueries({ queryKey: [TwoFactorPreferenceQuery] })
    },
  })
}

export const useMutationDisableTwoFactorAuth = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () =>
      apiDelete<void>({
        config: { url: `${mfaPath}/preference` },
        options: { forceLegacyGateway: true },
        fetcherName: 'useMutationDisableTwoFactorAuth',
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [TwoFactorPreferenceQuery] }),
  })
}

export const useMutationSendCodeToUnverifiedChannel = () => {
  const storeParams = useStoreParams()

  return useMutation({
    mutationFn: (channelId: string) =>
      apiPost<void>({
        config: { url: `${mfaPath}/preference/channels/${channelId}/codes` },
        options: { storeParams, forceLegacyGateway: true },
        fetcherName: 'useMutationSendCodeToUnverifiedChannel',
      }),
  })
}

export const useMutationValidateTwoFactorPreferenceCode = () => {
  const queryClient = useQueryClient()
  const storeParams = useStoreParams()

  return useMutation({
    mutationFn: (payload: ValidateCodePayload) =>
      apiPost<OauthTokenAPI>({
        config: {
          url: `${mfaPath}/preference/channels/${payload.channelId}/codes/${payload.code}`,
        },

        options: { storeParams, forceLegacyGateway: true },
        fetcherName: 'useMutationValidateTwoFactorPreferenceCode',
      }),
    onSuccess: (oAuthData) => {
      getAuthClient().persistence.setAuthData(oAuthData)
      queryClient.invalidateQueries({ queryKey: [TwoFactorPreferenceQuery] })
    },
  })
}

export const useMutationSendCodeToChannel = () => {
  const storeParams = useStoreParams()

  return useMutation({
    mutationFn: (channelId: string) =>
      apiPost<void>({
        config: { url: `${mfaPath}/channels/${channelId}/codes` },
        options: { storeParams, forceLegacyGateway: true },
        fetcherName: 'useMutationSendCodeToChannel',
      }),
    onError: (error) => {
      trackUserLoginError(toError(error))
    },
  })
}

export const useMutationValidateTwoFactorAuthCode = () => {
  const queryClient = useQueryClient()
  const fetchUserQuery = useFetchUserQuery()
  const storeParams = useStoreParams()

  return useMutation({
    mutationFn: (payload: ValidateCodePayload) =>
      apiPost<OauthTokenAPI>({
        config: {
          url: `${mfaPath}/channels/${payload.channelId}/codes/${payload.code}`,
        },

        options: {
          storeParams,
          ignoredMessages: ['Incorrect code'],
          forceLegacyGateway: true,
        },
        fetcherName: 'useMutationValidateTwoFactorAuthCode',
      }),
    onSuccess: async (oAuthData) => {
      await getAuthClient().persistence.setAuthData(oAuthData)
      await fetchUserQuery()
      trackLoginCompleted('')
      return queryClient.invalidateQueries({
        queryKey: [TwoFactorPreferenceQuery, TwoFactorChannelsQuery],
      })
    },
  })
}
