import { logError } from '@/features/shared/utils/logger'
import { isOnServer } from '@shared/constants/util'

export const setSessionStorageItem = (key: string, value: string) => {
  if (isOnServer()) return
  try {
    window.sessionStorage.setItem(key, value)
  } catch (e) {
    logError(e, { callLocation: 'setSessionStorageItem' })
  }
}

export const getSessionStorageItem = (key: string) => {
  if (isOnServer()) return
  try {
    return window.sessionStorage.getItem(key)
  } catch (e) {
    logError(e, { callLocation: 'getSessionStorageItem' })
    return null
  }
}
