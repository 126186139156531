import { useQueryClient } from '@tanstack/react-query'
import {
  type Address,
  type ValidAddress,
} from '@/features/account/services/Addresses/types'
import { useGuestUserContext } from '@/features/shared/context/GuestUserContext'
import {
  addressQueryOptions,
  useQueryAddresses,
} from '@/features/account/services/Addresses/queries'
import { useUserDefaultShoppingAddressId } from '@/features/account/services/User/hooks'

export const useGlobalDefaultShoppingAddress = (): ValidAddress | Address => {
  const defaultShoppingAddress = useDefaultShoppingAddress()
  const { guestAddress } = useGuestUserContext() ?? {}
  return guestAddress?.zip_code ? guestAddress : defaultShoppingAddress
}

export const useAddresses = () => {
  return useQueryAddresses().data
}

// Note that I used that non-null assertion for defaultAddress
// Because it is theoratically impossible for it to be null
// Except at the stage of first sign up, which is handled in
// useGlobalDefaultShoppingAddress hook
export const useDefaultShoppingAddress = (): Address => {
  const queryClient = useQueryClient()
  const defaultShoppingAddressId = useUserDefaultShoppingAddressId()
  const addresses = queryClient.getQueryData(addressQueryOptions.queryKey) ?? []
  const defaultAddress = addresses.find(
    (address) => address.id === defaultShoppingAddressId
  )!
  return defaultAddress || {}
}

export const useDefaultShoppingAddressTimezone = (): string =>
  useDefaultShoppingAddress().timezone

export const useDeliveryInstructions = (): string =>
  useDefaultShoppingAddress().delivery_instructions ?? ''
