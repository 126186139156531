import { type GetServerSidePropsContext } from 'next'
import { GuestDataStore } from '@/features/shared/constants/global'
import {
  type GuestUserParams,
  type GuestStoreParamsSSR,
} from '@/features/shared/context/GuestUserContext'
import { isJson } from '@/features/shared/utils/isJson'

export const getGuestStoreParams = ({
  guestStore,
  guestAddress,
}: GuestStoreParamsSSR) => ({
  // adding user_id with the value 0 so that it can be destructured
  // within useStoreParams even though a guest user does not have user_id
  user_id: 0,
  zip: guestAddress?.zip_code ?? '',
  store_id: guestStore?.id ?? null,
  metro_id: guestStore?.metro_id ?? null,
  store_location_id: guestStore?.store_location_id ?? null,
})

export const getGuestStoreParamsSSR = (
  ssrContext: GetServerSidePropsContext
) => {
  const {
    [GuestDataStore.STORE]: storeCookie,
    [GuestDataStore.ADDRESS]: addressCookie,
  } = ssrContext?.req?.cookies ?? {}
  const guestData: GuestUserParams = {
    guestStore: isJson(storeCookie) ? JSON.parse(storeCookie) : undefined,
    guestAddress: isJson(addressCookie) ? JSON.parse(addressCookie) : undefined,
  }
  return {
    ...guestData,
    guestStoreParams: getGuestStoreParams({ ...guestData, ssrContext }),
  }
}
