const nonce = process.env.NEXT_PUBLIC_NONCE_PLACEHOLDER

export const scripts = {
  // All page and track events MUST have their messageId set
  // we use `addSourceMiddleware` to override Segment's messageId with our own, so we can track the same event across multiple channels
  segment: `
  !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";t.setAttribute("nonce", "${nonce}");var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${process.env.NEXT_PUBLIC_SEGMENT_API_KEY}";analytics.SNIPPET_VERSION="4.15.3";
  analytics.load("${process.env.NEXT_PUBLIC_SEGMENT_API_KEY}", {
    // https://segment.com/docs/connections/destinations/catalog
    integrations: {
      'Segment.io': {
        deliveryStrategy: {
          strategy: 'batching',
          config: {
            size: 20,
            timeout: 5000
          }
        }
      }
    }
  });
  }}();
`,
  contentsquare: `
  (function() {
    if (!"${process.env.NEXT_PUBLIC_CONTENTSQUARE_TAG_ID || ''}") { return }
    window._uxa = window._uxa || [];
    // NOTE: Please update src/lib/contentsquare if changed
    var CSMaskQueryStrings = {
      access_token: 'CS_ANONYMIZED_ACCESS_TOKEN',
      address: 'CS_ANONYMIZED_ADDRESS',
      ID: 'CS_ANONYMIZED_ID',
      FirstName: 'CS_ANONYMIZED_FIRST_NAME',
      LastName: 'CS_ANONYMIZED_LAST_NAME',
      name: 'CS_ANONYMIZED_NAME',
      password: 'CS_ANONYMIZED_PASSWORD',
      provider: 'CS_ANONYMIZED_PROVIDER',
      refresh_token: 'CS_ANONYMIZED_REFRESH_TOKEN',
      token_type: 'CS_ANONYMIZED_TOKEN_TYPE',
      zip: 'CS_ANONYMIZED_ZIP',
      zipcode: 'CS_ANONYMIZED_ZIPCODE',
    };
    // NOTE: Please update src/lib/contentsquare if changed
    function csMaskKnownQueryStrings(search) {
      if (!search) {
        return;
      }
      var searchParams = new URLSearchParams(search);
      var size = Array.from(searchParams).length;
      if (size === 0) {
        return;
      }
      Object.entries(CSMaskQueryStrings).forEach(function([key, maskValue]) {
        if (searchParams.has(key)) {
          searchParams.set(key, maskValue);
        }
      });
      window._uxa.push(['setQuery', '?' + searchParams.toString()]);
    }
    if (typeof CS_CONF === 'undefined') {
      csMaskKnownQueryStrings(window.location.search);
      window._uxa.push(['setPath', window.location.pathname+window.location.hash.replace('#','?__')]);
      var mt = document.createElement('script'); mt.type = 'text/javascript'; mt.async = true;
      mt.src = '//t.contentsquare.net/uxa/${
        process.env.NEXT_PUBLIC_CONTENTSQUARE_TAG_ID
      }.js';
      mt.setAttribute('nonce', '${nonce}');
      document.getElementsByTagName('head')[0].appendChild(mt);
    }
    else {
      csMaskKnownQueryStrings(window.location.search);
      window._uxa.push(['trackPageview', window.location.pathname+window.location.hash.replace('#','?__')]);
    }
  })();
`,
  branch: `if("${process.env.NEXT_PUBLIC_BRANCH_KEY || ''}"){
  // load Branch
  (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(" "), 0);
  // init Branch
  branch.init("${process.env.NEXT_PUBLIC_BRANCH_KEY}", { nonce: "${nonce}"});
}
`,
  mouseflow: `
  window._mfq = window._mfq || [];
  (function () {
    var mf = document.createElement("script");
    mf.type = "text/javascript"; mf.async = true;
    mf.src = "//cdn.mouseflow.com/projects/${process.env.NEXT_PUBLIC_MOUSEFLOW_KEY}.js";
    document.getElementsByTagName("head")[0].appendChild(mf);
  })();
`,
  googletagmanager: `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'${process.env.NEXT_PUBLIC_GTM_CONTAINER_URL}/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_CONTAINER_ID}');
`,
  sprinklr: `
    window.sprChatSettings = window.sprChatSettings || {}; 
    window.sprChatSettings = {"appId":"${process.env.NEXT_PUBLIC_SPRINKLR_APP_ID}","skin":"MODERN"};
    (function(){var t=window,e=t.sprChat,a=e&&!!e.loaded,n=document,r=function(){r.m(arguments)};r.q=[],r.m=function(t){r.q.push(t)},t.sprChat=a?e:r;var e2=t.sprTeamChat,r2=function(){r2.m(arguments)};r2.q=[],r2.m=function(t){r2.q.push(t)},t.sprTeamChat=e2?e2:r2;var o=function(){var e=n.createElement("script"); 
    e.type="text/javascript",e.async=!0,e.src="https://prod2-live-chat.sprinklr.com/api/livechat/handshake/widget/"+t.sprChatSettings.appId; 
    e.setAttribute("nonce", "${nonce}");
    e.setAttribute("data-spr-nonce", "${nonce}");
    e.onerror=function(){t.sprChat.loaded=!1},e.onload=function(){t.sprChat.loaded=!0};var a=n.getElementsByTagName("script")[0];a.parentNode.insertBefore(e,a)};"function"==typeof e?a?e("update",t.sprChatSettings):o():"loading"!==n.readyState?o():n.addEventListener("DOMContentLoaded",o)})()
`,
}
