import styled from 'styled-components'
import { screenSizes } from '@shipt/design-system-themes'
import { VStack } from '@/features/shared/elements/Stack'
import {
  Body1,
  TagsTitle1,
  TitleClaremont0,
} from '@/features/shared/styles/typography'
import { Image } from '@/features/shared/elements/Image/NextImage'
import { objectStorageUrl } from '@/features/shared/utils/objectStorageUrl'

export const StarsOfShipt = () => {
  return (
    <StackWrapper>
      <GreenBackground>
        <ContentWrapper>
          <StarsOfShiptTitle>STARS OF SHIPT</StarsOfShiptTitle>
          <ShopperName>Tracey L.</ShopperName>
          <StoryDescription>
            Tracey has been a shopper for more than three years and in that time
            she’s witnessed her Shipt community and their families grow.
          </StoryDescription>
        </ContentWrapper>
      </GreenBackground>
      <StarImage
        height={300}
        width={300}
        alt=""
        src={objectStorageUrl('star-illustration.webp')}
      />
      <ShopperImageWrapper>
        <Image
          alt="Shopper Image"
          src={objectStorageUrl('star-shopper-homepage.webp')}
          width={1108}
          height={1108}
          style={{ width: '100%', height: '100%' }}
        />
      </ShopperImageWrapper>
    </StackWrapper>
  )
}

const StarImage = styled(Image)`
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: -1.25rem;
  margin: auto;

  @media ${screenSizes.max_mobile} {
    display: none;
  }

  @media ${screenSizes.tablet} {
    top: -4.375rem;
  }

  @media ${screenSizes.smDesktop} {
    top: 0;
    bottom: 0;
  }
`

const StackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media ${screenSizes.tablet} {
    position: relative;
    flex-direction: row;
  }
`

const GreenBackground = styled(VStack)`
  position: relative;
  flex-basis: 50%;
  background-color: ${({ theme }) => theme.green500};

  @media ${screenSizes.tablet} {
    justify-content: center;
  }
`

const ContentWrapper = styled.div`
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding-bottom: 3.125rem;

  @media ${screenSizes.tablet} {
    margin-left: auto;
    margin-right: auto;
    width: 17.5rem;
  }

  @media ${screenSizes.smDesktop} {
    align-self: flex-end;
    margin: 0;
    width: 24.75rem;
    margin-right: 10rem;
  }

  @media ${screenSizes.lgDesktop} {
    margin-right: 14rem;
  }
`

const StarsOfShiptTitle = styled(TagsTitle1)`
  margin-top: 3.125rem;

  @media ${screenSizes.tablet} {
    font-size: 1rem;
  }
`

const ShopperName = styled(TitleClaremont0)`
  margin-top: 1rem;
`

const StoryDescription = styled(Body1)`
  margin-top: 1.125rem;
  margin-bottom: 1.5rem;

  @media ${screenSizes.tablet} {
    margin-bottom: 2rem;
  }
`

const ShopperImageWrapper = styled.div`
  /* allows the StarAnimation to be placed correctly */
  position: relative;
  display: flex;
  flex-basis: 50%;
  object-fit: cover;

  @media ${screenSizes.max_mobile} {
    order: -1;
  }
`
