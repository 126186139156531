import { type QueryFunctionContext } from '@tanstack/react-query'
import { cleanFlags } from '@/features/shared/services/FeatureFlags/utils'
import { apiGet } from '@/features/shared/utils/dataFetching'
import { type FeatureFlagsKey } from '@/features/shared/services/FeatureFlags/queries'
import { type HttpFeatureFlagsResponse } from '@/features/shared/services/FeatureFlags/types'

export const fetchFeatureFlags = async (
  context: QueryFunctionContext<FeatureFlagsKey>
) => {
  const [, params] = context.queryKey
  try {
    const data = await apiGet<HttpFeatureFlagsResponse>({
      config: {
        url: 'feature-flags/v1/flagsWithContext',
        params: {
          ServiceName: 'Segway',
          Store: params?.store_id ?? 0,
          Location: params?.store_location_id ?? 0,
          Metro: params?.metro_id ?? 0,
          User: params?.user_id ?? 0,
        },
      },
      options: {
        includeAuthHeader: Boolean(params?.user_id),
      },
      fetcherName: 'fetchFeatureFlags',
    })
    return cleanFlags(data)
  } catch {
    return cleanFlags(undefined)
  }
}
