import { Button } from '@shipt/design-system-buttons'
import { LogoMarkOnlyIcon } from '@shipt/design-system-icons'
import { Stack } from '@shipt/design-system-layouts'
import { Body, Headline } from '@shipt/design-system-typography'
import { transparentize } from 'polished'
import { useState } from 'react'
import styled from 'styled-components'
import UAParser from 'ua-parser-js'
import { getItem, setItem } from '@/features/shared/utils/localStorage'
import { trackElementClicked } from '@/features/shared/analytics/element'
import { getAnonymousId } from '@shipt/analytics-member-web'
import { useIsomorphicLayoutEffect } from 'react-use'
import { AnimatePresence, type Variants, m } from 'framer-motion'
import { ButtonLink } from '@/features/shared/elements/Link/Buttons'
import { useHydrated } from '@/features/shared/components/ClientOnly'

const CONTINUE_IN_APP_DIALOG_DISMISSED = 'continue_in_app_dialog_dismissed'

export const ContinueInAppDialog = () => {
  const hydrated = useHydrated()
  const [isShown, setIsShown] = useState(false)
  const anonymousId = getAnonymousId()

  if (
    !isShown &&
    hydrated &&
    new UAParser().getDevice().type === 'mobile' &&
    !getItem(CONTINUE_IN_APP_DIALOG_DISMISSED)
  ) {
    setIsShown(true)
  }

  const deepLink = `https://xhmm.app.link/biem7QzclCb?web_anonymous_id=${anonymousId}`

  const dismissDialog = () => {
    setIsShown(false)
    setItem(CONTINUE_IN_APP_DIALOG_DISMISSED, 'true')
  }

  const handleClick = (
    content: 'continue_in_app' | 'continue_in_browser' | 'dismissible_overlay'
  ) => {
    trackElementClicked(
      {
        content,
        type: content === 'dismissible_overlay' ? 'overlay' : 'button',
      },
      { sendImmediately: true }
    )
    dismissDialog()
  }

  useIsomorphicLayoutEffect(() => {
    // When the dialog is shown, we should prevent scrolling in the background
    if (isShown) {
      document.body.style.overflow = 'hidden'
    } else {
      // When the dialog is closed, we should re-enable scrolling
      document.body.style.overflow = ''
    }

    // We should re-enable scrolling when the component is unmounted
    return () => {
      document.body.style.overflow = ''
    }
  }, [isShown])

  const overlayVariants: Variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  }

  const dialogVariants: Variants = {
    hidden: {
      transform: 'translateY(200%)',
    },
    visible: { transform: 'translateY(0%)' },
  }

  return (
    <AnimatePresence>
      {isShown && (
        <m.div initial="hidden" animate="visible" exit="hidden">
          <BGOverlay
            variants={overlayVariants}
            transition={{ duration: 0.5, delay: 0.2 }}
            onClick={() => handleClick('dismissible_overlay')}
          />
          <Dialog
            direction="column"
            align="center"
            justify="center"
            spacing="md"
            variants={dialogVariants}
            transition={{ duration: 0.3, delay: 0.4 }}
          >
            <Logo align="center" justify="center">
              <LogoMarkOnlyIcon />
            </Logo>
            <Headline size="lg">Enjoy the perks of the app</Headline>
            <Body variant="secondary" align="center">
              Shop, track orders, get updates on new deals, and more.
            </Body>
            <ButtonLink
              href={deepLink}
              fullWidth
              onClick={() => handleClick('continue_in_app')}
            >
              Continue in app
            </ButtonLink>
            <Button
              fullWidth
              variant="ghost"
              onClick={() => handleClick('continue_in_browser')}
            >
              Continue in browser
            </Button>
          </Dialog>
        </m.div>
      )}
    </AnimatePresence>
  )
}

const BGOverlay = styled(m.div)`
  position: fixed;
  background-color: ${({ theme }) => transparentize(0.4, theme.gray600)};
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 9999999999;
`

const Dialog = styled(m(Stack))`
  position: absolute;
  bottom: 0;
  background-color: white;
  width: 100%;
  border-radius: 1rem 1rem 0 0;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  padding: 3rem 1.5rem 1rem;
  z-index: 9999999999;
`

const Logo = styled(Stack)`
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  width: 4rem;
  height: 4rem;
  background-color: ${({ theme }) => theme.plum};
  border-radius: 100%;
  border: 2px solid white;
`
