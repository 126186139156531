import { socialLinks } from '@/features/shared/constants/socialLinks'
import { cmsRoutes, routes } from '@shared/constants/routes'
import { type FooterData } from '@/features/shared/components/Footers/MarketingFooter/types'
import { MAKE_MONEY_LINKS } from '@/features/shared/constants/layouts'
import { LEGAL_LINKS } from '@/features/shared/constants/legalLinks'

export const privacy = [
  {
    url: LEGAL_LINKS.TERMS_OF_SERVICE,
    name: 'Terms of service',
  },
  {
    url: LEGAL_LINKS.PRIVACY_POLICY,
    name: 'Privacy',
  },
  {
    url: LEGAL_LINKS.CA_PRIVACY_RIGHTS,
    name: 'CA privacy rights',
  },
  {
    url: 'https://www.shipt.com/privacy/intake-form',
    name: 'Your privacy choices',
    image: {
      alt: 'Privacy Selection Icon',
      height: 14,
      src: '@/svg/privacy_icon.svg',
      width: 29,
    },
  },
  {
    url: LEGAL_LINKS.WA_PRIVACY_RIGHTS,
    name: 'WA Health Privacy',
  },
  {
    url: LEGAL_LINKS.INTERNET_BASED_ADS,
    name: 'Interest-based ads',
  },
] as const satisfies FooterData['privacy']

export const footerData = {
  sections: [
    {
      title: 'Company',
      links: [
        {
          url: 'https://www.shipt.com/about/',
          name: 'About us',
          external: true,
        },
        {
          url: 'https://www.shipt.com/corporate/careers/',
          name: 'Careers',
          external: true,
        },
        {
          url: 'https://corporate.shipt.com/news',
          name: 'Newsroom',
          external: true,
        },
        {
          url: 'https://partner.shipt.com/join',
          name: 'Partners',
          external: true,
        },
        {
          url: routes.CITIES.url,
          name: 'Cities',
        },
        {
          url: routes.STORES.url,
          name: 'Stores',
        },
        {
          url: routes.BLOG.url,
          name: 'Blog',
        },
        {
          url: `${routes.DELIVERY.url}/grocery-delivery`,
          name: 'Grocery delivery',
        },
        {
          url: routes.SITEMAP_INDEX.url,
          name: 'Sitemap',
        },
      ],
    },
    {
      links: [
        {
          url: routes.DELIVERY.url,
          name: 'Delivery',
        },
        {
          url: `${routes.LP.url}/shipt-vs-instacart`,
          name: 'Why Shipt',
        },
        {
          url: 'https://help.shipt.com/how-shipt-works',
          name: 'How Shipt works',
          external: true,
        },
        {
          url: routes.PRICING.url,
          name: 'Membership',
        },
        {
          url: routes.GIFT.url,
          name: 'Gift Cards',
        },
        {
          url: routes.PASSWORD_RESET.url,
          name: 'Password reset',
        },
      ],
      title: 'Members',
    },
    {
      links: [
        ...MAKE_MONEY_LINKS,
        {
          url: cmsRoutes.SHOPPER.FAQ,
          name: 'FAQs',
        },
        {
          url: cmsRoutes.SHOPPER.SAFETY,
          name: 'Safety',
        },
        {
          url: cmsRoutes.SHOPPER.PERKS,
          name: 'Perks',
        },
      ],
      title: 'Make money with us',
    },
    {
      links: [
        {
          url: 'https://help.shipt.com/',
          name: 'Help',
          external: true,
        },
        {
          url: 'https://help.shipt.com/',
          name: 'FAQs',
          external: true,
        },
        {
          url: routes.CONTACT.url,
          name: 'Contact us',
        },
      ],
      title: 'Support',
      subSections: [
        {
          links: [
            {
              url: 'https://healthy.kaiserpermanente.org/front-door/machine-readable',
              name: 'Kaiser Permanente',
              target: '_blank',
              external: true,
            },
            {
              url: 'https://www.bcbsal.org/web/tcr',
              name: 'BCBS of Alabama',
              target: '_blank',
              external: true,
            },
          ],
          title: 'HealthPlan Transparency',
        },
      ],
    },
  ],
  socialLinks: [
    {
      url: socialLinks.FACEBOOK,
      name: 'facebook',
      opensInNewTab: true,
    },
    {
      url: socialLinks.INSTAGRAM,
      name: 'instagram',
      opensInNewTab: true,
    },
    {
      url: socialLinks.PINTEREST,
      name: 'pinterest',
      opensInNewTab: true,
    },
    {
      url: socialLinks.TWITTER,
      name: 'twitter',
      opensInNewTab: true,
    },
  ],
  privacy,
} as const satisfies FooterData

export const shopperPrivacy = [
  ...privacy,
  {
    url: 'https://healthy.kaiserpermanente.org/front-door/machine-readable',
    name: 'Kaiser Permanente',
  },
  {
    url: 'https://www.bcbsal.org/web/tcr',
    name: 'BCBS of Alabama',
  },
]

export const shopperFooterSections: FooterData['sections'] = [
  {
    title: 'Make money with us',
    links: MAKE_MONEY_LINKS,
  },
  {
    title: 'About Shipt',
    links: [
      { url: routes.CITIES.url, name: 'Cities' },
      { url: routes.STORES.url, name: 'Stores' },
      { url: '/shopper-explore', name: 'Explore' },
      { url: cmsRoutes.SHOPPER.FAQ, name: 'FAQs' },
    ],
  },
  {
    title: 'The shopper experience',
    links: [
      { url: cmsRoutes.SHOPPER.PERKS, name: 'Shopper perks' },
      { url: cmsRoutes.SHOPPER.SAFETY, name: 'Shopper safety' },
      { url: cmsRoutes.SHOPPER.LIFE, name: 'Shopper life' },
    ],
  },
  {
    title: 'Sign up',
    links: [{ url: routes.SIGN_UP.url, name: 'Sign up today' }],
  },
]
