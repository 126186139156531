import {
  useState,
  createContext,
  useMemo,
  useContext,
  useRef,
  type ReactNode,
  type MutableRefObject,
} from 'react'
import { type DynamicDialogComponent } from '@/features/shared/state/Dialog/types'
import { GlobalStoreContextProvider } from '@/features/shared/context/GlobalStoreContext'
import { type StoreParams } from '@/features/shared/utils/dataFetching/storeParams'
import { useSetAtom } from 'jotai'
import {
  dialogAtom,
  initialDialogValue,
} from '@/features/shared/state/Dialog/atom'
import { type Content } from '@/features/cms/components/CMS/types'
import { CMSContextProvider } from '@/features/cms/components/CMS/CMSContext'

type DialogContentState = {
  component: DynamicDialogComponent | null
  props:
    | (Record<string, unknown> & {
        globalStoreParams: StoreParams | undefined
        cmsData: Content | null | undefined
      })
    | undefined
}

export const InitialDialogState: DialogContentState = {
  component: null,
  props: { globalStoreParams: undefined, cmsData: undefined },
}

export const DialogContainer = ({ children }: { children: ReactNode }) => {
  const setAtom = useSetAtom(dialogAtom)
  const [dialogState, setDialogState] = useState(InitialDialogState)

  const { globalStoreParams, cmsData, ...restStateData } =
    dialogState.props ?? {}
  // this is to get the current dialog content state without
  // the need to re-render the component due to state changes
  const getDialogState = useRef(() => dialogState)

  const ModalComponent = dialogState.component

  const value = useMemo((): DialogContextType => {
    const onSetDialogState = (state: DialogContentState) => {
      setDialogState(state)
      getDialogState.current = () => state
    }
    return {
      getDialogState,
      setDialogState: onSetDialogState,
      closeDialog: () => {
        onSetDialogState(InitialDialogState)
        setAtom(initialDialogValue)
      },
    }
  }, [setAtom])

  return (
    <DialogContext.Provider value={value}>
      <DialogStateContext.Provider value={dialogState}>
        {ModalComponent ? (
          <DialogStoreContextProvider globalStoreParams={globalStoreParams}>
            <DialogCMSContextProvider cmsData={cmsData}>
              <ModalComponent {...restStateData} onClose={value.closeDialog} />
            </DialogCMSContextProvider>
          </DialogStoreContextProvider>
        ) : null}
        {children}
      </DialogStateContext.Provider>
    </DialogContext.Provider>
  )
}

const DialogStoreContextProvider = ({
  globalStoreParams,
  children,
}: {
  globalStoreParams: StoreParams | undefined
  children: ReactNode
}) =>
  globalStoreParams?.store_id ? (
    <GlobalStoreContextProvider storeParams={globalStoreParams}>
      {children}
    </GlobalStoreContextProvider>
  ) : (
    children
  )

const DialogCMSContextProvider = ({
  cmsData,
  children,
}: {
  cmsData: Content | null | undefined
  children: ReactNode
}) =>
  cmsData ? (
    <CMSContextProvider data={cmsData}>{children}</CMSContextProvider>
  ) : (
    children
  )

type DialogContextType = {
  getDialogState: MutableRefObject<() => DialogContentState>
  setDialogState: (params: DialogContentState) => void
  closeDialog: () => void
}
export const DialogContext = createContext<DialogContextType | null>(null)
export const DialogStateContext = createContext<DialogContentState | null>(null)

export const useDialogContext = () => {
  const context = useContext(DialogContext)
  if (!context) {
    throw new Error(
      'useDialogContext must be used within a DialogContextProvider'
    )
  }
  return context
}

// the difference between useDialogContext and useDialogStateContext is that
// the context value is reactive to the dialog state changes where as the other one is not.
// therefore, only use this hook if you need the consumer to react to dialog state changes
export const useDialogStateContext = () => {
  const context = useContext(DialogStateContext)
  if (!context) {
    throw new Error(
      'useDialogStateContext must be used within a DialogContextProvider'
    )
  }
  return context
}
