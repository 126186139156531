import { CMSLayoutContextProvider } from '@/features/cms/components/CMS/CMSLayoutContext'
import { CoreLayoutCMSSection } from '@/features/cms/components/CMS/CoreLayoutCMSSection'
import { type CMSPageDataWithSpecificLayout } from '@/features/cms/components/CMS/types'
import { getCMSWrapperProps } from '@/features/cms/components/CMS/utils/getCMSWrapperProps'

export const GlobalHomePageLayout = ({
  layoutData: { id, content_type_id, data },
}: {
  layoutData: CMSPageDataWithSpecificLayout<'global_home_page_layout'>['content']
}) => {
  const sectionAandB = (data?.body_section_a ?? []).concat(
    data?.body_section_b ?? []
  )
  return (
    <CMSLayoutContextProvider data={content_type_id}>
      <div {...getCMSWrapperProps({ content_type_id, id })}>
        {sectionAandB && <CoreLayoutCMSSection section={sectionAandB} />}
      </div>
    </CMSLayoutContextProvider>
  )
}
