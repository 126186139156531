import { type VisaBenefitDetails } from '@/features/account/services/VisaBenefits/types'
import { apiGet } from '@/features/shared/utils/dataFetching'
import { type getEnrolledBenefitQueryKey } from '@/features/account/services/VisaBenefits/queries'
import { type QueryFunctionContext } from '@tanstack/react-query'

export const fetchVisaBenefitDetails = (
  context: QueryFunctionContext<ReturnType<typeof getEnrolledBenefitQueryKey>>
) => {
  const [, userId] = context.queryKey
  return apiGet<VisaBenefitDetails>({
    config: { url: `/benefits/v1/customers/${userId}` },
    context,
    fetcherName: 'fetchVisaBenefitDetails',
  })
}
