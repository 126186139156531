import styled, { keyframes } from 'styled-components'
import { type ReactNode, useState } from 'react'
import { Image } from '@/features/shared/elements/Image/NextImage'
import { screenSizes } from '@shipt/design-system-themes'
import { objectStorageUrl } from '@/features/shared/utils/objectStorageUrl'
import { ScrollIntoView } from '@/features/shared/components/ScrollIntoView'

export const MemberShopperPhotos = () => (
  <PhotosContainer>
    <BigImgContainer>
      <FadeInFromBottom minHeight={325}>
        <Img
          src={objectStorageUrl('shopper-shopping.png')}
          alt="Shopper Shopping"
          width={612}
          height={613}
          style={{ objectFit: 'contain' }}
        />
      </FadeInFromBottom>
    </BigImgContainer>
    <SmallImgContainer>
      <FadeInFromBottom minHeight={100}>
        <Img
          src={objectStorageUrl('customers.png')}
          alt="Customer on laptop"
          width={612}
          height={404}
          style={{ objectFit: 'contain' }}
        />
      </FadeInFromBottom>
    </SmallImgContainer>
  </PhotosContainer>
)

const PhotosContainer = styled.div`
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 1.5625rem 1.5625rem 3.125rem;
  gap: 0.9375rem;

  @media ${screenSizes.tablet} {
    margin: 1.875rem 2.5rem 3.75rem;
    flex-direction: row;
  }

  @media ${screenSizes.smDesktop} {
    margin: 1.875rem 6.25rem 3.75rem;
    min-height: 37.5rem;
  }

  @media ${screenSizes.lgDesktop} {
    max-width: 77.5rem;
    margin: 1.875rem auto 3.75rem;
  }
`

const Img = styled(Image)`
  border-radius: 8px;
`

const SmallImgContainer = styled.div`
  display: flex;
  padding-right: 20px;

  @media ${screenSizes.tablet} {
    justify-content: flex-end;
    padding-right: 0;
    width: 50%;
  }
`

const BigImgContainer = styled.div`
  @media ${screenSizes.tablet} {
    width: 50%;
  }
`

type Props = {
  children: ReactNode
  minHeight?: number
}

const FadeInFromBottom = ({ minHeight = 0, children }: Props) => {
  const [isInView, setIsInView] = useState(false)

  return (
    <Container minHeight={minHeight}>
      <ScrollIntoView onInView={() => setIsInView(true)} threshold={0.15}>
        {isInView && <Wrapper>{children}</Wrapper>}
      </ScrollIntoView>
    </Container>
  )
}

const fadeInFromBottom = keyframes`
0% {
  opacity: 0;
  transform: translateY(100%);
}
100% {
  transition: all 0.75s cubic-bezier(0.25, 0.285, 0.2, 1.02); 
  opacity: 1;
  transform: translateY(0); 
}
`
const Wrapper = styled.div`
  animation: ${fadeInFromBottom} 1s;
`
const Container = styled.div<{ minHeight: number }>`
  min-height: ${(props) => props.minHeight}px;
`
