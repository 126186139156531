import { trackElementClicked } from '@/features/shared/analytics/element'
import {
  MEMBER_APP_DOWNLOAD_URL,
  SHOPPER_APP_DOWNLOAD_URL,
} from '@/features/shared/constants/urls'
import { Stack } from '@shipt/design-system-layouts'
import { Link } from '@shipt/design-system-typography'
import { AppStoresIcon } from '@shipt/design-system-icons'
import { getLinkNewTabAttributes } from '@/features/shared/elements/Link/utils'

type Props = {
  id?: string
  className?: string
  isShopper: boolean
  openInNewTab: boolean
  displaySublocation?: string
}

export const AppBadges = ({
  id,
  className,
  openInNewTab,
  isShopper,
  displaySublocation,
}: Props) => {
  const handleOnClick = (content: 'google' | 'apple') => {
    trackElementClicked({
      type: 'button',
      content,
      display_sublocation: displaySublocation,
    })
  }
  const linkAttributes = getLinkNewTabAttributes(openInNewTab)
  return (
    <Stack id={id} spacing="lg" className={className}>
      <Link
        size="sm"
        href={isShopper ? SHOPPER_APP_DOWNLOAD_URL : MEMBER_APP_DOWNLOAD_URL}
        aria-label="Shipt App in Apple App Store"
        onClick={() => handleOnClick('apple')}
        {...linkAttributes}
      >
        <AppStoresIcon variant="apple" />
      </Link>
      <Link
        size="sm"
        href={isShopper ? SHOPPER_APP_DOWNLOAD_URL : MEMBER_APP_DOWNLOAD_URL}
        aria-label="Shipt App in Google Play"
        onClick={() => handleOnClick('google')}
        {...linkAttributes}
      >
        <AppStoresIcon variant="google" />
      </Link>
    </Stack>
  )
}
