import { useEffect, useState } from 'react'
import {
  trackLiveChatConversationEnded,
  trackLiveChatConversationStarted,
} from '@/features/shared/analytics/marketing'
import { SprinklrChat } from '@/lib/ThirdPartyScripts/SprinklrChat'
import { type SprChatEvent } from '@/features/account/components/Chat/types'

type SubscriberCallback = Parameters<SprChatEvent['subscriber']>[0]

export const Chat = () => {
  const [isSprinklrLoaded, setIsSprinklrLoaded] = useState(false)

  useEffect(() => {
    if (!isSprinklrLoaded) return
    // show sprinklr chat on component mount
    window.sprChat('enable')
    function onEventTriggered({
      response: {
        data: { eventType },
      },
    }: SubscriberCallback) {
      if (eventType === 'NEW_CONVERSATION_STARTED') {
        trackLiveChatConversationStarted()
      }
      if (eventType === 'CONVERSATION_CLOSED') {
        trackLiveChatConversationEnded()
      }
    }
    window.sprChat('subscribeToUpdate', {
      topic: 'eventTriggered',
      subscriber: onEventTriggered,
    })
    return () => {
      // hide on unmount. This will hide/disable sprinklr on authed pages where we don't
      // want chat available
      window.sprChat('disable')
      window.sprChat('unsubscribeToUpdate', {
        topic: 'eventTriggered',
        subscriber: onEventTriggered,
      })
    }
  }, [isSprinklrLoaded])

  return <SprinklrChat setIsSprinklrLoaded={setIsSprinklrLoaded} />
}
