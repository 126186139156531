import { toZonedTime, format as formatTz } from 'date-fns-tz'
import { format } from 'date-fns/format'
import { isValid } from 'date-fns/isValid'
import { parseISO } from 'date-fns/parseISO'
import { differenceInDays } from 'date-fns/differenceInDays'
import { startOfDay } from 'date-fns/startOfDay'
import { isToday } from 'date-fns/isToday'
import { isTomorrow } from 'date-fns/isTomorrow'

export const utcIntoZonedTime = (
  inputDate: InputDate,
  timeZone: string
): Date | null => {
  const date = getDate(inputDate)
  return isValid(date) ? toZonedTime(date, timeZone) : null
}

// expects date with time offset '2020-11-02T14:00:00-06:00'
export const getDateTimeInfo = (dateTimeStr: string, timeZone: string) => {
  const zonedTime = utcIntoZonedTime(dateTimeStr, timeZone)
  if (!zonedTime) return { date: '', hourStr: '', dayName: '' }

  const date = formatTz(zonedTime, 'yyyy-MM-dd', { timeZone })
  const hourStr = formatTz(zonedTime, 'h a', { timeZone }).toLowerCase()
  const dayName = formatTz(zonedTime, 'EEEE', { timeZone })

  return { date, hourStr, dayName }
}

export const getFormattedHour = (
  aDateStr: string,
  timeZone = '',
  defaultStr = ''
): string => {
  const period = defaultStr.slice(defaultStr.length - 2)
  const number = defaultStr.slice(0, defaultStr.length - 2)
  const { hourStr } =
    timeZone !== null && timeZone !== ''
      ? getDateTimeInfo(aDateStr, timeZone)
      : { hourStr: `${number?.trim()} ${period.toLowerCase()}` }
  return hourStr
}

const getDate = (inputDate: InputDate): Date =>
  inputDate instanceof Date ? inputDate : parseISO(inputDate)

export const formatDate = (inputDate: InputDate, formatStr: string): string => {
  const date = getDate(inputDate)
  return isValid(date) ? format(date, formatStr) : ''
}

export const formatExpiration = (input: InputDate, formatStr = 'M/d'): string =>
  `Expires ${formatDate(input, formatStr)}`

export const getDifferenceInDays = ({
  theLaterDate,
  theEarlierDate,
}: getDifferenceInDaysType): number =>
  differenceInDays(getDate(theLaterDate), getDate(theEarlierDate))

export const getDaysUntilExpire = (expiration: string) => {
  if (!expiration) return 0
  const days = {
    theLaterDate: expiration,
    theEarlierDate: startOfDay(new Date(Date.now())),
  }
  return Math.abs(Math.round(getDifferenceInDays(days)))
}

export const getDateTimeInfoWithSeconds = (seconds: number) => ({
  minutes: Math.floor(seconds / 3600),
  hours: Math.floor(seconds / 3600),
  days: Math.floor(seconds / 86400),
  weeks: Math.floor(seconds / 604800),
  years: Math.floor(seconds / 31536000),
})

export const getDayInfo = (date: Date) => {
  const getFormattedDate = (toFormat: string) => formatDate(date, toFormat)
  return {
    dayNameAbbrev: getFormattedDate('eee'),
    dayName: getFormattedDate('eeee'),
    dayOfMonth: Number(getFormattedDate('d')),
    dayOfWeek: date.getDay(),
    dayRelative: isToday(date) ? 'Today' : getFormattedDate('eee'),
    monthAbbrev: getFormattedDate('MMM'),
  }
}

export const getDayStr = (date: Date) => {
  if (isToday(date)) return 'Today'
  if (isTomorrow(date)) return 'Tomorrow'

  const diffInDays = getDifferenceInDays({
    theEarlierDate: startOfDay(new Date(Date.now())),
    theLaterDate: date,
  })
  if (diffInDays <= 7) return formatDate(date, 'EEEE')
  return ''
}

export const getDayStrShort = (date: Date) => {
  const diffInDays = getDifferenceInDays({
    theEarlierDate: startOfDay(new Date(Date.now())),
    theLaterDate: date,
  })
  if (diffInDays <= 7) return formatDate(date, 'EEE')
  return ''
}

// types
type InputDate = string | Date
interface getDifferenceInDaysType {
  theLaterDate: InputDate
  theEarlierDate: InputDate
}

// eg. input:'2020-12-10T19:05:15.94305422Z', output: 11:05 AM
export const formatToDateTime = (dateStr: string) =>
  format(new Date(dateStr), 'h:mm a')

export const formatDateInTimezone = (
  dateTimeStr: string,
  timeZone: string
): string => {
  const parsedDate = parseISO(dateTimeStr)

  if (!isValid(parsedDate)) return ''

  const zonedTime = toZonedTime(parsedDate, timeZone || '')
  const formattedTime = formatTz(zonedTime, 'h:mm a', { timeZone })
  return formattedTime.toLowerCase()
}

// e.g 2024-01-22T00:00:00Z will return Jan 22 regardless of user's timezone
export const formatDateWithoutTimezone = (date: InputDate) => {
  const dateInUtc = utcIntoZonedTime(date, 'UTC')
  return dateInUtc ? formatDate(dateInUtc, 'MMM d') : ''
}
