import { userIsDoNotShareSellTA } from '@/features/account/services/DataPrivacy/utils'
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from '@/features/shared/utils/sessionStorage'
import {
  type AnalyticsEvent,
  adwordsDestinationEvents,
  facebookDestinationEvents,
} from '@shipt/analytics-member-web'

export const getAmplitudeSession = () => {
  const now = Date.now()
  const existingSessionEpoch = Number(
    getSessionStorageItem('shipt_amplitude_session')
  )
  const isExpired = now - new Date(existingSessionEpoch).getTime() > 1800000
  // reset session if doesn't exist or >30 minutes
  if (isExpired) {
    setSessionStorageItem('shipt_amplitude_session', now.toString())
    return now
  }
  return existingSessionEpoch
}

export const setDeviceModeIntegrations = (event: AnalyticsEvent) => {
  const isEnabledForFb = event in facebookDestinationEvents
  const isEnabledForAdWords = event in adwordsDestinationEvents
  const userHasNotOptedOut = !userIsDoNotShareSellTA()

  // Do not set device mode integrations if user has opted out of sharing, selling, and targeted advertising
  return {
    'Facebook Pixel': isEnabledForFb && userHasNotOptedOut,
    'Google AdWords New': isEnabledForAdWords && userHasNotOptedOut,
  }
}

// support cloud-mode for Amplitude: https://segment.com/docs/connections/destinations/catalog/amplitude/#sessionid
export const addCloudModeIntegrations = () => ({
  Amplitude: {
    session_id: getAmplitudeSession(),
  },
})
