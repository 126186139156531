import {
  selectedActiveOrderAtom,
  selectedActiveOrderIdAtom,
} from '@/features/shop/state/SelectedActiveOrder/atoms'
import { type Product } from '@/features/shop/services/Product/types'
import { type OrderLine } from '@/features/shop/services/Orders/types'
import { useAtomValue, useSetAtom } from 'jotai'
import { useQueryOrder } from '@/features/shop/services/Orders/queries'
import { useEffect } from 'react'

export const useActiveOrder = () => {
  return useAtomValue(selectedActiveOrderAtom)
}

export const useSetActiveOrder = () => {
  const setOrderId = useSetAtom(selectedActiveOrderIdAtom)

  return (orderId: number | null) => {
    setOrderId(orderId)
  }
}

export const useCurrentActiveOrderId = () => {
  return useActiveOrder()?.id
}

export const useOrderLinesFromCurrentActiveOrder = () => {
  const currentActiveOrder = useActiveOrder()
  return currentActiveOrder?.order_lines || []
}

export const useProductOrderLineFromCurrentActiveOrder = (
  product: Product
): OrderLine<Product> | undefined => {
  const orderLines = useOrderLinesFromCurrentActiveOrder()
  let orderLine = orderLines.find(
    (line) => line.requested_product.id === product.id
  )

  // special request or custom product look ups require "name" instead of "id"
  if (product.isCustomProduct) {
    orderLine = orderLines.find(
      (line) => line.requested_product.name === product.name
    )
  }

  return orderLine
}

export const useIsOrder = () => {
  const currentActiveOrder = useActiveOrder()
  return Boolean(currentActiveOrder?.id)
}

/**
 * Side effect hook to prime jotai with active order data on app load
 * and update as necessary when activeOrderId changes.
 * This approach allows us to communicate with Jotai directly instead of instantiatin
 * a ton of RQ observers. Jotai has a plugin for this (https://jotai.org/docs/integrations/query)
 * however:
 * - it doesn't integrate with our custom Jotai testing utilities
 * - it doesn't allow us to use hooks in implementation
 */
export const useLoadActiveOrder = () => {
  const orderId = useAtomValue(selectedActiveOrderIdAtom)
  const setOrder = useSetAtom(selectedActiveOrderAtom)
  const order = useQueryOrder(orderId ?? 0).data
  useEffect(() => {
    setOrder(orderId ? order ?? null : null)
  }, [order, orderId, setOrder])

  return null
}

export const useClearActiveOrder = () => {
  const setOrderId = useSetAtom(selectedActiveOrderIdAtom)
  const setOrder = useSetAtom(selectedActiveOrderAtom)

  return () => {
    setOrderId(null)
    setOrder(null)
  }
}
