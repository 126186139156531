// The logger is the intended alternative to using console directly.
// The exception has to live somewhere; this is that place.
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-console */
import safeStringify from 'safe-stable-stringify'
import {
  isLocal,
  isProduction,
  isRunningTests,
} from '@/features/shared/utils/environment'
import { isOnServer } from '@shared/constants/util'
import {
  levels,
  isLogLevel,
  type LogLevel,
  type LogMeta,
} from '@/features/shared/utils/logger/types'
import { dtrum, bugsnag } from '@/features/shared/utils/logger/vendor'
import { toError } from '@/features/shared/utils/dataFetching/utils'
import isEmpty from 'lodash/isEmpty'
import { modifyBugsnagEvent } from '@/features/shared/utils/logger/utils'

const getActiveLogLevel = () =>
  isLogLevel(process.env.NEXT_PUBLIC_LOG_LEVEL)
    ? process.env.NEXT_PUBLIC_LOG_LEVEL
    : 'info'
const isActiveLogLevel = (level: number) => level <= levels[getActiveLogLevel()]

export const logError = (
  err: unknown,
  meta: LogMeta = {},
  options?: ErrorOptions
) => {
  // always log during dev on client (unless we're running locally) :thisistheway:
  if (!isProduction && !isRunningTests && (!isOnServer() || isLocal)) {
    console.error('logError', err, meta, options)
  }
  const error: Error = toError(err, options)
  _logServerSide(error.message, meta, 'error')
  dtrum()?.reportError(error)

  bugsnag()?.notify(error, (event) => {
    modifyBugsnagEvent(event, meta)
  })
}

export const logWarn = (
  message: string,
  meta: Record<string, unknown> = {}
) => {
  if (!isActiveLogLevel(levels.warn)) return
  _logServerSide(message, meta, 'warn')
  bugsnag()?.notify(message, (event) => {
    event.severity = 'warning'

    if (meta && !isEmpty(meta)) {
      event.addMetadata('metadata', meta)
    }
  })
}

export const logInfo = (message: string, meta: LogMeta = {}) => {
  if (!isActiveLogLevel(levels.info)) return
  _logServerSide(message, meta, 'info')
  bugsnag()?.notify(message, (event) => {
    event.severity = 'info'
    modifyBugsnagEvent(event, meta)
  })
}

export const logDebug = (
  message: string,
  meta: Record<string, unknown> = {}
) => {
  if (!isActiveLogLevel(levels.debug)) return
  _logServerSide(message, meta, 'debug')
}

function _logServerSide(
  message: string,
  meta: Record<string, unknown>,
  level: LogLevel
) {
  // don't show logs in browser production-mode
  if (!isOnServer() && (isProduction || isRunningTests)) return
  console.log(safeStringify({ ...meta, message, level }))
}
