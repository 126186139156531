import styled from 'styled-components'
import { ButtonLink } from '@/features/shared/elements/Link/Buttons'
import { routes } from '@shared/constants/routes'
import { Body1, TitleClaremont1 } from '@/features/shared/styles/typography'
import { screenSizes } from '@shipt/design-system-themes'
import { Image } from '@/features/shared/elements/Image/NextImage'
import { useUserId } from '@/features/account/services/User/hooks'
import { objectStorageUrl } from '@/features/shared/utils/objectStorageUrl'
import { useEffect } from 'react'
import { trackElementViewed } from '@/features/shared/analytics/element'
import { useRouter } from 'next/router'

type Props = {
  statusCode?: number
  showActionButton?: boolean
  errorTitle?: string
  errorMessage?: string
  actionButtonText?: string
  isCMSPage?: boolean
}

export const ErrorPage = ({
  statusCode,
  showActionButton = true,
  errorTitle,
  errorMessage = 'Please try again in a bit.',
  actionButtonText = 'Go to homepage',
  isCMSPage = false,
}: Props) => {
  const { asPath } = useRouter()
  useEffect(() => {
    trackElementViewed({
      type: 'page',
      content: asPath,
      message_goal: `Status Code - ${statusCode}`,
      display_sublocation: `${isCMSPage ? 'CMS ' : ''}Error page viewed`,
    })
  }, [isCMSPage, statusCode, asPath])

  const userId = useUserId()

  const href = userId
    ? routes.RETAILER_HOMEPAGE.url
    : routes.UNGATED_HOMEPAGE.url

  const title =
    errorTitle ||
    (statusCode
      ? `Cleanup on aisle ${statusCode}.`
      : 'Our page is having trouble.')
  return (
    <ErrorPageWrapper>
      <ErrorImage
        alt=""
        src={objectStorageUrl('aisle-cleanup.webp')}
        width={351}
        height={260}
      />
      <ErrorContentWrapper>
        <ErrorTitle as="h3">{title}</ErrorTitle>
        <ErrorMessage>{errorMessage}</ErrorMessage>
        {showActionButton && (
          <ActionLink href={href}>{actionButtonText}</ActionLink>
        )}
      </ErrorContentWrapper>
    </ErrorPageWrapper>
  )
}

const ErrorPageWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const ErrorImage = styled(Image)`
  margin-bottom: 2rem;

  @media ${screenSizes.tablet} {
    margin-bottom: 0;
  }
`

const ErrorContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const ErrorTitle = styled(TitleClaremont1)`
  color: ${({ theme }) => theme.gray800};
  margin-bottom: 0.25rem;

  @media ${screenSizes.tablet} {
    margin: 2rem 0 0.5rem;
  }
`

const ErrorMessage = styled(Body1)`
  color: ${({ theme }) => theme.gray600};
  margin-bottom: 1rem;

  @media ${screenSizes.tablet} {
    margin-bottom: 0.75rem;
  }
`

const ActionLink = styled(ButtonLink)`
  @media ${screenSizes.max_mobile} {
    width: 100%;
  }
`
