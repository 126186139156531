import { type RedeemCopyType } from '@/features/purchase/services/GiftCard/types'

const uppercaseRegex = /[A-Z]/
const lowercaseRegex = /[a-z]/
const specialCharRegex = /[_.\-!@*$?&%]/

export const isValidRedeemType = (
  redeemType: string
): redeemType is RedeemCopyType =>
  ['credit', 'membership'].indexOf(redeemType) > -1

export const validatePassword = (
  password: string,
  confirmPassword: string
): PasswordValidationResult => {
  const result = {
    passLength: password.length >= 8,
    passUpper: uppercaseRegex.test(password),
    passLower: lowercaseRegex.test(password),
    passSpecialChar: specialCharRegex.test(password),
    passMatch: password.length > 0 && password === confirmPassword,
  }
  return result
}

export interface PasswordValidationResult {
  passLength: boolean
  passUpper: boolean
  passLower: boolean
  passSpecialChar: boolean
  passMatch: boolean
}

export const isValidZip = (zip: number | string) => {
  const zipPattern = /^\d{5}(-\d{4})?$/
  return zipPattern.test(String(zip))
}
