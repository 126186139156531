import { type LogMeta } from '@/features/shared/utils/logger/types'
import { type Event } from '@bugsnag/js'

const responseStatusesToGroup = new Set([401, 502, 503, 504])

export function modifyBugsnagEvent(event: Event, meta: LogMeta | undefined) {
  if (meta && Object.keys(meta).length) {
    event.addMetadata('metadata', meta)

    // group all 401s, 502s, 503s and 504s together
    if (
      meta.responseStatus &&
      responseStatusesToGroup.has(meta.responseStatus)
    ) {
      event.groupingHash = `REQUEST_${meta.responseStatus}`
    }

    if (meta.customGroupingHash) {
      event.groupingHash = meta.customGroupingHash
    }

    if (meta.fetcherName) {
      // if grouping hash already exists, do not overwrite it
      if (!event.groupingHash) {
        event.groupingHash = meta.fetcherName
      }
      event.context = meta.fetcherName
    }
  }
}
