import { useMutation, useQueryClient } from '@tanstack/react-query'
import { fetchPlaceDetails } from '@/features/shop/services/GeoLocation/fetchers'
import { type ValidAddress } from '@/features/account/services/Addresses/types'
import { navigatorGetCurrentPosition } from '@/features/shop/services/GeoLocation/utils'
import { reverseGeocodeQueryOptions } from '@/features/shop/services/GeoLocation/queries'

export const useMutationFetchPlaceDetails = () =>
  useMutation({ mutationFn: fetchPlaceDetails })

export const useMutationFetchCurrentLocation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (): Promise<ValidAddress> => {
      try {
        const currentPosition = await navigatorGetCurrentPosition()
        const data = await queryClient.fetchQuery(
          reverseGeocodeQueryOptions(currentPosition?.coords)
        )
        return {
          street1: data?.street1 ?? '',
          city: data?.city ?? '',
          state: data?.state ?? '',
          zip_code: data?.zip_code ?? '',
        }
      } catch {
        throw new Error('Failed to fetch current location')
      }
    },
  })
}
