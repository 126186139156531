import {
  type CmsMappedComponents,
  type CmsReferenceComponents,
  type ReferenceRenderProps,
} from '@/features/cms/components/CMS/types'
import dynamic from 'next/dynamic'

const DynamicAddressCheck = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/AddressCheck'))
      .AddressCheck
)
const DynamicZipCheckForm = dynamic(
  async () =>
    (await import('@/features/entrypoint/components/Marketing/ZipCheckForm'))
      .ZipCheckForm
)
const DynamicImageLink = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/ImageLink'))
      .ImageLink
)
const DynamicHeadlineImageCard = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/HeadlineImageCard'))
      .HeadlineImageCard
)
const DynamicThreeSteps = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/ThreeSteps'))
      .ThreeSteps
)
const DynamicHeadlineImageLink = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/HeadlineImageLink'))
      .HeadlineImageLink
)
const DynamicDisclaimer = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/CMSDisclaimer'))
      .CMSDisclaimer
)
const PartnershipBanner = dynamic(
  async () => (await import('./components/PartnershipBanner')).PartnershipBanner
)
const DynamicPricingLinks = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/PricingLinks'))
      .PricingLinks
)
const DynamicAppDownloadLinks = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/AppDownloadLinks'))
      .AppDownloadLinks
)
const DynamicShopperApplicationForm = dynamic(
  async () =>
    (
      await import(
        '@/features/entrypoint/components/Marketing/ShopperApplicationForm'
      )
    ).ShopperApplicationForm
)
const DynamicCouponCodeSignupForm = dynamic(
  async () =>
    (
      await import(
        '@/features/cms/components/CMS/components/CouponCodeSignupForm'
      )
    ).CouponCodeSignupForm
)
const DynamicEmployeeIDSignupForm = dynamic(
  async () =>
    (
      await import(
        '@/features/cms/components/CMS/components/EmployeeIDSignupForm'
      )
    ).EmployeeIDSignupForm
)
const DynamicRedeemEmailForm = dynamic(
  async () =>
    (
      await import(
        '@/features/cms/components/CMS/components/Forms/RedeemEmailForm'
      )
    ).RedeemEmailForm
)
const DynamicSingleCTA = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/SingleCTA'))
      .SingleCTA
)
const DynamicDoubleCTA = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/DoubleCTA'))
      .DoubleCTA
)

const referenceComponents: CmsMappedComponents<
  CmsReferenceComponents['content_type_id']
> = {
  address_check: DynamicAddressCheck,
  app_download_links: DynamicAppDownloadLinks,
  coupon_code_signup_form: DynamicCouponCodeSignupForm,
  double_cta: DynamicDoubleCTA,
  employee_id_signup_form: DynamicEmployeeIDSignupForm,
  footer_disclaimer: DynamicDisclaimer,
  headline_image_card: DynamicHeadlineImageCard,
  headline_image_link: DynamicHeadlineImageLink,
  headline_steps: DynamicThreeSteps,
  image_link: DynamicImageLink,
  partnership_banner: PartnershipBanner,
  pricing_links: DynamicPricingLinks,
  redeem_email_form: DynamicRedeemEmailForm,
  shopper_application_form: DynamicShopperApplicationForm,
  single_cta: DynamicSingleCTA,
  zip_check: DynamicZipCheckForm,
}

export const ReferenceRenderer = ({ references }: ReferenceRenderProps) => {
  if (!references) return null
  return (
    <>
      {references.map((item) => {
        const Component = referenceComponents[item.content_type_id]
        if (!Component) return null

        return <Component key={item.id} {...item} />
      })}
    </>
  )
}
