import { CMSContainer } from '@/features/cms/components/CMS/CMSContainer'
import { staticColors } from '@/features/shared/theme/staticColors'
import { isProduction } from '@/features/shared/utils/environment'
import { logError } from '@/features/shared/utils/logger'
import styled from 'styled-components'
import { ClientOnly } from '@/features/shared/components/ClientOnly'
import { isOnServer } from '@shared/constants/util'

type CMSErrorHandlerProps = {
  error: unknown
  id: string
  contentTypeId: string
}

export const CMSErrorHandler = ({
  error,
  id,
  contentTypeId,
}: CMSErrorHandlerProps) => {
  if (isOnServer()) {
    logError(error, { id, contentTypeId })
  }

  // Do not render out an error message in production
  if (isProduction) return null

  // Render out an error message in development & staging
  return (
    <ErrorContainer id={id} contentTypeId={contentTypeId}>
      Error rendering CMS content type:{' '}
      <ContentTypeId>{contentTypeId}</ContentTypeId>
      {error instanceof Error && (
        <ClientOnly>
          <ErrorMessage>{error.stack}</ErrorMessage>
        </ClientOnly>
      )}
    </ErrorContainer>
  )
}

const ErrorContainer = styled(CMSContainer)`
  border: 1px dashed ${staticColors.red500};
`

const ErrorMessage = styled.pre`
  color: ${staticColors.red500};
  background-color: ${staticColors.gray100};
  padding: 16px;
  overflow: scroll;
`

const ContentTypeId = styled.pre`
  display: inline;
  font-weight: 600;
`
