import { apiGet } from '@/features/shared/utils/dataFetching'
import { type QueryFunctionContext } from '@tanstack/react-query'
import {
  type Address,
  type AddressesResponse,
} from '@/features/account/services/Addresses/types'

export const fetchAddresses = async (
  context: QueryFunctionContext<readonly [string]>
): Promise<Address[]> => {
  const { signal } = context
  const addressResponse = await apiGet<AddressesResponse>({
    config: {
      url: 'api/v1/customer_addresses.json',
      signal,
    },
    context,
    fetcherName: 'fetchAddresses',
  })
  return addressResponse.customer_addresses
}
