import styled from 'styled-components'
import { screenSizes } from '@shipt/design-system-themes'
import {
  TagsTitle1,
  TitleClaremont1,
} from '@/features/shared/styles/typography'
import { VStack } from '@/features/shared/elements/Stack'
import { ValueCards } from '@/features/entrypoint/components/UngatedHomePage/ValueCards'

export const DeliveryDoneDifferently = () => (
  <SectionLayoutContainer>
    <SmallHeadline>Delight in every delivery</SmallHeadline>
    <MiddleHeadline>
      Order groceries, everyday essentials, and so much more from your favorite
      stores — delivered the same day by friendly shoppers who care about
      getting things just right.
    </MiddleHeadline>
    <ValueCardsWrapper>
      <ValueCards />
    </ValueCardsWrapper>
  </SectionLayoutContainer>
)

const SectionLayoutContainer = styled(VStack)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.plum};
  padding: 0 1.5rem 3.125rem;

  @media ${screenSizes.tablet} {
    padding: 0 0 3.75rem;
  }

  @media ${screenSizes.smDesktop} {
    padding: 0 11.25rem 3.75rem;
  }

  @media ${screenSizes.lgDesktop} {
    padding: 0 6.25rem 3.75rem;
  }
`

const SmallHeadline = styled(TagsTitle1)`
  display: flex;
  margin-top: 3.125rem;
  color: ${({ theme }) => theme.white};

  @media ${screenSizes.tablet} {
    margin-top: 3.75rem;
    font-size: 1rem;
  }

  @media ${screenSizes.smDesktop} {
    margin-top: 3.75rem;
    font-size: 1rem;
  }
`

const MiddleHeadline = styled(TitleClaremont1)`
  display: flex;
  text-align: center;
  max-width: 36.875rem;
  margin: 1.5rem 0 2.5rem;
  color: ${({ theme }) => theme.white};

  @media ${screenSizes.tablet} {
    margin: 2rem 0 5rem;
    font-size: 2.25rem;
    line-height: 2.75rem;
  }

  @media ${screenSizes.smDesktop} {
    font-size: 2.5rem;
    line-height: 3rem;
    max-width: 52.8125rem;
    margin: 7.5rem 0;
  }
`
const ValueCardsWrapper = styled.div`
  @media ${screenSizes.only_tablet} {
    width: 48.125rem;
  }
`
