import { ExperimentType } from '@/features/shared/services/Experiments/constants'
import { type ExperimentValue } from '@/features/shared/services/Experiments/types'
import {
  type FetchExperimentsSSR,
  fetchExperimentsSSR,
} from '@/features/shared/services/Experiments/queries'
import { getSearchQueryString } from '@/features/shared/utils/url'
import { routes } from '@shared/constants/routes'
import { fetchGeoLocationByIp } from '@/features/shop/services/GeoLocation/fetchers'
import { setServerGuestCookie } from '@/features/shared/serverUtils/guest'
import { GuestDataStore } from '@/features/shared/constants/global'
import { useExperiment } from '@/features/shared/services/Experiments/hooks'
import { logError, logWarn } from '@/features/shared/utils/logger'
import { fetchHomepageStores } from '@/features/shop/services/ShoppingStore/fetchers'
import { getHomepageStoresQueryKey } from '@/features/shop/services/ShoppingStore/queries'
import { getCookie } from '@/features/shared/utils/cookies'
import { useGuestUserContext } from '@/features/shared/context/GuestUserContext'
import { getAnonymousId } from '@shipt/analytics-member-web'
import { useRouter } from 'next/router'
import { type ValidAddress } from '@/features/account/services/Addresses/types'
import { getIsBot } from '@/features/shared/serverUtils/getIsBot'
import { getPageRedirect } from '@/features/shared/serverUtils/ssrHelpers/utils'

type AddressRemovalVariationOptions = 'retailer' | 'atc' | 'control'

export const GUEST_ADDRESS_REMOVAL_FLOW = 'address_entry_phase_1b'

// This function is used to normalize the raw experiment value to one of the known Address Removal variation options.
// It can accept either the raw experiment value ('variation_1') or the normalized variation name ('retailer').
// If the input value is not recognized, it will default to 'control'.
const getAddressRemovalVariation = (
  value: ExperimentValue | AddressRemovalVariationOptions | undefined
): AddressRemovalVariationOptions => {
  if (value === 'variation_1' || value === 'retailer') {
    return 'retailer'
  }
  return 'control'
}

// This helper function determines if the experiment value is either of the variants (aka not control)
const isInAddressRemovalExperiment = (
  value: AddressRemovalVariationOptions | ExperimentValue | undefined
) => {
  return ['retailer'].includes(getAddressRemovalVariation(value))
}

// This hook can be used within React components to quickly access the normalized variation value and other useful data for this experiment
export const useAddressRemovalExperiment = () => {
  const { guestAddress } = useGuestUserContext() ?? {}
  const experiment = useExperiment(ExperimentType.ADDRESS_ENTRY_PHASE_1B)
  const isVariant = isInAddressRemovalExperiment(experiment.value)
  const flowCookieValue: boolean = getCookie(GUEST_ADDRESS_REMOVAL_FLOW)
  const router = useRouter()

  const inExperimentFlow =
    isVariant && flowCookieValue && !guestAddress?.street1

  // Temporary debug flag for the Address Removal Experiment
  if (router.query.addressRemovalDebug) {
    logWarn('Address Removal Experiment Debug Info', {
      anonymous_id: getAnonymousId(),
      inExperimentFlow,
      experiment,
      isVariant,
      flowCookieValue,
      guestAddress,
    })
  }

  return {
    ...experiment, // active, track, isLoading, isSuccess, isFetched
    value: getAddressRemovalVariation(experiment.value),
    isVariant,
    isControl: !isVariant,
    inExperimentFlow,
    flowCookieValue,
  }
}

export const handleAddressRemovalExperimentSSR = async ({
  queryClient,
  ssrContext,
}: FetchExperimentsSSR) => {
  if (getIsBot(ssrContext)) return {}

  try {
    //   determine the variant that the user is in
    const experiments = await fetchExperimentsSSR({
      queryClient,
      ssrContext,
    })
    const variant = getAddressRemovalVariation(
      experiments?.[ExperimentType.ADDRESS_ENTRY_PHASE_1B]?.value
    )

    if (!isInAddressRemovalExperiment(variant)) return {}
    // get the user's ip address via headers
    const forwardedFor = ssrContext.req.headers?.['x-forwarded-for']
    const ip = Array.isArray(forwardedFor)
      ? forwardedFor.at(0)
      : forwardedFor?.split(',').at(0) ?? 'Unknown'

    if (ip && ip !== 'Unknown') {
      const { address } = await fetchGeoLocationByIp(ip)
      if (address?.zip_code) {
        const stores = await queryClient.fetchQuery({
          queryKey: getHomepageStoresQueryKey(address.zip_code),
          queryFn: fetchHomepageStores,
        })
        const isServiceableZip = stores.delivery_stores.length

        if (isServiceableZip) {
          const addressWithInferredZip: ValidAddress = {
            street1: '',
            city: '',
            state: '',
            zip_code: address.zip_code,
          }
          // set guest address cookie - without this cookie, the user will get redirected from GHP to login
          setServerGuestCookie({
            name: GuestDataStore.ADDRESS,
            value: addressWithInferredZip,
            ssrContext,
          })
          setServerGuestCookie({
            name: GUEST_ADDRESS_REMOVAL_FLOW,
            value: true,
            ssrContext,
          })

          const stringifiedParams = getSearchQueryString(ssrContext.query)

          // redirect to GHP
          return getPageRedirect(
            `${routes.GLOBAL_HOMEPAGE.url}${stringifiedParams}`
          )
        }
      }
    }
  } catch (e) {
    logError(e)
    return {}
  }

  return {}
}
