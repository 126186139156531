import { type DtrumApi } from '@/features/shared/models/dtrum'
import { isOnServer } from '@shared/constants/util'
import Bugsnag from '@bugsnag/js'

export const dtrum = (): DtrumApi | undefined => {
  if (isOnServer()) return
  return window.dtrum
}

export const bugsnag = (): typeof Bugsnag | undefined => {
  if (isOnServer()) return
  return Bugsnag
}
