import { CoreLayoutCMSSection } from '@/features/cms/components/CMS/CoreLayoutCMSSection'
import { type CMSPageDataWithSpecificLayout } from '@/features/cms/components/CMS/types'
import styled from 'styled-components'
import { screenSizes, spacingRem } from '@shipt/design-system-themes'
import { getCMSWrapperProps } from '@/features/cms/components/CMS/utils/getCMSWrapperProps'
import { CMSLayoutContextProvider } from '@/features/cms/components/CMS/CMSLayoutContext'

export const MarketplacePageLayout = ({
  layoutData: { id, content_type_id, data },
}: {
  layoutData: CMSPageDataWithSpecificLayout<'marketplace_page_layout'>['content']
}) => (
  <CMSLayoutContextProvider data={content_type_id}>
    <MarketplacePageLayoutContainer
      {...getCMSWrapperProps({ content_type_id, id })}
    >
      {data?.body && <CoreLayoutCMSSection section={data.body} />}
    </MarketplacePageLayoutContainer>
  </CMSLayoutContextProvider>
)

const MarketplacePageLayoutContainer = styled.div`
  margin-top: ${spacingRem('lg')};

  @media ${screenSizes.tablet} {
    margin-top: ${spacingRem('xl')};
  }
`
