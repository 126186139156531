// stolen from https://raw.githubusercontent.com/prisma-labs/graphql-request/master/src/types.ts

import { type AxiosResponse } from 'axios'

export type Variables = Record<string, unknown>

export interface GraphQLError {
  message: string
  locations?: { line: number; column: number }[]
  path?: string[]
  extensions?: {
    exception?: {
      path?: unknown[]
    }
  }
}

export interface GraphQLResponse<T> {
  data?: T
  errors?: GraphQLError[]
  extensions?: Record<string, unknown>
  status: number
}

export interface GraphQLRequestContext<V = Variables> {
  query: string | string[]
  variables?: V
  operationName: string
}

export class ClientError<
  T,
  D extends GraphQLResponse<T> = GraphQLResponse<T>
> extends Error {
  axiosResponse: AxiosResponse<D>
  response: GraphQLResponse<T>
  request: GraphQLRequestContext

  constructor(apiResponse: AxiosResponse<D>, request: GraphQLRequestContext) {
    const response = apiResponse.data
    const message = `${ClientError.extractMessage<T>(
      response
    )}: ${JSON.stringify({
      response,
      request,
    })}`

    super(message)

    Object.setPrototypeOf(this, ClientError.prototype)

    this.axiosResponse = apiResponse
    this.response = response
    this.request = request

    // this is needed as Safari doesn't support .captureStackTrace
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, ClientError)
    }
  }

  private static extractMessage<T>(response: GraphQLResponse<T>): string {
    try {
      return response.errors![0]!.message
    } catch (e) {
      return `GraphQL Error (Code: ${response.status})`
    }
  }
}
