import { urlPathnameToLocationName } from '@/features/shared/analytics/utils'
import {
  COMMERCE_PRODUCT_BANNER_SHELF_TYPE,
  STATIC_BANNER_SHELF_TYPE,
} from '@/features/shared/constants/shelves'
import { DISPLAY_FORMAT } from '@/features/shared/constants/analytics'
import { type Endcap } from '@/features/shop/services/Search/types'
import {
  type StaticBannerShelfItems,
  type CommerceUnitShelfItems,
} from '@/features/shop/services/Shelves/types'
import { type ElementViewed } from '@shipt/analytics-member-web'
import { type ParsedUrlQuery } from 'querystring'

export enum PromotionScopeType {
  endcap = 'endcap',
  coupon = 'coupon',
  featured_promotion = 'featured promotion',
  criteo = 'criteo',
}

export const getStaticBannerShelfProperties = (
  banner: StaticBannerShelfItems,
  shelfIndex: number
): ElementViewed['properties'] => {
  const location = urlPathnameToLocationName()
  return {
    location,
    display_format: DISPLAY_FORMAT.SHELF,
    display_sublocation: STATIC_BANNER_SHELF_TYPE,
    shelf_index: shelfIndex,
    next_location: banner.link_url,
    type: banner.featured_source,
  }
}

export const getCommerceUnitShelfProperties = (
  shelfItem: CommerceUnitShelfItems,
  shelfIndex: number
): ElementViewed['properties'] => {
  const location = urlPathnameToLocationName()
  return {
    location,
    display_format: DISPLAY_FORMAT.SHELF,
    display_sublocation: COMMERCE_PRODUCT_BANNER_SHELF_TYPE,
    shelf_index: shelfIndex,
    next_location: shelfItem?.rendering?.optional_footer_redirect_url,
    type: shelfItem?.featured_source,
  }
}

export const getEndcapProperties = ({
  endcap,
  query,
  gridIndex,
}: {
  endcap: Endcap
  query: ParsedUrlQuery
  gridIndex: number
}): ElementViewed['properties'] => ({
  location: urlPathnameToLocationName(),
  display_sublocation: 'banner endcap',
  display_format: DISPLAY_FORMAT.GRID,
  next_location: endcap.click_uri,
  grid_index: gridIndex,
  category_name: endcap.metadata?.tag_line || endcap.title,
  type: endcap.source,
  ...(query.query && { content: String(query.query) }),
  ...(endcap.category_id && { category_id: endcap.category_id }),
})

export const getProgressiveRevealShelfProperties = (
  // https://app.clickup.com/t/86b1geg8p
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  shelfItem: any,
  shelfIndex: number
): Omit<ElementViewed['properties'], 'location'> => {
  return {
    display_format: DISPLAY_FORMAT.HORIZONTAL,
    display_sublocation: shelfItem?.name,
    shelf_name: shelfItem?.name,
    shelf_index: shelfIndex,
    type: DISPLAY_FORMAT.SHELF,
  }
}
