import { parseUrl } from '@/features/shared/utils/queryString'

export const parseCategoryId = (url: string) => {
  const query = parseUrl(url).query
  const category_id = query.category_id
  return typeof category_id === 'string' && !isNaN(+category_id)
    ? +category_id
    : undefined
}

export const parsePromotionCategoryId = (url: string) => {
  const { query } = parseUrl(url)
  const { promotion_category_id } = query
  return typeof promotion_category_id === 'string' &&
    !isNaN(+promotion_category_id)
    ? promotion_category_id
    : undefined
}

export const parseCategoryIds = (url: string) => {
  const query = parseUrl(url).query
  const category_ids = query.category_ids
  try {
    if (typeof category_ids === 'string') {
      const parsedIds = JSON.parse(category_ids)
      return parsedIds[0]
    }
  } catch {
    return undefined
  }
  return undefined
}

export const parseCategoryName = (url: string) => {
  const query = parseUrl(url).query
  const category_name = query.category_name
  return typeof category_name === 'string' ? category_name : undefined
}
