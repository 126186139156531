import { type Content } from '@/features/cms/components/CMS/types'
import { useFetchDeferredHydration } from '@/features/cms/services/CMS/queries'

type Props = {
  data: Content
  // eslint-disable-next-line react/no-unused-prop-types -- https://app.clickup.com/t/86azap57n: will be used when we add shelf specific skeletons.
  contentTypeId: string
  children: (data: Content) => JSX.Element
}

export const DeferredShelf = ({ data, children }: Props) => {
  const {
    isError,
    isLoading,
    data: deferredShelvesData,
  } = useFetchDeferredHydration(data.hydration_callback)
  if (!data.hydration_callback) {
    return children(data)
  }

  if (isLoading || !deferredShelvesData?.data || isError) {
    return null
  }

  return children(deferredShelvesData)
}
