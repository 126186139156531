import { ExperimentType } from '@/features/shared/services/Experiments/constants'
import {
  ExperimentValue,
  type AristotleExperiments,
} from '@/features/shared/services/Experiments/types'
import { getObjectFromEntries } from '@/features/shared/utils/types'

export const defaultExperiments: AristotleExperiments = getObjectFromEntries(
  Object.values(ExperimentType).map((type) => {
    return [type, { value: ExperimentValue.Control }]
  })
)
