import {
  type CustomerBaseResponse,
  type User,
} from '@/features/account/services/User/types'
import { type UserTraitsEvent } from '@/features/shared/analytics/users'
import { isOnServer } from '@shared/constants/util'
import { addCloudModeIntegrations } from '@/features/shared/analytics/integrations'
import {
  type AnalyticsEvent,
  getAnonymousId,
  gtmDestinationEvents,
  reset,
} from '@shipt/analytics-member-web'
import { getDataRightsTrait } from '@/features/account/services/DataPrivacy/utils'

import { getJotaiStore } from '@/features/shared/state'
import { routeDataAtom } from '@/features/shared/state/RouteData/atoms'
import { GuestDataStore } from '@/features/shared/constants/global'
import { getCookie } from '@/features/shared/utils/cookies'
import { getUser, getUserZip } from '@/features/account/services/User/utils'
import { getQueryClient } from '@/features/shared/utils/dataFetching/reactQuery/SegwayQueryClient'

// These traits about a user are recorded using the Identify API.
// Please read the Identify API spec for information on why
// we do this: https://segment.com/docs/spec/identify/

export const getUserTraits = (
  user: CustomerBaseResponse | User
): UserTraitsEvent => {
  const {
    id,
    name = '',
    email,
    store,
    metro,
    phone,
    marketing_phone,
    metro_id,
    store_location_id,
    order_delivery_type,
    universal_id,
    exempt,
    subscription,
  } = user || {}
  const zip = 'customer_addresses' in user ? getUserZip(user) : undefined
  const plan_type =
    'plan_type' in user ? user.plan_type ?? undefined : undefined
  return {
    id,
    name,
    first_name: firstName(name),
    last_name: lastName(name),
    email,
    phone: phone ? `1${phone}` : phone,
    metro: metro?.name,
    metro_id: metro_id ?? -1,
    store: store?.name ?? '',
    store_id: store?.id ?? -1,
    store_location_id: store_location_id ?? -1,
    order_delivery_type: order_delivery_type ?? '',
    universal_id: universal_id ?? -1,
    exempt,
    plan_type,
    do_not_share_sell_ta:
      'do_not_share_sell_ta' in user && user.do_not_share_sell_ta,
    ...(subscription && {
      subscription_status: subscription?.status ?? '',
    }),
    zip,
    ...(marketing_phone && { marketing_phone }),
  }
}

export const getGuestTrait = () => {
  const user = getUser(getQueryClient())
  const guestAddressCookie = getCookie(GuestDataStore.ADDRESS)
  return {
    guest: Boolean(guestAddressCookie && !user?.id),
  }
}

const firstName = (name: string) => {
  const splittedName = name.split(' ')
  return splittedName.length > 0 ? splittedName[0] || '' : name
}
const lastName = (name: string) => {
  const splittedName = name.split(' ')
  return splittedName.length > 1
    ? splittedName[splittedName.length - 1] || ''
    : ''
}

// Data science wants us to send a location property. When a search is
// executed from the HeaderContainer, we need a way to know the search origin
export const urlPathnameToLocationName = (): string => {
  if (isOnServer()) return ''
  const routeMatch = getJotaiStore().get(routeDataAtom)
  if (!routeMatch) return ''

  // Get dynamic page pathname for CMS routes instead of using trackingName value
  // Removes beginning '/' to match trackingName patterns
  if ('dynamicPath' in routeMatch) {
    const url = new URL(window.location.href)
    return url.pathname.substring(1)
  }

  return routeMatch.trackingName || ''
}
// https://segment.com/docs/connections/spec/identify/
export const identify = (userId: number, traits: UserTraitsEvent) => {
  if (shouldDisableAnalytics()) return
  const anonymousId = getAnonymousId()
  window.analytics?.setAnonymousId(anonymousId)

  window.analytics?.identify(
    String(userId),
    {
      ...traits,
      ...getDataRightsTrait(),
    },
    {
      integrations: addCloudModeIntegrations(),
      anonymousId,
    }
  )
}

export const resetAnalytics = () => {
  if (shouldDisableAnalytics()) return
  window.analytics?.reset()
  reset()
}

// disable segment for cypress, lighthouse, and load tests as it contributes to high event usage and MTU costs
export const shouldDisableAnalytics = () =>
  !isOnServer() &&
  Boolean(window?.location?.search?.includes('disable_segment=true'))

export const shouldSendToGoogle = (eventName: AnalyticsEvent) =>
  process.env.NEXT_PUBLIC_GTM_CONTAINER_ID && eventName in gtmDestinationEvents

export const getPageData = () => ({
  path: location.pathname,
  referrer: document.referrer,
  search: location.search,
  title: document.title,
  url: location.href,
})
