import { queryOptions, useQuery } from '@tanstack/react-query'
import {
  fetchCMSCatNavData,
  fetchCMSData,
  fetchDeferredHydration,
  fetchOrderPlacedStoreCarousel,
} from '@/features/cms/services/CMS/fetchers'
import { type StoreParams } from '@/features/shared/utils/dataFetching/storeParams'
import { type getAddressParamsForCMS } from '@/features/cms/services/CMS/utils'
import { useCMSParamsWithoutSlug } from '@/features/cms/services/CMS/hooks'
import { useIsDataRightsRelatedQueryEnabled } from '@/features/account/services/DataPrivacy/hooks'
import { type Content } from '@/features/cms/components/CMS/types'

type StoreParamsAndAddressParams = {
  storeParams?: StoreParams
  addressParams?: ReturnType<typeof getAddressParamsForCMS>
}

export const CMS_RHP_SLUG = 'shop'
export const CMS_GHP_SLUG = 'shop/stores'

export const getCMSQueryKeySlug = (slug: string): Readonly<`CMS_${string}`> =>
  `CMS_${slug}`

export const cmsQueryOptions = ({
  slug,
  storeParams,
  addressParams,
}: StoreParamsAndAddressParams & {
  slug: string
}) => {
  return queryOptions({
    queryKey: [getCMSQueryKeySlug(slug), { slug, storeParams, addressParams }],
    queryFn: fetchCMSData,
  })
}

export const useQueryCMS = ({
  params,
  enabled,
  keepPreviousData,
}: {
  params: ReturnType<typeof useCMSParams>
  enabled: boolean
  keepPreviousData?: boolean
}) => {
  return useQuery({
    ...cmsQueryOptions(params),
    enabled: useIsDataRightsRelatedQueryEnabled(enabled),
    placeholderData: (prevData) => (keepPreviousData ? prevData : undefined),
  })
}

export const catNavCmsQueryOptions = ({
  storeParams,
  addressParams,
}: StoreParamsAndAddressParams) =>
  queryOptions({
    queryKey: ['CMS_CAT_NAV', { storeParams, addressParams }],
    queryFn: fetchCMSCatNavData,
  })

export const useQueryCatNavCMS = () => {
  const { storeParams, addressParams } = useCMSParamsWithoutSlug()
  return useQuery({
    ...catNavCmsQueryOptions({ storeParams, addressParams }),
    enabled: Boolean(storeParams.store_location_id),
  })
}

export const useCMSParams = (slug: string) => {
  const cmsParamsWithoutSlug = useCMSParamsWithoutSlug()
  return {
    ...cmsParamsWithoutSlug,
    slug,
  }
}

export const useFetchDeferredHydration = (
  hydrationCallback: Content['hydration_callback']
) => {
  return useQuery({
    queryFn: () =>
      fetchDeferredHydration(
        hydrationCallback ?? {
          url: '',
          headers: {},
          body: {},
        }
      ),
    queryKey: ['DeferredCallback', hydrationCallback?.url ?? ''],
    enabled: !!hydrationCallback,
  })
}

const ProgressiveRevealShelf = 'Progressive reveal shelves' as const
export type ProgressiveRevealShelfQueryKey = [
  typeof ProgressiveRevealShelf,
  {
    storeParams: StoreParams
    productId: number | null
    searchQuery: string | string[]
    userId: number | null
  }
]

type ProgressiveRevealShelfQueryKeyParams = {
  storeParams: StoreParams
  productId: number | null
  searchQuery: string | string[]
  userId: number | null
}

export const getProgressiveRevealShelfQueryKey = ({
  storeParams,
  productId,
  searchQuery,
  userId,
}: ProgressiveRevealShelfQueryKeyParams): ProgressiveRevealShelfQueryKey => {
  return [
    ProgressiveRevealShelf,
    { storeParams, productId, searchQuery, userId },
  ] as const
}

export type ProgressiveRevealShelfQueryKeyReturnType = ReturnType<
  typeof getProgressiveRevealShelfQueryKey
>

export const orderPlacedStoresCarouselOptions = ({
  storeParams,
  addressParams,
}: StoreParamsAndAddressParams) =>
  queryOptions({
    queryKey: [
      'CMS_ORDER_PLACED_STORES_CAROUSEL',
      { storeParams, addressParams },
    ],
    queryFn: fetchOrderPlacedStoreCarousel,
  })

export const useQueryOrderPlacedStoresCarousel = () => {
  const { storeParams, addressParams } = useCMSParamsWithoutSlug()

  return useQuery({
    ...orderPlacedStoresCarouselOptions({ storeParams, addressParams }),
    enabled: Boolean(addressParams?.address_id),
  })
}
