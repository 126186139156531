import styled from 'styled-components'
import { CarouselButton } from '@/features/shared/elements/Buttons'
import { hide as hideAnimation } from '@/features/shared/styles/effects'
import { type SurfaceOption, screenSizes } from '@shipt/design-system-themes'
import { rgba } from 'polished'
import { ChevronLeftIcon, ChevronRightIcon } from '@shipt/design-system-icons'

type Direction = 'left' | 'right'

type Props = {
  onClick?: () => void
  direction?: Direction
  hide?: boolean
  size?: 'md' | 'lg'
  surface?: SurfaceOption
}

export const NavButton = ({
  onClick,
  direction = 'left',
  hide = false,
  size = 'lg',
  surface = 'default',
}: Props) => {
  const isLeft = direction === 'left'
  const ariaLabel = isLeft ? 'Previous items' : 'Next items'

  return (
    <div className="hide-keyboarduser">
      <OverLayerWrapper direction={direction} hide={hide} surface={surface} />
      <ButtonWrapper
        direction={direction}
        hide={hide}
        // Don't remove this className, it's used for Testimonials
        className="NavButton-wrapper"
      >
        <CarouselButton
          type="button"
          size={size}
          variant="tertiary"
          icon={isLeft ? ChevronLeftIcon : ChevronRightIcon}
          onClick={onClick}
          aria-label={ariaLabel}
          // Do not remove this className, it is used within SubstitutionCard
          className={`NavButton-${direction}`}
        />
      </ButtonWrapper>
    </div>
  )
}

const Wrapper = styled.div<{ hide: boolean; direction: Direction }>`
  ${({ direction }) => `${direction}: 0`};
  z-index: 2;
  position: absolute;
  ${({ hide }) => hide && hideAnimation};
  display: none;

  @media ${screenSizes.tablet} {
    display: ${({ hide }) => (hide ? 'none' : 'block')};
  }
`

const OverLayerWrapper = styled(Wrapper)<{ surface: SurfaceOption }>`
  ${({ direction }) => `${direction}: -0.5rem`};
  top: 0;
  width: 5rem;
  height: 100%;
  pointer-events: none;
  background: ${({ direction, theme, surface }) =>
    surface === 'default'
      ? `linear-gradient(to ${direction}, ${rgba(theme.white, 0)} 0%, ${rgba(
          theme.white,
          0.62
        )} 39.46%, ${theme.white})`
      : `linear-gradient(to ${direction}, ${rgba(theme.plum, 0)} 0%, ${rgba(
          theme.plum,
          0.62
        )} 39.46%, ${theme.plum})`};
`

const ButtonWrapper = styled(Wrapper)`
  top: 50%;
  transform: ${({ direction }) =>
    `translate(${direction === 'left' ? '-25%' : '25%'}, -50%)`};
`
