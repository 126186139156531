import {
  type Subscription,
  type SubscriptionResponse,
  type SubscriptionPhase,
  MembershipPlanType,
} from '@/features/account/services/MembershipPlan/types'
import { type User } from '@/features/account/services/User/types'

export const subscriptionForUser = (userId: number | string) =>
  `/v1/customers/${userId}/subscriptions`

export const toMembershipPlan = (
  subscription?: Maybe<SubscriptionResponse>
): Subscription => {
  if (subscription?.schedule?.phases) {
    subscription.schedule.phases = coalesceTrialPhases(
      subscription.schedule.phases
    )
  }
  return {
    id: subscription?.plan?.id ?? '',
    name: subscription?.plan?.name ?? '',
    trialDays: subscription?.plan?.trial_period_days ?? 0,
    metadata: subscription?.plan?.metadata ?? {},
    price: (subscription?.plan?.amount ?? 0) / 100,
    startDate: epochSecondsToISO(subscription?.start),
    currentPeriodStartDate: epochSecondsToISO(
      subscription?.current_period_start
    ),
    renewalDate: epochSecondsToISO(subscription?.current_period_end),
    trialStart: epochSecondsToISO(subscription?.trial_start),
    trialEnd: epochSecondsToISO(subscription?.trial_end),
    duration: subscription?.plan?.duration ?? '',
    cancelAtPeriodEnd: subscription?.cancel_at_period_end ?? false,
    interval: subscription?.plan?.interval ?? '',
    intervalCount: subscription?.plan?.interval_count ?? 0,
    refundable: subscription?.refundable ?? false,
    status: subscription?.status || '',
    schedule: subscription?.schedule ?? {},
    type: subscription?.type ?? '',
  }
}

export const coalesceTrialPhases = (phases: SubscriptionPhase[]) =>
  phases.reduce<SubscriptionPhase[]>((acc, phase) => {
    const lastAcc = acc[acc.length - 1]
    if (lastAcc?.trial_end && phase.trial_end) {
      lastAcc.end_date = phase.end_date
      lastAcc.trial_end = phase.trial_end
    } else {
      acc.push(phase)
    }
    return acc
  }, [])

export const epochSecondsToISO = (time?: Maybe<number>) => {
  try {
    return time ? new Date(time * 1000).toISOString() : ''
  } catch {
    return ''
  }
}

export const getIsMember = (user: User, membership: Subscription) => {
  return Boolean(
    (user.exempt && membership.type !== MembershipPlanType.CIRCLE_360) ||
      membership.id
  )
}
