import { EmptyState } from '@/features/shop/components/EmptyState'
import styled from 'styled-components'

export const NoDataState = () => (
  <EmptyStateContainer>
    <EmptyState
      text="Sorry, we are having trouble loading this page."
      subText="In the meantime..."
    />
  </EmptyStateContainer>
)
const EmptyStateContainer = styled.div`
  margin-top: 6rem;
`
