import {
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query'
import { minutesToMilliseconds } from 'date-fns/minutesToMilliseconds'
import orderBy from 'lodash/orderBy'
import {
  useUserHasPlacedFirstOrder,
  useUserId,
} from '@/features/account/services/User/hooks'
import {
  type NormalizedOrderHistoryResponse,
  type Order,
} from '@/features/shop/services/Orders/types'
import {
  type StoreParamsAsKey,
  useStoreParams,
} from '@/features/shared/utils/dataFetching/storeParams'
import {
  fetchActiveOrders,
  fetchLastDeliveredOrder,
  fetchOrder,
  fetchOrderTracking,
  fetchOrdersHistory,
} from '@/features/shop/services/Orders/fetchers'
import { useOrderQuery } from '@/features/shop/services/Order/graphql/order.generated'
import { secondsToMilliseconds } from 'date-fns/secondsToMilliseconds'
import { useOrderEngagementQuery } from '@/features/shop/services/OrderEngagement/graphql/orderEngagement.generated'
export const OrderQuery = 'order'
export const OrdersQuery = 'Orders'
export const ActiveOrdersQuery = 'Active orders'
export const LastDeliveredOrderQuery = 'Last delivered order'
export const UpdateOrderMutation = 'Update order'
export const OrderTrackingQuery = 'order tracking'
export const OrderEngagementETAQuery = 'Order Engagement ETA'

const getActiveOrdersQueryKey = ({ storeParams }: StoreParamsAsKey) => {
  return [ActiveOrdersQuery, { storeParams }] as const
}
export type ActiveOrdersQueryKey = ReturnType<typeof getActiveOrdersQueryKey>

export const useQueryActiveOrders = () => {
  const storeParams = useStoreParams()
  const hasPlacedFirstOrder = useUserHasPlacedFirstOrder()

  return useQuery({
    queryKey: getActiveOrdersQueryKey({ storeParams }),
    queryFn: fetchActiveOrders,
    placeholderData: (prevData) => prevData,
    // store_location_id is also needed along with hasPlacedFirstOrder since
    // it is possible for a user to have placed an order on target.com (platform)
    // but not have a store selected yet on the shipt app (store_location_id is null)
    enabled: Boolean(storeParams.store_location_id && hasPlacedFirstOrder),
  })
}

useQueryActiveOrders.fetcher = fetchActiveOrders
useQueryActiveOrders.getKey = getActiveOrdersQueryKey

const ordersHistorySelect = (response: NormalizedOrderHistoryResponse) => {
  const upcomingOrders = orderBy(
    response.upcomingOrders ?? [],
    ['id'],
    ['desc']
  )
  const pastOrders = orderBy(response.pastOrders ?? [], ['id'], ['desc'])
  return { upcomingOrders, pastOrders }
}
export const useQueryOrdersHistory = () => {
  const user_id = useUserId()
  return useQuery({
    queryKey: [OrdersQuery],
    queryFn: fetchOrdersHistory,
    enabled: Boolean(user_id),
    select: ordersHistorySelect,
  })
}

const getLastDeliveredOrderKey = () => {
  return [LastDeliveredOrderQuery] as const
}

export type LastDeliveredOrderKey = ReturnType<typeof getLastDeliveredOrderKey>

export const useQueryLastDeliveredOrder = () => {
  const storeParams = useStoreParams()
  const user_id = useUserId()
  const { store_location_id } = storeParams
  const hasPlacedFirstOrder = useUserHasPlacedFirstOrder()
  const shouldEnable = Boolean(
    hasPlacedFirstOrder && store_location_id && user_id
  )

  return useQuery({
    queryKey: getLastDeliveredOrderKey(),
    queryFn: fetchLastDeliveredOrder,
    enabled: shouldEnable,
    staleTime: minutesToMilliseconds(15),
    // this is to ensure useQuery does no do any deep object comparison
    structuralSharing: false,
  })
}
useQueryLastDeliveredOrder.getKey = getLastDeliveredOrderKey
useQueryLastDeliveredOrder.fetcher = fetchLastDeliveredOrder

export const useQueryOrder = (
  id: number,
  options: Omit<
    UseQueryOptions<Awaited<ReturnType<typeof fetchOrder>>>,
    'queryKey'
  > = {
    enabled: true,
  }
) => {
  const queryClient = useQueryClient()
  const user_id = useUserId()

  return useQuery({
    queryKey: useOrderQuery.getKey({ order_id: id }),
    queryFn: (context) => fetchOrder(id, queryClient, context),
    staleTime: minutesToMilliseconds(15),
    ...options,
    enabled: Boolean(id && options.enabled && user_id),
  })
}

export const getOrderTrackingQueryKey = ({ orderId }: { orderId: number }) => {
  return [OrderTrackingQuery, orderId] as const
}

export type OrderTrackingQueryKey = ReturnType<typeof getOrderTrackingQueryKey>

export const useQueryOrderTracking = (
  orderId: number,
  orderStatus: Order['status']
) => {
  const queryKey = getOrderTrackingQueryKey({ orderId })

  return useQuery({
    queryKey,
    queryFn: (context) => fetchOrderTracking(context),
    //we would like members or have realtime experience until we get the websockets
    //average rate for shoppers to add products is around 85seconds, hence 90secs refetchinterval when order status is shopping
    ...(orderStatus === 'shopping' && {
      refetchInterval: secondsToMilliseconds(90),
    }),
  })
}

export const useQueryOrderEngagementETA = (
  orderId: number,
  isEnabled: boolean
) => {
  return useOrderEngagementQuery(
    { orderId },
    {
      refetchInterval: minutesToMilliseconds(4),
      enabled: Boolean(orderId) && isEnabled,
    }
  )
}
