import styled, { css } from 'styled-components'
import {
  type InputElementProps,
  type HelperTextProps,
} from '@/features/shared/elements/Inputs/types'
import { fontWeights } from '@/features/shared/styles/typography/constants'
import { Body2 } from '@/features/shared/styles/typography'
import { TransparentButton } from '@/features/shared/elements/Buttons'
import isPropValid from '@emotion/is-prop-valid'

// This is only intended to be used within Inputs.  All other typography elements should use components found in `styles/typography`
export const Body1FontStyles = css`
  /* Font Styles for Body 1 */
  font-weight: ${fontWeights.book};
  line-height: 1.5rem;
  font-size: 1.0625rem;
`

export const InputElement = styled.input.withConfig({
  shouldForwardProp: (prop) => prop === 'passwordrules' || isPropValid(prop),
})<InputElementProps>`
  width: 100%;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.gray900};
  caret-color: ${({ hasError, theme }) =>
    hasError ? theme.red500 : theme.plum};

  &:disabled {
    color: ${({ theme }) => theme.gray400};
  }

  &::placeholder {
    color: ${({ theme }) => theme.gray600};
  }

  &:focus-within::placeholder {
    color: ${({ theme }) => theme.gray400};
  }

  ${Body1FontStyles}
`

export const InputContainer = styled.div`
  width: 100%;
`

export const HelperMessagesGroup = styled.div<{
  shouldRemoveHelperTextGroupMargin?: boolean
}>`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: 'start end';
  gap: 16px;

  &:empty {
    margin-bottom: ${({ shouldRemoveHelperTextGroupMargin }) =>
      !shouldRemoveHelperTextGroupMargin && '24px'};
  }
`

export const HelperText = styled(Body2)<HelperTextProps>`
  margin-top: 0.25rem;
  grid-area: ${({ positionEnd = false }) => (positionEnd ? 'end' : 'start')};
  color: ${({ theme, hasError, surface = 'default' }) =>
    hasError
      ? surface === 'default'
        ? theme.red500
        : theme.red400
      : theme.gray600};
`

export const InputButton = styled(TransparentButton)`
  display: flex;
  align-items: center;
  z-index: 2;
`
