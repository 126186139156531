import { type ShiptTheme } from '@/features/shared/constants/shiptStaticData'
import { staticColors } from '@/features/shared/theme/staticColors'
import { calcRem, type Size } from '@shipt/design-system-themes'

// The design system calcRem function returns a string without the rem unit.
const calcRems = (px: number) => {
  return `${calcRem(px)}rem`
}

type CSSSpacingValue = 'auto' | 'unset'
type SpacingValue = Size | CSSSpacingValue | number
type SpacingArgs =
  | [SpacingValue, SpacingValue, SpacingValue, SpacingValue]
  | [SpacingValue, SpacingValue, SpacingValue]
  | [SpacingValue, SpacingValue]
  | [SpacingValue]

const cssValues: CSSSpacingValue[] = ['auto', 'unset']
const isCssValue = (value: SpacingValue): value is CSSSpacingValue => {
  // This is needed in order to narrow the type value that is being passed to the spacing function
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return cssValues.includes(value as CSSSpacingValue)
}

/**
 *  This is a custom function that we use to create a spacing function that
 * can be used in styled components. It allows for 1, 2, 3, or 4 spacing
 * arguments to be passed in. If a number is passed in, it will be converted
 * to rems. If a theme spacing value is passed in, it will access the numeric
 * value from the theme and then convert it to rems.
 *
 */
export const spacing =
  (...args: SpacingArgs) =>
  ({ theme }: { theme: ShiptTheme }) => {
    return args
      .map((arg) => {
        if (isCssValue(arg) || arg === 0) {
          return arg
        }
        if (typeof arg === 'number') {
          return calcRems(arg)
        }
        return calcRems(theme.spacing[arg])
      })
      .join(' ')
  }

export const themeTokens = {
  radii: {
    0: '0',
    2: calcRems(2),
    4: calcRems(4),
    8: calcRems(8),
    16: calcRems(16),
    full: '50%',
    pill: '100px',
  },
  shadows: {
    1: `0px 2px 8px 0px ${staticColors.gray900}33`,
    2: `0px 4px 16px 4px ${staticColors.gray900}1A`,
  },
}
