import Head from 'next/head'
import { environment } from '@/features/shared/utils/environment'
import { buildStructuredDataByPage } from '@/features/shared/utils/seo'
import { useRouter } from 'next/router'
import { WHITE_LABEL_DOMAIN } from '@/features/shared/constants/whitelabel'
import { objectStorageUrl } from '@/features/shared/utils/objectStorageUrl'

export enum OpenGraphTypes {
  WEBSITE = 'website',
  ARTICLE = 'article',
}

type Props = {
  description: string
  title?: string
  imageUrl?: string
  publishedTime?: string
  type?: OpenGraphTypes
  doNotIndex?: boolean
  doNotFollow?: boolean
}

export const DocumentHead = ({
  description,
  title,
  imageUrl,
  publishedTime,
  type = OpenGraphTypes.WEBSITE,
  doNotIndex = false,
  doNotFollow = false,
}: Props) => {
  const nonce = process.env.NEXT_PUBLIC_NONCE_PLACEHOLDER

  const getRobotsContent = () => {
    if (environment() !== 'production' || (doNotIndex && doNotFollow)) {
      return 'noindex,nofollow'
    }
    if (doNotIndex) return 'noindex'
    if (doNotFollow) return 'nofollow'
    return 'max-snippet:-1, max-image-preview:large, max-video-preview:-1'
  }
  const rootDomain = `https://www.${WHITE_LABEL_DOMAIN}`
  const previewImageUrl = imageUrl ?? objectStorageUrl('seo-preview.png')
  const { asPath, isReady } = useRouter()
  const pageStructuredData = buildStructuredDataByPage({
    title,
    slug: asPath,
    description,
    type,
    publishedTime,
    imageUrl: previewImageUrl,
  })
  const canonicalUrl = `${rootDomain}${asPath.split('?')[0]}`

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="robots" content={getRobotsContent()} />
      <meta property="og:title" content={title} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content={type} />
      {isReady && <meta property="og:url" content={canonicalUrl} />}
      <meta property="og:site_name" content="Shipt Grocery Delivery" />
      <meta property="og:image" content={previewImageUrl} />
      <meta property="og:image:secure_url" content={previewImageUrl} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@Shipt" />
      <meta name="twitter:image" content={previewImageUrl} />
      <meta name="twitter:creator" content="@Shipt" />
      {type === OpenGraphTypes.ARTICLE && (
        <>
          <meta
            name="article:publisher"
            content="https://www.facebook.com/Shipt"
          />
          {publishedTime && (
            <meta property="article:published_time" content={publishedTime} />
          )}
        </>
      )}
      <meta
        name="google-site-verification"
        content="-uT3EyH_XO74edf6qtNyUKNittSYs5J1yW38p3PacGY"
      />
      {isReady && <link rel="canonical" href={canonicalUrl} />}
      {/**
       * JSON-LD structured data
       * dangerouslySetInnerHTML is used here because inserting the serialized object directly
       * as a string will result in React HTML-escaping the text, which bots can't crawl.
       */}
      <script
        type="application/ld+json"
        nonce={nonce}
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(pageStructuredData),
        }}
      />
    </Head>
  )
}
