import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  type SearchCouponsWithIdResponse,
  type SearchCouponProductsResponse,
  type CouponWithItemsUpcList,
} from '@/features/shop/services/Coupons/types'
import { useCurrentActiveOrderId } from '@/features/shop/state/SelectedActiveOrder/hooks'
import { normalizeCoupon } from '@/features/shop/utils/normalizeCoupon'
import { normalizeProduct } from '@/features/shared/utils/normalizeProduct'
import { apiPost } from '@/features/shared/utils/dataFetching'
import {
  useStoreParams,
  type StoreParamsAsKey,
  useCombinedStoreParams,
} from '@/features/shared/utils/dataFetching/storeParams'
import { fetchCouponsSavings } from '@/features/shop/services/Coupons/fetchers'
import { type QueryOptions } from '@/features/shared/utils/dataFetching/types'
import { resetCouponsUsageData } from '@/features/shop/services/Coupons/utils'

export const SearchProductsWithBarcodesQueryKey =
  'Search Products With Barcodes'
export const SearchCouponsQueryKey = 'Search Coupons'

export const useQuerySearchCoupons = (id: string) => {
  const storeParams = useStoreParams()
  const { store_location_id } = storeParams

  return useQuery({
    queryKey: [SearchCouponsQueryKey, { id }],
    queryFn: async (): Promise<CouponWithItemsUpcList[]> => {
      const data = await apiPost<SearchCouponsWithIdResponse>({
        config: {
          url: '/coupon_service/v1/coupons.search',
          data: {
            quotient_coupon_id_list: [id],
          },
        },
        options: { storeParams },
        fetcherName: 'useQuerySearchCoupons',
      })
      return (data?.hits ?? []).map((coupon) => ({
        ...normalizeCoupon(coupon),
        itemsUpcList: coupon.items_upc12 || [],
      }))
    },
    enabled: Boolean(store_location_id && id),
  })
}

const getCouponsSavingsQueryKey = (
  params: StoreParamsAsKey & { currentActiveOrderId?: number }
) => [CouponsSavingsQuery, params] as const

export const CouponsSavingsQuery = 'Coupons Savings'
export type CouponsSavingsQueryKey = ReturnType<
  typeof getCouponsSavingsQueryKey
>

export const useQueryCouponsSavings = (queryOptions?: QueryOptions) => {
  const queryClient = useQueryClient()
  const storeParams = useCombinedStoreParams()
  const { store_location_id } = storeParams
  const currentActiveOrderId = useCurrentActiveOrderId()

  return useQuery({
    queryKey: getCouponsSavingsQueryKey({ currentActiveOrderId, storeParams }),
    queryFn: async (context) => {
      const couponSavings = await fetchCouponsSavings(context)
      /**
       * The coupon_service/v1/coupons.calculateSavings service does not return a coupon back in the API response
       * if it is no longer applicable to the cart/order. This means we need to make sure we don't remove any existing
       * coupons in the query cache and reset their usage data manually whenever the service returns no coupons.
       */
      const currentCoupons = queryClient.getQueryData<typeof couponSavings>(
        getCouponsSavingsQueryKey({ currentActiveOrderId, storeParams })
      )
      if (!Object.values(couponSavings).length) {
        return currentCoupons ? resetCouponsUsageData(currentCoupons) : {}
      }
      return { ...currentCoupons, ...couponSavings }
    },
    enabled: Boolean(store_location_id),
    ...queryOptions,
  })
}

useQueryCouponsSavings.fetcher = fetchCouponsSavings
useQueryCouponsSavings.getKey = getCouponsSavingsQueryKey

export const useQuerySearchProductsWithBarcodes = (
  couponBarcodes?: string[],
  couponId?: string
) => {
  const barcodes = couponBarcodes?.map((barcode) => ({
    format: 'UPC_A',
    value: barcode,
  }))
  const { store_location_id, store_id, metro_id } = useStoreParams()
  const payload = {
    store_location_id,
    store_id,
    metro_id,
    barcodes,
  }

  return useQuery({
    queryKey: [SearchProductsWithBarcodesQueryKey, { payload }],
    queryFn: async () => {
      const response = await apiPost<SearchCouponProductsResponse>({
        config: { url: '/barcodes/v2/search', data: payload },
        fetcherName: 'useQuerySearchProductsWithBarcodes',
      })
      const { hits = [] } = response
      const normalizedProducts = hits.map(({ product }) => {
        return normalizeProduct({
          ...product,
          coupon_id: couponId,
        })
      })

      // we only want to store products with featured promotion if all the
      // products have featured promotion
      return normalizedProducts.some((p) => Boolean(p.couponId))
        ? normalizedProducts.filter((p) => Boolean(p.couponId))
        : normalizedProducts
    },
    enabled: Boolean(couponBarcodes && couponId && store_location_id),
  })
}
