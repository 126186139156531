import { type QueryFunctionContext } from '@tanstack/react-query'
import {
  type AutocompleteSuggestionsQueryKey,
  type ReverseGeocodeQueryKey,
} from '@/features/shop/services/GeoLocation/queries'
import {
  type GeoLocationIpResponse,
  type AutocompleteSuggestionsResponse,
  type GeoLocationAddress,
} from '@/features/shop/services/GeoLocation/types'
import { apiGet, apiPost } from '@/features/shared/utils/dataFetching'

const headers = { 'X-Shipt-Identifier': 'web' }

export const fetchCurrentLocation = (
  context: QueryFunctionContext<ReverseGeocodeQueryKey>
) => {
  const { queryKey } = context
  const [, coordinates] = queryKey

  return apiPost<GeoLocationAddress>({
    config: {
      url: '/geo/v1/reversegeocode',
      data: coordinates,
      headers,
    },
    fetcherName: 'fetchCurrentLocation',
  })
}

export const fetchAutocompleteSuggestions = async (
  context: QueryFunctionContext<AutocompleteSuggestionsQueryKey>,
  sessionToken: string
) => {
  const [, { query }] = context.queryKey
  const response = await apiPost<AutocompleteSuggestionsResponse>({
    config: {
      url: '/geo/v1/place/autocomplete',
      data: {
        input: query,
        options: {
          session_token: sessionToken,
          restrict_type: 'address',
        },
      },
      headers,
    },
    fetcherName: 'fetchAutocompleteSuggestions',
  })
  return response?.predictions ?? []
}

export const fetchPlaceDetails = ({
  placeId,
  sessionToken,
}: {
  placeId: string
  sessionToken: string
}) => {
  return apiPost<GeoLocationAddress>({
    config: {
      url: '/geo/v1/place/details',
      data: {
        place_id: placeId,
        options: {
          session_token: sessionToken,
        },
      },
      headers,
    },
    fetcherName: 'fetchPlaceDetails',
  })
}

/**
 * Fetches the geolocation of a given IP address.
 *
 * See: https://geo.us-central1.staging.shipt.com/docs/#operation/v1IPGeoLocation
 */
export const fetchGeoLocationByIp = (ip: string) =>
  apiGet<GeoLocationIpResponse>({
    config: { url: `geo/v1/ip/${ip}`, headers },
    options: { includeAuthHeader: false },
    fetcherName: 'fetchGeoLocationByIp',
  })
