import styled from 'styled-components'
import { Column } from '@shipt/design-system-layouts'
import { Body } from '@shipt/design-system-typography'
import { screenSizes } from '@shipt/design-system-themes'
import { useMediaQuery } from '@/features/shared/hooks/useMediaQuery'
import { Image } from '@/features/shared/elements/Image/NextImage'
import { objectStorageUrl } from '@/features/shared/utils/objectStorageUrl'

export const BagContainer = () => {
  const { screenSm, screenLg } = useMediaQuery()
  const getHeight = () => {
    if (screenSm) return 200
    if (screenLg) return 350
    return 300
  }
  const getWidth = () => {
    if (screenSm) return 300
    if (screenLg) return 550
    return 450
  }

  return (
    <Wrapper>
      <BagText>
        The things you want, delivered from the stores you love.
      </BagText>
      <Image
        alt=""
        src={objectStorageUrl('homepage-grocery-bag.webp')}
        height={getHeight()}
        width={getWidth()}
      />
    </Wrapper>
  )
}

const Wrapper = styled(Column)`
  height: 31.25rem;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.plum};

  img {
    margin-top: auto;
  }

  @media ${screenSizes.tablet} {
    height: auto;
  }
`

const BagText = styled(Body)`
  font-family: ClaremontMedium, georgia, times, serif;
  margin-top: 6.25rem;
  margin-bottom: 5rem;
  padding: 0 12.5rem;
  text-align: center;
  color: ${({ theme }) => theme.white};

  @media ${screenSizes.max_mobile} {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 0 2rem;
    font-size: 2.5rem;
    line-height: 3rem;
  }

  @media ${screenSizes.tablet} {
    padding: 0 6.25rem;
    font-size: 4rem;
    line-height: 70px;
  }

  @media ${screenSizes.smDesktop} {
    font-size: 7rem;
    line-height: 100px;
  }
`
