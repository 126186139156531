import styled from 'styled-components'
import {
  PII_CONTENTSQUARE_ATTR_NAME,
  PII_DYNATRACE_ATTR_NAME,
  PII_MOUSEFLOW_ATTR_NAME,
} from '@/features/shared/constants/pii'
import {
  type TypographyProps,
  type ComputedTypographyProps,
} from '@/features/shared/styles/typography/types'

/**
 * Private component used as the common ancestor of all text styled-components. Do not use this directly.
 */
export const BaseText = styled.p.attrs<
  TypographyProps & ComputedTypographyProps
>(({ isPII }) => ({
  ...(isPII && {
    [PII_MOUSEFLOW_ATTR_NAME]: '**REDACTED**',
    [PII_CONTENTSQUARE_ATTR_NAME]: '',
    [PII_DYNATRACE_ATTR_NAME]: '',
  }),
}))<TypographyProps>`
  color: ${({ theme, color }) => (color ? theme[color] : null)};
  text-align: ${({ textAlign }) => textAlign ?? null};
`
