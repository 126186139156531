import {
  type QueryClient,
  type QueryFunctionContext,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import {
  fetchUser,
  fetchUserGraphqlQuery,
} from '@/features/account/services/User/fetchers'
import { useSession } from '@/features/authentication/utils/authentication/hooks'
import {
  type CustomerBaseResponse,
  type FetchUserIfAuthOnSSR,
  type User,
} from '@/features/account/services/User/types'
import { type QueryOptions } from '@/features/shared/utils/dataFetching/types'
import { hasServerAuthData } from '@/features/authentication/utils/authentication/client/legacy/utils'
import { addressQueryOptions } from '@/features/account/services/Addresses/queries'
import { identifyUser } from '@/features/shared/analytics/users'
import { getIsUserGraphqlQueryEnabled } from '@/features/account/services/User/graphql/utils'
import { UserQuery } from '@/features/account/services/User/constants'
import { apiPatch } from '@/features/shared/utils/dataFetching'

const getUserQueryKey = () => [UserQuery] as const
const getUserQueryOptions = () => ({ staleTime: Infinity })

export type UserQueryKey = ReturnType<typeof getUserQueryKey>

const fetchUserWithEnvironmentVariableLogic = async (
  queryClient: QueryClient,
  context: QueryFunctionContext<UserQueryKey>
): Promise<User> => {
  let user = await (getIsUserGraphqlQueryEnabled()
    ? fetchUserGraphqlQuery(context)
    : fetchUser(context))

  try {
    /**
     * Update the user's default shopping address if it's not already
     * set and a customer address is available. This primarily covers
     * platform users going through the onboarding flow.
     */
    const [firstCustomerAddress] = user?.customer_addresses ?? []
    if (!user?.default_shopping_address_id && firstCustomerAddress?.id) {
      await apiPatch<CustomerBaseResponse>({
        config: {
          url: `api/v1/customers/${user?.id}`,
          data: { default_shopping_address_id: firstCustomerAddress.id },
        },
        ssrContext: context.meta?.ssrContext,
        fetcherName: 'updateDefaultAddressSSR',
      })
      user = {
        ...user,
        default_shopping_address_id: firstCustomerAddress.id,
      }
      queryClient.setQueryData<User>(getUserQueryKey(), user)
    }
  } catch (error) {
    // fail silently
  }

  /**
   * We are seeding the query cache with the user's addresses and credit cards
   * so that we don't have to make additional requests to fetch them until the
   * caches are invalidated.
   */
  queryClient.setQueryData(
    addressQueryOptions.queryKey,
    user.customer_addresses
  )
  identifyUser(user)
  return user
}

export const useQueryUser = ({
  notifyOnChangeProps,
}: {
  notifyOnChangeProps?: QueryOptions['notifyOnChangeProps']
}) => {
  const queryClient = useQueryClient()
  const currentUser = queryClient.getQueryData<User>([UserQuery])

  const { isSessionValid } = useSession()

  return useQuery({
    notifyOnChangeProps,
    queryKey: getUserQueryKey(),
    queryFn: (context) =>
      fetchUserWithEnvironmentVariableLogic(queryClient, context),
    enabled: Boolean(isSessionValid && currentUser?.id),
    ...getUserQueryOptions(),
  })
}

useQueryUser.fetcher = fetchUserWithEnvironmentVariableLogic
useQueryUser.getKey = getUserQueryKey
useQueryUser.options = getUserQueryOptions

export const fetchUserQuery = (queryClient: QueryClient) =>
  queryClient.fetchQuery({
    queryKey: useQueryUser.getKey(),
    queryFn: async (context) =>
      fetchUserWithEnvironmentVariableLogic(queryClient, context),
    ...useQueryUser.options(),
  })

export const fetchUserIfAuthOnSSR = async ({
  queryClient,
  ssrContext,
}: FetchUserIfAuthOnSSR) => {
  if (!hasServerAuthData(ssrContext)) return undefined
  try {
    return await fetchUserQuery(queryClient)
  } catch {
    // fail silently, not adding logging here since dataFetching already logs error for us
  }
}
