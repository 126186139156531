if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (search, replacement) {
    if (typeof search === 'string') {
      search = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    }

    const regex = new RegExp(search, 'g')
    return this.replace(regex, replacement)
  }
}
