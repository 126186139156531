import {
  normalizeProduct,
  getRequestedQuantityForProduct,
} from '@/features/shared/utils/normalizeProduct'
import { parseCurrency, formatPrice } from '@/features/shared/utils/formatter'
import {
  type OrderTrackingResponse,
  type Order,
  type OrderAPI,
  type OrderDisplayValue,
  type OrderLine,
} from '@/features/shop/services/Orders/types'
import {
  type TransformedOrderTracking,
  type Product,
} from '@/features/shop/services/Product/types'
import { type QueryClient } from '@tanstack/react-query'
import { LastDeliveredOrderQuery } from '@/features/shop/services/Orders/queries'
import { isToday } from 'date-fns/isToday'
import { isPastOrderStatus } from '@/features/shared/utils/orders'

const getNormalizedOrderLines = (order: OrderAPI): OrderLine<Product>[] => {
  if (!order.order_lines) return []
  // The products in the order.order_lines response here are not exactly the same
  // as products in other parts of our app (cart, for example) because
  // we add an additional "quantity" field to order products to track quantity changes
  // We may want to consider instead to only reference the `requested_qty` field
  // effectively removing `quantity` field from an order product,
  // so we can keep consistent, Prism product shape across all products (cart, order, etc)
  return order.order_lines.map((line) => ({
    ...line,
    notes: line.notes ?? '',
    actual_product: line.actual_product
      ? normalizeProduct({
          ...line.actual_product,
          quantity: line.actual_qty,
        })
      : undefined,
    actual_qty:
      line.actual_qty !== undefined && line.actual_qty >= 0
        ? Number(line.actual_qty)
        : undefined,
    requested_product:
      line.requested_product_type === 'CustomProduct'
        ? normalizeProduct({
            ...line.requested_product,
            quantity: line.requested_qty,
            product_id: line.requested_product.id,
            name: line.requested_product.description,
            product_type: line.requested_product_type,
            is_custom_product: true,
          })
        : normalizeProduct({
            ...line.requested_product,
            quantity: line.requested_qty,
          }),
    requested_qty: Number(line.requested_qty),
  }))
}

export const normalizeOrder = (order: OrderAPI): Order => ({
  ...order,
  order_lines: getNormalizedOrderLines(order),
})

export const getOrderDisplayValuesAsInt = (
  displayValues?: Maybe<OrderDisplayValue[]> | OrderDisplayValue
): Record<string, number> => {
  if (!displayValues) return {}
  const displayValuesList = Array.isArray(displayValues)
    ? displayValues
    : displayValues.display_values
  if (!displayValuesList) return {}
  return displayValuesList.reduce<Record<string, number>>(
    (accum, displayValue) => {
      if (displayValue.key) {
        accum[displayValue.key] = parseCurrency(displayValue.value ?? '')
      }
      if (displayValue.display_values) {
        const nested = getOrderDisplayValuesAsInt(displayValue)
        return {
          ...accum,
          ...nested,
        }
      } else {
        return accum
      }
    },
    {}
  )
}

export function getFormattedOrderDeliveryFee(order: Order) {
  const numericDeliveryFee =
    getOrderDisplayValuesAsInt(order.display_values)['delivery_fee'] ?? 0
  let formattedDeliveryFee = formatPrice(numericDeliveryFee)

  const emptySuffix = '.00'

  if (formattedDeliveryFee.endsWith(emptySuffix)) {
    formattedDeliveryFee = formattedDeliveryFee.substring(
      0,
      formattedDeliveryFee.length - emptySuffix.length
    )
  }
  return formattedDeliveryFee
}

export const getOrderLinesForUpdateOrder = (
  order: Order,
  products: Record<string | number, Product>
) =>
  order?.order_lines
    .map((line) => {
      const { requested_product } = line ?? {}
      const { id, name, isCustomProduct } = requested_product ?? {}
      const changedProductInOrder = products[isCustomProduct ? name : id]
      if (changedProductInOrder) {
        return {
          ...line,
          requested_qty: getRequestedQuantityForProduct(changedProductInOrder),
          requested_product: {
            ...requested_product,
            ...changedProductInOrder,
          },
          notes: changedProductInOrder.note || '',
        }
      }
      return line
    })
    .filter((line) => line.requested_qty > 0)

export const getLastDeliveredOrderQueryState = (queryClient: QueryClient) => {
  return queryClient
    .getQueryCache()
    .find({ queryKey: [LastDeliveredOrderQuery], exact: false })?.state
}

export const transformOrderTrackingProduct = (
  orderTrackingResponse: OrderTrackingResponse
): TransformedOrderTracking => {
  const foundProducts = []
  const notFoundProducts = []
  const stillShoppingProducts = []
  const substitutedProducts = []

  for (const product of orderTrackingResponse.products) {
    if (product.shop_status === 'found' && product.fulfilled) {
      foundProducts.push(product.fulfilled)
    } else if (product.shop_status === 'not_found' && product.requested) {
      notFoundProducts.push(product.requested)
    } else if (product.shop_status === 'not_shopped' && product.requested) {
      stillShoppingProducts.push(product.requested)
    } else if (
      product.shop_status === 'substituted' &&
      product.fulfilled &&
      product.requested
    ) {
      substitutedProducts.push({
        fulfilled: product.fulfilled,
        requested: product.requested,
      })
    }
  }

  return {
    foundProducts,
    notFoundProducts,
    stillShoppingProducts,
    substitutedProducts,
  }
}

export function findEarliestActiveOrderToday(orders: Order[]): Order | null {
  const todaysActiveOrders = orders.filter(isOrderLive)
  return todaysActiveOrders.reduce<Order | null>((prev, curr) => {
    if (!prev) return curr
    return new Date(prev.time_end) < new Date(curr.time_end) ? prev : curr
  }, null)
}

export function isOrderLive(order: Order): boolean {
  return (
    // order is not rescheduled and order is not in completed state
    isToday(new Date(order.time_end)) && !isPastOrderStatus(order.status)
  )
}
