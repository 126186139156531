import {
  useRef,
  type ReactNode,
  forwardRef,
  type ChangeEvent,
  type MutableRefObject,
  useEffect,
} from 'react'
import { type TextInputProps } from '@/features/shared/elements/Inputs/types'
import { InputWrapper } from '@/features/shared/elements/Inputs/InputWrapper'
import { InputElement } from '@/features/shared/elements/Inputs/styles'
import {
  useController,
  type UseControllerProps,
  type FieldValues,
  type Control,
  type RefCallBack,
} from 'react-hook-form'

type Props = TextInputProps & { children?: ReactNode; rhfRef?: RefCallBack }

/**
 * @deprecated Use { TextInput } from '@shipt/design-system-inputs' instead
 */
export const TextInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      type,
      children,
      enableClearButton = true,
      label,
      rhfRef,
      ...restProps
    }: Props,
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null) // union with null allows current to be mutable
    const newRef = ref || inputRef
    const { error } = restProps

    useEffect(() => {
      // the type for 'ref' with forwardRef is RefObject instead of MutableRefObject
      if (error && typeof newRef !== 'function' && newRef !== null) {
        newRef.current?.focus()
      }
    }, [error, newRef])

    return (
      <InputWrapper
        id={id}
        type={type}
        inputRef={newRef}
        enableClearButton={enableClearButton}
        label={label}
        {...restProps}
        renderInput={(inputProps) => (
          <InputElement
            placeholder=""
            {...inputProps}
            type={type}
            ref={(e) => {
              // ref type is marked as immutable when it actually isn't
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              ;(newRef as MutableRefObject<HTMLInputElement | null>).current = e
              rhfRef?.(e)
            }}
          />
        )}
      >
        {children}
      </InputWrapper>
    )
  }
)

type RHFTextInputProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<TextInputProps, 'onChange' | 'value'> & {
    control: Control<T>
    customOnChange?: (e: ChangeEvent<HTMLInputElement>) => void
  }
export const RHFTextInput = <T extends FieldValues>({
  onClear,
  id,
  error,
  type,
  label,
  customOnChange,
  disabled,
  maxLength,
  ariaDescribedBy,
  helperTextLeft,
  onKeyDown,
  inputMode,
  ...restProps
}: RHFTextInputProps<T>) => {
  const { field } = useController<T>(restProps)

  return (
    <TextInput
      id={id}
      name={field.name}
      label={label}
      onChange={(value) => {
        field.onChange(value)
        customOnChange?.(value)
      }}
      onClear={onClear}
      onBlur={field.onBlur}
      value={field.value}
      type={type}
      error={error}
      rhfRef={field.ref}
      disabled={disabled}
      maxLength={maxLength}
      ariaDescribedBy={ariaDescribedBy}
      helperTextLeft={helperTextLeft}
      inputMode={inputMode}
      onKeyDown={onKeyDown}
    />
  )
}
