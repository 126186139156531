import { useMutation, useQueryClient } from '@tanstack/react-query'
import { apiPatch } from '@/features/shared/utils/dataFetching'
import { type CustomerBaseResponse } from '@/features/account/services/User/types'
import { type SelectStoreParams } from '@/features/shop/services/ShoppingStore/types'
import { useUserId } from '@/features/account/services/User/hooks'
import { DELIVERY_TYPES } from '@/features/shared/constants/global'
import { routes } from '@shared/constants/routes'
import { UserQuery } from '@/features/account/services/User/constants'
import { useRouter } from 'next/router'
import { useMutationUpdateAddress } from '@/features/account/services/Addresses/mutations'
import { getAddresses } from '@/features/account/services/Addresses/utils'
import { cartOOSExpandedAtom } from '@/features/shop/state/Cart/atoms'
import { useClearActiveOrder } from '@/features/shop/state/SelectedActiveOrder/hooks'
import { useSetAtom } from 'jotai'
import {
  initialProgressiveRevealState,
  progressiveRevealAtom,
} from '@/features/shop/state/Products/atoms'
import { logError } from '@/features/shared/utils/logger'
import { shoppingStoresQueryOptions } from '@/features/shop/services/ShoppingStore/queries'
import { usePrefetchCmsShopQueries } from '@/features/cms/services/CMS/hooks'

export const SelectStoreMutationKey = 'Select store'

export const useMutationSelectStore = () => {
  const queryClient = useQueryClient()
  const userId = useUserId() ?? 0
  const router = useRouter()
  const clearActiveOrder = useClearActiveOrder()
  const { mutateAsync: updateAddress } =
    useMutationUpdateAddress('select_store')
  const setIsCartOOSExpanded = useSetAtom(cartOOSExpandedAtom)
  const setProgressiveReveal = useSetAtom(progressiveRevealAtom)
  const { prefetchCMSRetailerQueries } = usePrefetchCmsShopQueries()

  return useMutation({
    mutationKey: [SelectStoreMutationKey],
    mutationFn: (params: SelectStoreParams) => {
      const { id: store_id, metro_id, store_location_id } = params.store
      const payload = {
        store_id,
        default_shopping_address_id: params.addressId,
        order_delivery_type: params.isPickup
          ? DELIVERY_TYPES.PICKUP
          : DELIVERY_TYPES.STANDARD,
      }

      if (metro_id && store_id && store_location_id && !params.skipPrefetch) {
        prefetchCMSRetailerQueries({ metro_id, store_id, store_location_id })
      }
      return apiPatch<CustomerBaseResponse>({
        config: { url: `api/v1/customers/${userId}`, data: payload },
        fetcherName: 'useMutationSelectStore',
      })
    },
    onSuccess: async (response, params) => {
      const { shouldClearCurrentActiveOrder = true } = params

      if (shouldClearCurrentActiveOrder) {
        clearActiveOrder()
      }

      const cachedStore = queryClient
        .getQueryData(
          shoppingStoresQueryOptions({ address_id: params.addressId }).queryKey
        )
        ?.delivery_stores.find((store) => store.id === params.store.id)

      if (
        cachedStore &&
        response.store_location_id !== cachedStore.store_location_id
      ) {
        logError('store location mismatch', {
          cachedStoreLocation: cachedStore.store_location_id,
          responseStoreLocation: response.store_location_id,
        })
      }

      setIsCartOOSExpanded(true)
      setProgressiveReveal(initialProgressiveRevealState)

      if (params.isPickup) {
        const addresses = getAddresses(queryClient)
        const address = {
          ...addresses?.find((add) => add.id === params.addressId),
          store_location_id: response.store_location_id,
          pickup_store_location_id: params.store.store_location_id,
        }
        await updateAddress(address)
      }
      if (params.isRoutingHome) {
        router.push(routes.RETAILER_HOMEPAGE.url)
      }
      return queryClient.invalidateQueries({ queryKey: [UserQuery] })
    },
  })
}
