import { type Promotion } from '@/features/shop/services/Campaigns/types'
import { type PromoCodeDetails } from '@/features/shop/services/NewOrder/types'
import { formatExpiration } from '@/features/shared/utils/date'
import { staticColors } from '@/features/shared/theme/staticColors'

export const formatPromotionExpiration = (
  promotion: Promotion | PromoCodeDetails | undefined
) => {
  const { valid_through, valid_through_string } = promotion ?? {}
  if (valid_through_string && valid_through_string !== '') {
    return `Expires ${valid_through_string}`
  }
  if (valid_through && valid_through !== '') {
    return formatExpiration(valid_through, 'M/d')
  }
  return ''
}

export const formatAvailableStores = (numStores: number) => {
  if (numStores === 1) return 'At 1 store'
  if (numStores) return `At ${numStores} stores`
  return ''
}

const bgColorOrder = [
  staticColors.blue100,
  staticColors.green100,
  staticColors.orange100,
  staticColors.yellow100,
  staticColors.purple100,
] as const

export const getBackgroundColor = (index: number, offset: number = 0) => {
  return bgColorOrder[(index + offset) % bgColorOrder.length] ?? bgColorOrder[0]
}
