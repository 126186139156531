import { DocumentHead } from '@/features/entrypoint/components/Marketing/DocumentHead'
import { LogoOnlyHeader } from '@/features/shared/components/Headers/LogoOnlyHeader'
import { type CMSData } from '@/features/cms/components/CMS/types'
import { ErrorPage } from '@/features/shared/components/ErrorPage'
import { LayoutRenderer } from '@/features/cms/components/CMS/components/Layouts'

export const CMSPage = ({ cmsData }: { cmsData: CMSData }) => {
  if (!cmsData || typeof cmsData !== 'object' || 'error' in cmsData) {
    return <CMSPageError statusCode={cmsData.error?.status} />
  }

  return (
    <>
      <DocumentHead
        title={cmsData.seo_title}
        description={cmsData.seo_description}
        doNotIndex={cmsData.do_not_index}
        doNotFollow={cmsData.do_not_follow}
      />
      <LayoutRenderer layoutData={cmsData.content} />
    </>
  )
}

export const CMSPageError = ({ statusCode }: { statusCode: number }) => {
  return (
    <>
      <DocumentHead
        title={`${statusCode || 404} - Shipt`}
        description=""
        doNotIndex
        doNotFollow
      />
      <LogoOnlyHeader />
      <ErrorPage statusCode={statusCode} isCMSPage />
    </>
  )
}
