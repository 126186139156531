import { isProduction } from '@/features/shared/utils/environment'
import { z } from 'zod'

// we allow @gmail.com for non-prod to allow testing
// without actual .edu accounts
export const studentEmailRegex = isProduction
  ? /\.edu$/
  : /(\.edu|gmail.com|shipt.com)$/

export const studentEmailFormValidation = z
  .string()
  .email('Please enter a valid email address')
  .regex(studentEmailRegex, 'Please enter an .edu email address')

export const emailValidation = z
  .string()
  .min(1, 'Email is required')
  .email('Please enter a valid email address')
