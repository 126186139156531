import { type GetPageDataParams } from '@/features/shared/serverUtils/ssrHelpers/createServerSidePropsHandler'
import { type CMSDataQueryFunctionContext } from '@/features/cms/services/CMS/fetchers'
import { type User } from '@/features/account/services/User/types'
import { type GetServerSidePropsContext } from 'next'
import { getStoreParamsSSR } from '@/features/shared/utils/dataFetching/storeParams'
import { cmsQueryOptions } from '@/features/cms/services/CMS/queries'
import { getDefaultShoppingAddressWithUser } from '@/features/account/services/User/utils'
import { getGuestStoreParamsSSR } from '@/features/shared/utils/dataFetching/guestStoreParams'
import {
  type Address,
  type ValidAddress,
} from '@/features/account/services/Addresses/types'
import { fetchExperimentsSSR } from '@/features/shared/services/Experiments/queries'
import { type ShoppingCategory } from '@/features/shop/services/ShoppingCategories/types'
import {
  type CMSData,
  type CMSCatData,
  type CMSPageErrorData,
} from '@/features/cms/components/CMS/types'
import { fetchUserQueryAndOptOutRestrictions } from '@/features/shared/serverUtils/data-privacy/utils'
import { updateUserSelectedStoreSSR } from '@/features/shared/serverUtils/user'
import { AxiosError } from 'axios'
import { routes } from '@shared/constants/routes'

export type GetCMSPageDataParams = Pick<
  GetPageDataParams,
  'ssrContext' | 'queryClient' | 'authContext'
> & {
  overrideStoreSelection?: boolean
}

export const fetchCMSDataForSSR = async ({
  ssrContext,
  queryClient,
  authContext: { isAuthenticated },
  overrideStoreSelection,
}: GetCMSPageDataParams): Promise<{ props: { cmsData: CMSData } }> => {
  try {
    let user = await fetchUserQueryAndOptOutRestrictions({
      ssrContext,
      queryClient,
      isAuthenticated,
    })

    if (overrideStoreSelection && user?.id) {
      user = await updateUserSelectedStoreSSR(
        user,
        ssrContext,
        queryClient,
        Number(ssrContext.query['store_id'])
      )
    }
    const queryOptions = cmsQueryOptions(
      getCMSQueryKeyParamsFromSSRContext(ssrContext, user)
    )
    const [cmsData] = await Promise.all([
      queryClient.fetchQuery(queryOptions),
      fetchExperimentsSSR({ queryClient, ssrContext, user }),
    ])
    // since we don't mount any query observers for the CMS query whenever we are
    // returning the cmsData as a prop to the page, we will CMS query from the
    // query client cache to reduce the amount of page data returned to the client
    queryClient.removeQueries({ queryKey: queryOptions.queryKey })

    return { props: { cmsData } }
  } catch (e) {
    if (e instanceof Error) {
      e.stack = undefined
      e.cause = undefined
      if (e instanceof AxiosError) {
        e.config = undefined
      }
    }
    // cast as CMSPageErrorData to match the expected type used in CMS components
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return { props: { cmsData: { error: e } as CMSPageErrorData } }
  }
}

export const getCMSQueryKeyParamsFromSSRContext = (
  ssrContext: GetServerSidePropsContext,
  user: User | undefined
) => {
  // We don't want the slug to contain the beginning '/'
  // and we don't want to include any query params in the slug
  const cleanPath = ssrContext.resolvedUrl.substring(1).split('?')[0] || ''
  const slug = cleanPath === '' ? 'home' : cleanPath

  const storeParams = getStoreParamsSSR(ssrContext, user)

  const addressParams = user
    ? getAddressParamsForCMS(user)
    : getGuestAddressParamsForCMSSSR(ssrContext)

  return { slug, storeParams, addressParams }
}

export const INVALID_STORE_SUPPLIED_MESSAGE =
  'Invalid store supplied for user in CMS page querystring'

export const createFetchCMSDataConfig = ({
  context,
  isOnServer,
  enableDeferredHydration,
  contentAlias,
}: {
  context: CMSDataQueryFunctionContext
  isOnServer: boolean
  enableDeferredHydration: boolean
  contentAlias?: string
}) => {
  const [, { slug, storeParams, addressParams }] = context.queryKey

  const data = {
    slug,
    ...storeParams,
    ...addressParams,
    enable_deferred_hydration: enableDeferredHydration,
    address_id: addressParams?.address_id?.toString(),
  }
  const ssrContext = context.meta?.ssrContext

  // Capture the visitor's IP address via the x-forwarded-for header
  // Note: the x-forwarded-for header will not be set on localhost
  const forwardedFor = ssrContext?.req.headers?.['x-forwarded-for']
  const ip = Array.isArray(forwardedFor)
    ? forwardedFor[0]
    : forwardedFor?.split(',')[0] ?? ''

  const userAgent = ssrContext?.req.headers['user-agent'] ?? ''
  const cmsUrl = contentAlias ? `content-alias/${contentAlias}` : 'page-content'

  return {
    // If request is coming from server during SSR, we should send the x-real-ip header and x-real-user-agent
    ...(isOnServer && {
      headers: {
        'x-real-ip': ip,
        'x-real-user-agent': userAgent,
      },
    }),
    url: `/cms/v1/${cmsUrl}`,
    data,
    onErrorLog: { ...data },
  }
}

export const getAddressParamsForCMS = (user: User) => {
  const defaultAddress = getDefaultShoppingAddressWithUser(user)
  return formatCMSAddress(defaultAddress)
}

export const getGuestAddressParamsForCMS = (guestAddress?: ValidAddress) => {
  return formatCMSAddress(guestAddress)
}

export const getGuestAddressParamsForCMSSSR = (
  ssrContext: GetServerSidePropsContext
) => {
  const { guestAddress } = getGuestStoreParamsSSR(ssrContext)
  return formatCMSAddress(guestAddress)
}

const formatCMSAddress = (address?: Address | ValidAddress) => {
  if (!address) return undefined

  const addressId = ('id' in address && address.id) || 0

  return {
    address_id: addressId,
    address_city: address?.city,
    address_state: address.state,
    address_line_1: address?.street1,
    address_line_2: address?.street2,
    zip: address?.zip_code,
  }
}

const mapCategoryChildren = (children?: Maybe<CMSCatData[]>) =>
  children?.map(toCategory)

export const toCategory = (category: CMSCatData): ShoppingCategory => {
  const {
    data: {
      heading,
      image,
      children,
      link: { url, type },
    },
    metadata,
  } = category

  return {
    ...metadata.read_operation_info.source_object?.object,
    name: heading,
    image_url: image?.src,
    relativeLink: type === 'relative' ? url : undefined,
    children: mapCategoryChildren(children),
  }
}

export const getCategoryUrl = (category: ShoppingCategory) => {
  const { id = 0, relativeLink } = category ?? {}
  return relativeLink ?? `${routes.SEARCH.url}?category_id=${id}`
}
