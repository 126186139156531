import { keyframes, css } from 'styled-components'

// Keyframes
export const fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

// Animations

export const fadeIn = css`
  ${fade} 0.6s ease-in-out
`

export const fadeIn2 = css`
  ${fade} 1s ease-in-out;
`

const visibilityTransition = `opacity 0.2s ease-in-out`

export const hide = `
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, ${visibilityTransition};
`

export const scaleToShowAndHide = keyframes`
  0% { transform: scale(0); opacity: 0; }
  40% { transform: scale(1.5); opacity: 1; }
  80% { transform: scale(1); opacity: 1; }
  100% { transform: scale(0); opacity: 0; }
`

export const scaleToHide = keyframes`
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(0.5); opacity: 0.5; }
  100% { transform: scale(0); opacity: 0; }
`

export const scaleToShow = keyframes`
  0% { transform: scale(0); opacity: 0; }
  100% { transform: scale(1); opacity: 1; }
`

const pingKeyframe = (color: string, size: string) => keyframes`
0% {
  box-shadow: 0 0 0 0 ${color}80;
}
50%, 100% {
  box-shadow: 0 0 0 ${size} ${color}00;
}
`

export const pingAnimation = (
  color: string,
  pingSize: string,
  circleSize: string
) => css`
  &:before {
    content: '';
    position: absolute;
    width: ${circleSize};
    height: ${circleSize};
    border-radius: 50%;
    animation-name: ${pingKeyframe(color, pingSize)};
    animation-duration: 1500ms;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    will-change: box-shadow;
  }
`
