import { dialogAtom } from '@/features/shared/state/Dialog/atom'
import {
  type DialogSessionItem,
  type DynamicDialogComponent,
  type DynamicDialogData,
} from '@/features/shared/state/Dialog/types'
import { useSetAtom } from 'jotai'
import { useCallback } from 'react'

import { getJotaiStore } from '@/features/shared/state'
import { useDialogContext } from '@/features/shared/components/DialogContainer'
import { isProduction } from '@/features/shared/utils/environment'
import { useStoreContext } from '@/features/shared/context/GlobalStoreContext'
import { logError, logWarn } from '@/features/shared/utils/logger'
import { isObject } from '@/features/shared/utils/isObject'
import { useCMSContext } from '@/features/cms/components/CMS/CMSContext'

export const useHandleDialog = () => {
  const dialogContext = useDialogContext()
  const setDialog = useSetAtom(dialogAtom)
  const globalStoreParams = useStoreContext()
  const cmsData = useCMSContext()

  const openDialog = useCallback(
    async <T extends DynamicDialogComponent>(
      component: T,
      ...dialogData: DynamicDialogData<T>
    ) => {
      const data = dialogData[0]
      const handleOpenDialog = () =>
        dialogContext.setDialogState({
          component,
          props: { ...data, globalStoreParams, cmsData },
        })

      if (!isObject(component) || !component.render) {
        logError('openDialog must be called with a dynamic imported dialog')
        if (!isProduction) throw new Error('invalid dialog component')
        return handleOpenDialog()
      }

      if (
        typeof component.render === 'function' &&
        typeof component.render.preload === 'function'
      ) {
        try {
          await component.render.preload()
        } catch (e) {
          logWarn('failed to preload dialog', { cause: e })
        }
      } else {
        logWarn('no preload function found for dialog')
      }
      handleOpenDialog()
    },
    [globalStoreParams, dialogContext, cmsData]
  )

  const setDialogSessionItem = useCallback(
    (key: string, data: DialogSessionItem) => {
      setDialog((currentDialog) => ({
        ...currentDialog,
        dialogSessionStorage: {
          ...currentDialog.dialogSessionStorage,
          [key]: data,
        },
      }))
    },
    [setDialog]
  )

  const getDialogSessionItem = (key: string) => {
    const jotaiStore = getJotaiStore()
    const dialog = jotaiStore.get(dialogAtom)
    return dialog.dialogSessionStorage?.[key]
  }

  const clearDialogSessionItem = useCallback(
    (key: string) =>
      setDialog((currentDialog) => ({
        ...currentDialog,
        dialogSessionStorage: {
          ...currentDialog.dialogSessionStorage,
          [key]: undefined,
        },
      })),
    [setDialog]
  )

  return {
    openDialog,
    closeDialog: dialogContext.closeDialog,
    setDialogSessionItem,
    getDialogSessionItem,
    clearDialogSessionItem,
  } as const
}
