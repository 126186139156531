import { type RouteName } from '@shared/constants/routes'
import { useContext, useMemo } from 'react'
import { IsPageRoutesDataContext } from '@/features/shared/context/RouteDataContext'

export const useIsPage = () => {
  const context = useContext(IsPageRoutesDataContext)
  if (context === null) {
    throw new Error(
      'useIsPage must only be used within a IsPageRoutesDataContextProvider'
    )
  }
  return context
}

export const useIsOneOfPages = (routeNamesArray: RouteName[]) => {
  const isPageObject = useIsPage()

  return useMemo(() => {
    return routeNamesArray.some((routeName) => isPageObject[routeName])
  }, [isPageObject, routeNamesArray])
}
