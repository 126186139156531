import { getKey, type KeyCode } from '@/features/shared/constants/keyCodes'

export const isKeyCode = (
  e: React.KeyboardEvent | KeyboardEvent,
  key: KeyCode
) => getKey(e) === key

const isNumberKeyEvent = (e: React.KeyboardEvent) => /^[0-9]$/i.test(getKey(e))

const isLowercaseLetterKeyEvent = (e: React.KeyboardEvent) =>
  /^[a-z]$/.test(getKey(e))

const isUppercaseLetterKeyEvent = (e: React.KeyboardEvent) =>
  /^[A-Z]$/.test(getKey(e))

const isLetterKeyEvent = (e: React.KeyboardEvent) =>
  isLowercaseLetterKeyEvent(e) || isUppercaseLetterKeyEvent(e)

const isSymbolKeyEvent = (e: React.KeyboardEvent) =>
  // escaping symbols is required to match the character exactly
  // eslint-disable-next-line no-useless-escape
  /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/.test(getKey(e))

export const isStandardCharacterKeyEvent = (e: React.KeyboardEvent) =>
  isNumberKeyEvent(e) || isLetterKeyEvent(e) || isSymbolKeyEvent(e)

export const isUpDownKeyEvent = (e: React.KeyboardEvent) =>
  isKeyCode(e, 'ArrowUp') || isKeyCode(e, 'ArrowDown')

export const isButtonActionEvent = (e: React.KeyboardEvent) =>
  isKeyCode(e, 'Enter') || isKeyCode(e, ' ')
