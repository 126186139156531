import styled, { css } from 'styled-components'
import { Body as BaseBody } from '@shipt/design-system-typography'
import { getAnonymousId } from '@shipt/analytics-member-web'
import { useUserId } from '@/features/account/services/User/hooks'
import { getUniversalId } from '@/features/shared/services/Experiments/utils'
import { useCopyToClipboard } from 'react-use'

export function ExperimentWidget() {
  const universalID = getUniversalId()
  const anonymousID: string = getAnonymousId()
  const userID = useUserId() || 0
  const [, copyToClipboard] = useCopyToClipboard()

  const handleCopy = (content?: string) => {
    if (!content) return
    copyToClipboard(content)
  }

  return (
    <WidgetContainer>
      <tbody>
        <tr>
          <Body
            as="td"
            title="Guest User/Device ID, can also be used in experiments"
          >
            Anonymous ID:
          </Body>
          <Body as="td">
            <CopyIDButton onClick={() => copyToClipboard(anonymousID)}>
              {anonymousID}
            </CopyIDButton>
          </Body>
        </tr>
        <tr>
          <Body as="td" title="Modern User ID, used in Experiments">
            Universal ID:
          </Body>
          <Body as="td">
            <CopyIDButton
              disabled={!universalID}
              onClick={() => handleCopy(universalID)}
            >
              {universalID ?? 'N/A'}
            </CopyIDButton>
          </Body>
        </tr>
        <tr>
          <Body as="td" title="Legacy User ID, used in Feature Flags">
            User ID:
          </Body>
          <Body as="td">
            <CopyIDButton
              disabled={!userID}
              onClick={() =>
                handleCopy(userID === 0 ? undefined : String(userID))
              }
            >
              {userID !== 0 ? userID : 'Guest'}
            </CopyIDButton>
          </Body>
        </tr>
      </tbody>
    </WidgetContainer>
  )
}

const WidgetContainer = styled.table`
  position: fixed;
  z-index: 1000000;
  top: 4rem;
  left: 1rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.gray100};

  & td {
    padding: 0.25rem;
  }
`

const Body = styled(BaseBody)`
  text-decoration-line: underline;
  text-decoration-style: dashed;
  cursor: help;
`
const buttonDefaultStyles = css`
  background: transparent;
  border: none;
  color: inherit;
`
const CopyIDButton = styled.button<{ disabled?: boolean }>(
  ({ theme, disabled }) => {
    if (disabled) return buttonDefaultStyles

    return css`
      ${buttonDefaultStyles}
      cursor: pointer;
      border-radius: 0.25rem;

      &:hover {
        background-color: ${theme.gray300};
      }

      &:active {
        background-color: ${theme.gray400};
      }
    `
  }
)
