import styled from 'styled-components'
import { type HeaderData } from '@/features/cms/components/CMS/types'
import { LogoOnlyHeader } from '@/features/shared/components/Headers/LogoOnlyHeader'
import { UngatedHeaderContent } from '@/features/shared/components/Headers/UngatedHeader/UngatedHeaderContent'
import { getCMSWrapperProps } from '@/features/cms/components/CMS/utils/getCMSWrapperProps'

export const CMSHeader = ({
  content_type_id,
  id,
  data: { header_type, is_sticky },
}: HeaderData) => {
  const showLogoOnly = Boolean(
    header_type === 'logo_only' || header_type === 'transparent'
  )
  return (
    <Wrapper
      header_type={header_type}
      is_sticky={is_sticky}
      {...getCMSWrapperProps({ content_type_id, id })}
    >
      {showLogoOnly ? <LogoOnlyHeader /> : <UngatedHeaderContent />}
    </Wrapper>
  )
}

const Wrapper = styled.header<{
  header_type: HeaderData['data']['header_type']
  is_sticky: boolean
}>`
  background-color: ${({ header_type }) =>
    header_type === 'transparent' ? 'transparent' : 'white'};
  margin-bottom: ${({ header_type }) =>
    header_type === 'transparent' && '-5rem'};
  box-shadow: ${({ header_type }) =>
    header_type === 'transparent'
      ? 'none'
      : '0 .125rem .625rem 0 rgba(0, 0, 0, 0.1)'};
  position: ${({ header_type }) => header_type === 'logo_only' && 'relative'};
  ${({ is_sticky }) =>
    is_sticky &&
    `
    position: sticky;
    top: 0;
    z-index: 999;
  `};
`
