import styled from 'styled-components'
import { screenSizes } from '@shipt/design-system-themes'
import { BaseText } from '@/features/shared/styles/typography/base.styled'

const ClaremontTitleBase = styled(BaseText)`
  font-family: 'ClaremontMedium', georgia, times, serif;
  font-weight: normal;
  letter-spacing: 0.0125rem;
  margin: 0;
`
/**
 * @deprecated Use { Display } from '@shipt/design-system-typography' instead
 */
export const DisplayTitle1 = styled(ClaremontTitleBase)`
  line-height: 5.125rem;
  font-size: 4.5rem;
  letter-spacing: -0.0625rem;
`
/**
 * @deprecated Use { Display } from '@shipt/design-system-typography' instead
 */
export const DisplayTitle3 = styled(ClaremontTitleBase)`
  line-height: 3.5rem;
  font-size: 3rem;
  letter-spacing: 0rem;
  ${({ shouldScaleUp = false }) =>
    shouldScaleUp &&
    ` 
    @media ${screenSizes.smDesktop} { 
      line-height: 4.5rem;
      font-size: 3.75rem;
      letter-spacing: -0.0625rem;      
    }
    @media ${screenSizes.lgDesktop} { 
      line-height: 5.125rem;
      font-size: 4.5rem;
    }
    `}
`
/**
 * @deprecated Use { Display } from '@shipt/design-system-typography' instead
 */
export const DisplayTitle4 = styled(ClaremontTitleBase)`
  line-height: 3rem;
  font-size: 2.5rem;

  ${({ shouldScaleUp = false }) =>
    shouldScaleUp &&
    `
    @media ${screenSizes.tablet} {
      line-height: 3.5rem;
      font-size: 3rem;
    }
    @media ${screenSizes.smDesktop} {
      line-height: 4.25rem;
      font-size: 3.75rem;
      letter-spacing: -0.0625rem;      
    }
    @media ${screenSizes.lgDesktop} {
      line-height: 5.125rem;
      font-size: 4.5rem;
      letter-spacing: -0.0625rem;      
    }
  `};
`
/**
 * @deprecated Use { Display } from '@shipt/design-system-typography' instead
 */
export const OldDisplayTitle5 = styled(ClaremontTitleBase)`
  line-height: 3rem;
  font-size: 2.625rem;
`
// HeadingTitles in Web DLS (https://www.figma.com/file/SWPhdIsgPAHmghzvdC2Yzs/Member-DLS-2021?node-id=172%3A311)
/**
 * @deprecated Use { Display } from '@shipt/design-system-typography' instead
 */
export const TitleClaremont0 = styled(ClaremontTitleBase)`
  line-height: 2.5rem;
  font-size: 2rem;

  ${({ shouldScaleUp = false }) =>
    shouldScaleUp &&
    `
    @media ${screenSizes.tablet} {
      line-height: 3rem;
      font-size: 2.625rem;
    }
  `};
`
/**
 * @deprecated Use { Display } from '@shipt/design-system-typography' instead
 */
export const TitleClaremont1 = styled(ClaremontTitleBase)`
  line-height: 2rem;
  font-size: 1.5rem;

  ${({ shouldScaleUp = false }) =>
    shouldScaleUp &&
    `
    @media ${screenSizes.tablet} {
      line-height: 2.5rem;
      font-size: 2rem;
    }
  `};
`
/**
 * @deprecated Use { Display } from '@shipt/design-system-typography' instead
 */
export const TitleClaremont2 = styled(ClaremontTitleBase)`
  line-height: 1.5rem;
  font-size: 1.1875rem;

  ${({ shouldScaleUp = false }) =>
    shouldScaleUp &&
    `
    @media ${screenSizes.tablet} {
      line-height: 2rem;
      font-size: 1.5rem;
    }
  `};
`
