import {
  type NextApiRequest,
  type GetServerSidePropsContext,
  type Redirect,
} from 'next'
import safeStringify from 'safe-stable-stringify'
import { cookieName as legacyAuthCookieName } from '@shared/constants/auth'
import { GuestDataStore } from '@/features/shared/constants/global'
import { routes, type RouteName, getRouteData } from '@shared/constants/routes'
import { isJson } from '@/features/shared/utils/isJson'
import { isValidZip } from '@/features/shared/utils/validator'
import { isValidGuestStore } from '@/features/account/services/User/validations'
import {
  getSearchQueryString,
  getUrlWithNextParam,
} from '@/features/shared/utils/url'
import {
  type PageRouteData,
  type ClientSafeSsrContext,
  type ServerPageData,
  type ServerSideProps,
} from '@/features/shared/utils/setPageProps'
import { getErrorStatus } from '@/features/shared/utils/dataFetching/utils'
import { logError } from '@/features/shared/utils/logger'
import { shouldUseNewAuth } from '@/features/authentication/utils/authentication/utils'
import {
  getSession,
  updateSession,
  getIsOnboardedWithUser,
} from '@/features/shared/serverUtils/auth/helpers'
import { type AuthContext } from '@/features/shared/serverUtils/ssrHelpers/createServerSidePropsHandler'
import { authRoutes } from '@/features/shared/serverUtils/auth/constants'
import { getQueryClient } from '@/features/shared/utils/dataFetching/reactQuery/SegwayQueryClient'
import { getSignupPlansQueryKey } from '@/features/shop/services/DeliveryPlans/queries'
import { fetchSignupPlans } from '@/features/shop/services/DeliveryPlans/fetcher'
import { getHasSignupPromotionPlans } from '@/features/authentication/services/SignupPromotions/utils'
import { GuestHasCartQuery } from '@/features/shared/constants/ungatedParams'
import { getCartConversionRoute } from '@/features/shop/services/Cart/utils'
import { type ParsedUrlQuery } from 'querystring'
import { useQueryCouponCode } from '@/features/authentication/services/SignupPromotions/queries'
import { type DehydratedState } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { type UserProfile } from '@auth0/nextjs-auth0/client'

export async function getAuthStatus(ssrContext: GetServerSidePropsContext) {
  const { req, res } = ssrContext
  const { cookies } = req
  if (!shouldUseNewAuth(ssrContext)) {
    const { [legacyAuthCookieName]: authCookieValue } = cookies ?? {}
    return { isAuthenticated: Boolean(authCookieValue) }
  }
  try {
    const user = (await getSession(req, res))?.user
    return {
      isAuthenticated: Boolean(user),
      isOnboarded: getIsOnboardedWithUser(user),
      user,
    }
  } catch {
    return { isAuthenticated: false, isOnboarded: false, user: undefined }
  }
}

export function getGuestStatus(ssrContext: GetServerSidePropsContext) {
  const { req } = ssrContext
  const { cookies } = req
  const {
    [GuestDataStore.ADDRESS]: guestAddressCookie,
    [GuestDataStore.STORE]: guestStoreCookie,
  } = cookies ?? {}
  const isGuestAddressValid =
    isJson(guestAddressCookie) &&
    isValidZip(JSON.parse(guestAddressCookie)?.zip_code ?? '')
  const isGuestStoreValid =
    isJson(guestStoreCookie) && isValidGuestStore(JSON.parse(guestStoreCookie))
  return {
    isGuestAddressValid,
    isGuestStoreValid,
    isGuestUser: isGuestAddressValid,
  }
}

export const getLoginRedirectUrl = ({
  resolvedUrl,
  newAuthEnabled,
}: {
  resolvedUrl?: string
  newAuthEnabled: boolean
}) => {
  if (!newAuthEnabled) {
    return getUrlWithNextParam(routes.LOGIN.url, resolvedUrl)
  }
  return getUrlWithNextParam(authRoutes.LOGIN, resolvedUrl, {
    queryParam: 'returnTo',
  })
}

export const getContextualRedirect = async ({
  currentResolvedUrl,
  routeData,
  authContext,
  newAuthEnabled,
  ssrContext,
}: {
  currentResolvedUrl: string
  routeData: PageRouteData
  authContext: AuthContext
  newAuthEnabled: boolean
  ssrContext: GetServerSidePropsContext
}): Promise<{ redirect: Redirect } | undefined> => {
  const { isAuthenticated, isOnboarded, isGuestUser, isGuestStoreValid } =
    authContext
  // Redirect guest user to global homepage when page does not allow guest access
  if (routeData?.noGuestAccess && !isAuthenticated && isGuestUser) {
    const stringifiedParams = getSearchQueryString(ssrContext.query)

    return getPageRedirect(`${routes.GLOBAL_HOMEPAGE.url}${stringifiedParams}`)
  }

  // Authenticated page redirects
  if (routeData?.authentication) {
    // Redirect to login if no auth cookie or address cookie is present
    if (!isAuthenticated && !isGuestUser) {
      return getPageRedirect(
        getLoginRedirectUrl({ resolvedUrl: currentResolvedUrl, newAuthEnabled })
      )
    }

    // Redirect to welcome flow for authenticated users who have not onboarded
    if (newAuthEnabled && isAuthenticated && !isOnboarded) {
      await updateSession(ssrContext.req, ssrContext.res, {
        destination_url: currentResolvedUrl,
      })
      return getPageRedirect(routes.WELCOME.url)
    }
    // Redirect to the global homepage if store cookie is not present for guest user
    if (
      routeData?.storeRequired &&
      !isAuthenticated &&
      isGuestUser &&
      !isGuestStoreValid
    ) {
      return getPageRedirect(routes.GLOBAL_HOMEPAGE.url)
    }
  }
}

export const getProcessedPageProps = <TProps>({
  user,
  routeData,
  ssrContext,
  pageData,
}: {
  user: UserProfile | undefined
  routeData: PageRouteData
  ssrContext: GetServerSidePropsContext
  pageData?: ServerPageData<TProps>
}) => {
  const { reactQueryState, notFound, redirect } = pageData ?? {}
  if (notFound) return { notFound: true }
  if (redirect) return { redirect }

  const serializedReactQueryState = safeStringify(reactQueryState ?? {})
  const clientSafeSsrContext: ClientSafeSsrContext = {
    req: {
      cookies: ssrContext.req.cookies,
    },
  }
  const parsedProps: TProps = JSON.parse(safeStringify(pageData?.props ?? {}))

  const props: TProps & Partial<ServerSideProps> = {
    ...parsedProps,
    ssrContext: clientSafeSsrContext,
    routeData,
    // @ts-expect-error extending req object in middleware object via 'setDisableSegment' in 'server/middleware.ts'
    disableSegment: !!ssrContext.req.disableSegment,
    reactQueryState: JSON.parse(serializedReactQueryState),
    ...(user && { user: JSON.parse(safeStringify(user)) }),
  } satisfies ServerSideProps

  return { props }
}

export const handleSSRError = <TProps>(
  e: unknown,
  ssrContext: GetServerSidePropsContext,
  {
    user,
    isGuestUser,
    isAuthenticated,
    routeName,
    resolvedUrl,
    newAuthEnabled,
    reactQueryState,
  }: {
    user: UserProfile | undefined
    isGuestUser: boolean
    isAuthenticated: boolean
    routeName: RouteName
    resolvedUrl: string
    newAuthEnabled: boolean
    reactQueryState: DehydratedState | undefined
  }
) => {
  const clientSafeSsrContext: ClientSafeSsrContext = {
    req: { cookies: ssrContext.req.cookies },
  }
  const serializedReactQueryState = safeStringify(reactQueryState ?? {})

  const routeData = getRouteData(routeName)
  const statusCode = getErrorStatus(e)
  const isGuestForbidden = statusCode === 403 && isGuestUser

  // We have to manually set the status code since we are catching & handling errors
  if (statusCode === 404 || isGuestForbidden) {
    // If the code is 404, use that code and allow it to surface in server metrics

    // We would get a 403 for a guest user in case we decide to stop guest traffic from cloudfare level
    // in this case we would want to persist the statusCode for the redirection to happen below.
    ssrContext.res.statusCode = statusCode
  } else if (statusCode !== 401) {
    // In all other cases (except 401), set a generic 500 code to indicate in server metrics that an unexpected error occurred
    // A 401 causes the "redirect" key to be applied in the return, which performs a server redirect, so we shouldn't set a status code here
    ssrContext.res.statusCode = 500
  }

  // Don't log 401 errors if the user was not authenticated; this is expected
  if (statusCode === 401 && !isAuthenticated) {
    // Is this still possible, since contextualRedirect is computed before api calls?
  } else {
    logError(e, {
      pageData: {
        name: routeName,
        url: resolvedUrl,
        statusCode: ssrContext.res.statusCode,
      },
    })
  }

  return {
    ...((statusCode === 401 || isGuestForbidden) &&
      getPageRedirect(getLoginRedirectUrl({ resolvedUrl, newAuthEnabled }))),
    // cast to include TProps in the type of the return value. this is fine since TProps
    // won't be consumed whenever there is a SSR error due to our error handling in _app.tsx
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    props: {
      // @ts-expect-error extending req object in middleware object via 'setDisableSegment' in 'server/middleware.ts'
      disableSegment: !!ssrContext.req.disableSegment,
      ssrContext: clientSafeSsrContext,
      routeData,
      statusCode,
      reactQueryState: JSON.parse(serializedReactQueryState),
      ...(user && { user: JSON.parse(safeStringify(user)) }),
    } satisfies ServerSideProps as TProps & Partial<ServerSideProps>,
  }
}

export const validateCouponCode = async ({
  ssrContext,
  queryParams,
}:
  | { ssrContext: GetServerSidePropsContext; queryParams?: never }
  | { ssrContext?: never; queryParams: ParsedUrlQuery }): Promise<boolean> => {
  const { gift_code } = ssrContext?.query ?? queryParams ?? {}
  const queryClient = getQueryClient(ssrContext)

  if (!gift_code) return false

  try {
    const response = await queryClient.fetchQuery({
      queryKey: useQueryCouponCode.getKey({ gift_code }),
      queryFn: useQueryCouponCode.fetcher,
    })
    return Boolean(response.code)
    // We want to return true for a falsy gift_code so that we can take them to correct page to show errors.
  } catch {
    return true
  }
}

export const checkHasValidPromotion = async ({
  email,
  ssrContext,
  queryParams,
}: { email: string } & (
  | { ssrContext: GetServerSidePropsContext; queryParams?: never }
  | { ssrContext?: never; queryParams: ParsedUrlQuery }
)): Promise<boolean> => {
  const queryClient = getQueryClient(ssrContext)
  const {
    param = '',
    referrerUrl = '',
    zip = '',
    zipcode = '',
  } = ssrContext?.query ?? queryParams ?? {}

  const params = {
    email,
    zip_code: String(zip || zipcode),
    param: String(param),
    referring_url: String(referrerUrl),
  }

  try {
    const signupPlans = await queryClient.fetchQuery({
      queryKey: getSignupPlansQueryKey(params),
      queryFn: fetchSignupPlans,
    })
    return getHasSignupPromotionPlans(signupPlans)
  } catch (e) {
    if (!isAxiosError(e)) {
      logError(e)
    }
    return false
  }
}

export const checkHasQueryParamForRouting = ({
  ssrContext,
  req,
}:
  | { ssrContext: GetServerSidePropsContext; req?: never }
  | { req: NextApiRequest; ssrContext?: never }) => {
  const queryObject = ssrContext?.query ?? req?.query ?? {}
  const queryKeys = Object.keys(queryObject)
  const chooseMembershipQueryParams = [
    'gift_code',
    'param',
    'referrerUrl',
    'zip',
    'redeem',
    // adding this here even if this is not a signupPromotion queryParam since we need this queryParam on next screen for guest conversion
    'guest',
  ]
  return queryKeys.some((key) => chooseMembershipQueryParams.includes(key))
}

export const handleSignupWithQueryParam = async (
  ssrContext: GetServerSidePropsContext
) => {
  if (ssrContext.query.guest === GuestHasCartQuery) {
    const cartConversionRoute = getCartConversionRoute(ssrContext.query)
    return await updateSession(ssrContext.req, ssrContext.res, {
      destination_url: cartConversionRoute,
    })
  }
  const session = await getSession(ssrContext.req, ssrContext.res)
  const email = session?.user?.email ?? ''
  const promoExists = await checkHasValidPromotion({ email, ssrContext })
  const hasCouponCode = await validateCouponCode({ ssrContext })

  if (promoExists || hasCouponCode) {
    /*
      promoExists boolean is going to be true even if the email domain promo is present 
      or a regular signupPromotion is present. And hence we will first check if query params exists,
      if they do then we append email to the end which can be empty or just have email in param which will have value.
    */
    const stringifiedParams = getSearchQueryString({
      ...ssrContext.query,
      email,
    })
    return await updateSession(ssrContext.req, ssrContext.res, {
      destination_url: `${routes.CHOOSE_MEMBERSHIP.url}${stringifiedParams}`,
    })
  }
}

/**
 * Capture the originating client IP address via the [x-forwarded-for](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/X-Forwarded-For) header.
 */
export const getForwardedForIp = (
  ssrContext: GetServerSidePropsContext | undefined
) => {
  // Note: the x-forwarded-for header will not be set on localhost
  const forwardedFor = ssrContext?.req.headers?.['x-forwarded-for']
  return Array.isArray(forwardedFor)
    ? forwardedFor.at(0)
    : forwardedFor?.split(',').at(0)
}

type RedirectOptions =
  | { statusCode: 301 | 302 | 303 | 307 | 308; permanent?: never }
  | { permanent: boolean; statusCode?: never }

export const getPageRedirect = (
  destination: string,
  options: RedirectOptions = { permanent: false }
): { redirect: Redirect } => ({ redirect: { destination, ...options } })
