import { type PricingSubsection } from '@/features/cms/components/CMS/types'
import { useCMSTrackingEvents } from '@/features/cms/components/CMS/CMSContext'
import { PlanCardV2 } from '@/features/account/components/Membership/DeliveryPlans/PlanCardV2'

type Props = PricingSubsection & { index: number; isCarousel?: boolean }

export const MembershipPricingCard = ({
  index,
  data: {
    price,
    sale_price,
    cta_text,
    subheading,
    membership_type,
    primary_subsection_badge,
  },
  isCarousel,
}: Props) => {
  const { trackCMSElementClicked } = useCMSTrackingEvents()

  const getButtonLinkAriaLabel = () => {
    const membershipClassification =
      membership_type.includes('Monthly') || membership_type.includes('Annual')
        ? `${membership_type} membership`
        : membership_type

    return `${membershipClassification}, ${
      sale_price
        ? `sale price is $${sale_price}, regular price is $${price}`
        : `$${price}`
    }, ${subheading} ${cta_text?.text}.`
  }

  const planName =
    membership_type === 'One-time delivery' ? 'Individual' : membership_type

  const getPlanInterval = () => {
    switch (membership_type) {
      case 'Student':
      case 'Monthly':
        return 'month'
      case 'One-time delivery':
        return 'order'
      default:
        return 'year'
    }
  }

  return (
    <PlanCardV2
      callout={primary_subsection_badge ?? ''}
      isBestValue={Boolean(primary_subsection_badge && index === 0)}
      description={subheading ?? ''}
      isDiscounted={Boolean(sale_price)}
      originalPrice={price}
      price={sale_price || price}
      name={planName}
      buttonCta={cta_text.text}
      interval={getPlanInterval()}
      onButtonClick={() =>
        trackCMSElementClicked({
          type: 'link',
          next_location: cta_text?.url,
          content: cta_text?.text,
          message_goal: `membership_acquisition_${membership_type.toLowerCase()}_default`,
        })
      }
      href={cta_text.url}
      buttonAriaLabel={getButtonLinkAriaLabel()}
      isCarousel={isCarousel}
    />
  )
}
