import {
  apiDelete,
  apiPatch,
  apiPost,
} from '@/features/shared/utils/dataFetching'
import {
  type QueryClient,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import {
  type ValidAddress,
  type Address,
  type BaseAddress,
  type DeleteAddressResponse,
  type AddressMutationBffResponse,
} from '@/features/account/services/Addresses/types'
import { addressQueryOptions } from '@/features/account/services/Addresses/queries'
import { useCustomerAddressesQuery } from '@/features/account/services/Addresses/graphql/customerAddresses.generated'
import { userIsDoNotShareSellTA } from '@/features/account/services/DataPrivacy/utils'
import { useMutationHandleOptOutRestrictions } from '@/features/account/services/DataPrivacy/mutations'
import { useFeatureFlags } from '@/features/shared/services/FeatureFlags/useFeatureFlags'
import { track } from '@/features/shared/analytics/trackingQueue'
import { AnalyticsEvent } from '@shipt/analytics-member-web'
import { setClientOptOutState } from '@/features/account/services/DataPrivacy/fetchers'

const useOnSuccessAddress = (queryClient: QueryClient) => {
  const invalidateAddressQuery = () =>
    queryClient.invalidateQueries({
      queryKey: [useCustomerAddressesQuery.rootKey],
    })
  const { mutateAsync: handleOptOutRestrictions } =
    useMutationHandleOptOutRestrictions(invalidateAddressQuery)

  return (address: Address) => {
    if (userIsDoNotShareSellTA()) {
      invalidateAddressQuery()
    } else {
      return handleOptOutRestrictions({
        state: address.state,
        zip_code: address.zip_code,
      })
    }
  }
}

export const useMutationAddAddressLegacy = (location: string) => {
  const queryClient = useQueryClient()
  const onSuccessAddress = useOnSuccessAddress(queryClient)

  return useMutation({
    mutationFn: (address: ValidAddress) =>
      apiPost<Address>({
        config: {
          url: 'api/v1/customer_addresses.json',
          data: address,
        },
        options: {
          ignoredMessages: [
            /address not in service area/i,
            'delivery instructions must be 400 characters or less',
            'Please enter a valid address',
            "too many addresses, you're allowed a maximum of 100",
          ],
        },
        fetcherName: 'useMutationAddAddress',
      }),
    onSuccess: (address) => {
      queryClient.setQueryData(addressQueryOptions.queryKey, (addresses) => [
        address,
        ...(addresses || []),
      ])
      onSuccessAddress(address)
    },
    onSettled: onSettledAddressHandler({
      isBff: false,
      location,
      mutation: 'ADD',
    }),
  })
}

export const useMutationUpdateAddressLegacy = <
  TAddress extends Partial<BaseAddress>
>(
  location: string
) => {
  const queryClient = useQueryClient()
  const onSuccessAddress = useOnSuccessAddress(queryClient)

  return useMutation({
    mutationFn: (address: TAddress) =>
      apiPatch<Address>({
        config: {
          url: `api/v1/customer_addresses/${address.id}`,
          data: address,
        },
        options: {
          ignoredMessages: [
            /address not in service area/i,
            'delivery instructions must be 400 characters or less',
          ],
        },
        fetcherName: 'useMutationUpdateAddress',
      }),
    onSuccess: (updatedAddress) => {
      queryClient.setQueryData(addressQueryOptions.queryKey, (addresses) => {
        return addresses?.map((address) => {
          if (address.id === updatedAddress.id) {
            return updatedAddress
          }
          return address
        })
      })
      onSuccessAddress(updatedAddress)
    },
    onSettled: onSettledAddressHandler({
      isBff: false,
      location,
      mutation: 'UPDATE',
    }),
  })
}

const onOptOutSuccessHandler =
  (queryClient: QueryClient) =>
  async ({ user_opt_out }: { user_opt_out: boolean }) => {
    // BACKEND took care of opting out the user, FE needs to invalidate queries,  set window property and call opt-out endpoint to set cookies
    //
    if (user_opt_out) {
      await setClientOptOutState()
      return queryClient.invalidateQueries()
    }

    // no user opt out, so just invalidate existing address queries
    return Promise.all([
      queryClient.invalidateQueries({
        queryKey: [useCustomerAddressesQuery.rootKey],
      }),
      queryClient.invalidateQueries({
        queryKey: addressQueryOptions.queryKey,
      }),
    ])
  }

const onSettledAddressHandler =
  ({
    location,
    isBff,
    mutation,
  }: {
    location: string
    isBff: boolean
    mutation: 'ADD' | 'UPDATE'
  }) =>
  (_: unknown, error: Error | null) => {
    track({
      eventName: AnalyticsEvent.AddressMutated,
      properties: {
        is_bff: isBff,
        is_success: !error,
        mutation_type: mutation,
        location,
        error_message: error?.message,
      },
    })
  }

export const useMutationAddAddressBff = (location: string) => {
  const queryClient = useQueryClient()

  const checkUserOptOut = !userIsDoNotShareSellTA()
  return useMutation({
    mutationFn: async (address: ValidAddress) => {
      const resp = await apiPost<AddressMutationBffResponse>({
        config: {
          url: 'member-web-bff/v1/address',
          data: { address, check_user_opt_out: checkUserOptOut },
        },
        options: {
          ignoredMessages: [
            /address not in service area/i,
            'delivery instructions must be 400 characters or less',
            'Please enter a valid address',
            "too many addresses, you're allowed a maximum of 100",
          ],
        },
        fetcherName: 'useMutationAddAddressBff',
      })

      // satisfy typescript, as there are consumers that expect address
      // to be returned from this mutation
      return { ...resp.address, user_opt_out: resp.user_opt_out }
    },
    onSuccess: onOptOutSuccessHandler(queryClient),
    onSettled: onSettledAddressHandler({
      isBff: true,
      location,
      mutation: 'ADD',
    }),
  })
}
export const useMutationUpdateAddressBff = <
  TAddress extends Partial<BaseAddress>
>(
  location: string
) => {
  const queryClient = useQueryClient()
  // if user is not already marked as do not share, tell backend to check
  const checkUserOptOut = !userIsDoNotShareSellTA()
  return useMutation({
    mutationFn: (address: TAddress) =>
      apiPatch<AddressMutationBffResponse>({
        config: {
          url: `member-web-bff/v1/address/${address.id}`,
          data: { address, check_user_opt_out: checkUserOptOut },
        },
        options: {
          ignoredMessages: [
            /address not in service area/i,
            'delivery instructions must be 400 characters or less',
          ],
        },
        fetcherName: 'useMutationUpdateAddressBff',
      }),
    onSuccess: onOptOutSuccessHandler(queryClient),
    onSettled: onSettledAddressHandler({
      isBff: true,
      location,
      mutation: 'UPDATE',
    }),
  })
}

export const useMutationUpdateAddress = <TAddress extends Partial<BaseAddress>>(
  location: string
) => {
  const { flags } = useFeatureFlags()
  const bff = useMutationUpdateAddressBff<TAddress>(location)
  const legacy = useMutationUpdateAddressLegacy<TAddress>(location)

  return flags.address_mutations_bff ? bff : legacy
}

export const useMutationAddAddress = (location: string) => {
  const { flags } = useFeatureFlags()
  const bff = useMutationAddAddressBff(location)
  const legacy = useMutationAddAddressLegacy(location)
  return flags.address_mutations_bff ? bff : legacy
}

export const useMutationDeleteAddress = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (addressId: number) =>
      apiDelete<DeleteAddressResponse>({
        config: {
          url: `api/v1/customer_addresses/${addressId}`,
        },
        fetcherName: 'useMutationDeleteAddress',
      }),
    onSuccess: (_, addressId) => {
      queryClient.setQueryData(addressQueryOptions.queryKey, (addresses) =>
        addresses?.filter((address) => address.id !== addressId)
      )
    },
  })
}
