// we can use useMedia here because a default state is specified
// so we won't run into server hydration issues
// eslint-disable-next-line no-restricted-imports
import { useMedia } from 'react-use'
import { screenSizes } from '@shipt/design-system-themes'
import { useHydrated } from '@/features/shared/components/ClientOnly'

const { max_mobile, tablet, smDesktop } = screenSizes

export const useMediaQuery = () => {
  const isHydrated = useHydrated()
  const screenSm = useMedia(max_mobile, isHydrated ? undefined : true)
  const screenMd = useMedia(tablet, isHydrated ? undefined : false)
  const screenLg = useMedia(smDesktop, isHydrated ? undefined : false)
  return { screenSm, screenMd, screenLg }
}
