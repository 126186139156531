import { type ParsedUrlQuery } from 'querystring'
import { isOnServer } from '@shared/constants/util'
import { parse, parseUrl, stringify } from '@/features/shared/utils/queryString'
import { type routes } from '@shared/constants/routes'

export const cleanUrl = () => {
  if (isOnServer()) return
  const currentUrl = window.location.toString()
  const search = parse(window.location.search)

  /**
   * Do not purge the following query string parameters:
   *  - `utm_` (used for tracking purposes)
   *  - `product_id` and `zip` (used to fetch universal product data on ungated PDPs)
   *  - `query` (required for returning SSO users within the guest experience)
   */
  const allowedParamsRegex =
    /^(utm_|product_id$|zip$|query$|planId$|student_email$)/
  const allowedParams = Object.keys(search).reduce<Record<string, unknown>>(
    (params, key) => {
      if (allowedParamsRegex.test(key)) {
        params[key] = search[key]
      }
      return params
    },
    {}
  )

  const baseUrl = currentUrl.substring(0, currentUrl.indexOf('?'))
  const queryString = stringify(allowedParams)
  const delimiter = queryString ? '?' : ''
  const replacementUrl = `${baseUrl}${delimiter}${queryString}`
  window.history.replaceState({}, '', replacementUrl)
}

export const cleanUrlOfRedirectParams = () => {
  if (isOnServer()) return
  const url = new URL(window.location.href)
  const currentUrl = url.pathname + url.search
  const search = parse(window.location.search)

  const redirectParams = ['redirect_count', 'redirect_from']
  const isEmpty = Object.keys(search).length === 0
  const searchContainsNoRedirectKeys = !Object.keys(search).some((key) =>
    redirectParams.includes(key)
  )
  if (isEmpty || searchContainsNoRedirectKeys) {
    return
  }

  const allowedParams = Object.keys(search).reduce<Record<string, unknown>>(
    (params, key) => {
      if (!redirectParams.includes(key)) {
        params[key] = search[key]
      }
      return params
    },
    {}
  )

  const baseUrl = currentUrl.substring(0, currentUrl.indexOf('?'))
  const queryString = stringify(allowedParams)
  const delimiter = queryString ? '?' : ''
  return `${baseUrl}${delimiter}${queryString}`
}

type pathnameTypes =
  | typeof routes.REDEEM_LOGIN.url
  | typeof routes.REDEEM_SIGNUP.url
  | typeof routes.REDEEM_PAYMENT.url
  | typeof routes.SIGN_UP.url
  | typeof routes.LOGIN.url

export const getUrlWithRedeemCode = (
  pathname: pathnameTypes,
  search = '',
  isRedeemFlow = false,
  redeemType = ''
) => {
  const params = new URLSearchParams(search)
  if (isRedeemFlow && redeemType) params.set('redeem', redeemType)
  const queryString = params.toString()

  return queryString ? `${pathname}?${queryString}` : pathname
}

export const getSearchQueryString = (query?: ParsedUrlQuery) => {
  if (!query || typeof query !== 'object' || Object.keys(query).length === 0)
    return ''
  return `?${stringify(query)}`
}

export const getUtmParams = (addReferrerUrl = true) => {
  const params = parse(window.location.search)
  const utmReferrer = document.referrer.includes('utm_')
    ? document.referrer
    : ''
  const utmParams = parseUrl(utmReferrer).query
  return {
    ...utmParams,
    ...params,
    ...(addReferrerUrl && {
      referrerUrl: window.location.href,
    }),
  }
}

interface NextUrlOptions {
  queryParam?: string
}
export const getUrlWithNextParam = (
  destination: string,
  nextUrl?: string,
  { queryParam = 'next' }: NextUrlOptions = {}
) => {
  if (!nextUrl || nextUrl.includes('/_next')) return destination
  return `${destination}?${queryParam}=${encodeURIComponent(nextUrl)}`
}

export const concatQueryStrings = (url: string, locationSearch: string) => {
  if (!locationSearch) return url

  if (url.includes('?')) {
    if (locationSearch.startsWith('?') || locationSearch.startsWith('&')) {
      return `${url}&${locationSearch.substring(1)}`
    }
    return `${url}&${locationSearch}`
  } else if (!locationSearch.startsWith('?')) {
    return `${url}?${locationSearch}`
  }
  return url + locationSearch
}

export const getNewUrlWithoutQueryParam = (
  queryString: string
): undefined | string => {
  const currentUrl = window.location.toString()
  const searchParams = parse(window.location.search)

  const queryParams = Object.keys(searchParams)

  if (!queryParams.some((param) => param === queryString)) return

  const filteredParams = queryParams.reduce<Record<string, unknown>>(
    (params, key) => {
      if (key !== queryString) {
        params[key] = searchParams[key]
      }
      return params
    },
    {}
  )

  const baseUrl = currentUrl.substring(0, currentUrl.indexOf('?'))
  const filteredQueryStrings = stringify(filteredParams)
  const delimiter = filteredQueryStrings ? '?' : ''
  return `${baseUrl}${delimiter}${filteredQueryStrings}`
}

/**
 * Returns true if the URL is a route controlled by Segway.
 * Note that tampering with this logic may cause CodeQL errors.
 * https://codeql.github.com/codeql-query-help/javascript/js-server-side-unvalidated-url-redirection/#example
 */
export function isSafeSegwayURL(url: string): boolean {
  if (url === '') return false
  // these are acceptable origins to match against
  // eslint-disable-next-line shipt/no-incorrect-shipt-url
  const trustedOrigins = new Set(['https://www.shipt.com', 'https://shipt.com'])

  try {
    const origin = new URL(url, 'https://www.shipt.com').origin
    return trustedOrigins.has(origin)
  } catch (e) {
    return false
  }
}
