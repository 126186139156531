import clone from 'lodash/clone'
import keyBy from 'lodash/keyBy'
import {
  type HttpFeatureFlagsResponse,
  type FeatureFlagKey,
  type FeatureFlags,
} from '@/features/shared/services/FeatureFlags/types'
import { defaultFlags } from '@/features/shared/services/FeatureFlags/constants'

export const isFlagTracked = (key: string): key is FeatureFlagKey => {
  // We're correctly checking for the existence of a key, we can ignore this TS error.
  // @ts-expect-error TS7053
  return typeof defaultFlags[key] === 'boolean'
}

export const cleanFlags = (
  data: HttpFeatureFlagsResponse | undefined
): FeatureFlags => {
  const flags: FeatureFlags = clone(defaultFlags)

  const serverFlags = keyBy(data?.FeatureFlags, 'Name')

  // use default flags if response doesn't match expected shape
  if (
    !serverFlags ||
    typeof serverFlags !== 'object' ||
    Object.keys(serverFlags).length === 0
  ) {
    return flags
  }

  for (const key in flags) {
    const value = serverFlags[key]?.Value

    // This function basically checks for existence of each flag with its key, scroll above to have a look
    if (!isFlagTracked(key)) continue
    if (value !== undefined) {
      flags[key] = value
    }
  }

  return flags
}
