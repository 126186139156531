import capitalize from 'lodash/capitalize'
import {
  type CouponAPI,
  type ClippedCouponAPI,
  type Coupon,
} from '@/features/shop/services/Coupons/types'
import { formatPrice } from '@/features/shared/utils/formatter'
import { formatExpiration } from '@/features/shared/utils/date'

export const normalizeCoupon = (coupon: CouponAPI): Coupon => {
  const {
    summary = '',
    disclaimer = '',
    description = '',
    coupon_id: couponId = '',
    coupon_image: couponImage = '',
    expired_at: expiredAt = '',
    clipped_at: clippedAt = '',
    face_value: faceValue = 0,
    coupon_provider: couponProvider = 'quotient',
    applied_product_quantity: appliedProductQty = 0,
  } = coupon
  return {
    summary,
    couponId,
    clippedAt,
    expiredAt,
    disclaimer,
    description,
    couponProvider,
    appliedProductQty,
    faceValue: faceValue / 100,
    ...(couponImage && { couponImage }),
  }
}

export const normalizeClippedCoupon = (coupon: ClippedCouponAPI): Coupon => {
  const {
    coupon_id: couponId = '',
    coupon_image: couponImage = '',
    clipped_at: clippedAt = '',
    face_value: faceValue = 0,
    expired_at: expiredAt = '',
    applied_product_quantity: appliedProductQty = 0,
    is_fulfilled: isFulfilled = false,
    coupon_provider: couponProvider = '',
    completion_percent: completionPercent = 0,
    qualified_product_ids_in_order,
  } = coupon

  return {
    couponId,
    clippedAt,
    expiredAt,
    appliedProductQty,
    faceValue: faceValue / 100,
    isFulfilled,
    couponProvider,
    completionPercent,
    qualifiedProductIdsInOrder: qualified_product_ids_in_order ?? [],
    ...(couponImage && { couponImage }),
  }
}

export const getCouponDetails = (coupon: Coupon) => {
  const {
    summary = '',
    expiredAt = '',
    faceValue = 0,
    appliedProductQty = 0,
  } = coupon || {}
  const formattedValue = formatPrice(faceValue)
  const formattedSummary = capitalize(summary)
  const hasMinimumQtyReq = appliedProductQty > 1
  const expirationDate = formatExpiration(expiredAt, 'MM/dd/yyyy')

  return {
    formattedValue,
    formattedSummary,
    hasMinimumQtyReq,
    expirationDate,
  }
}
