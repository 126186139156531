import { isObject } from '@/features/shared/utils/isObject'
import { type GraphQlOrderError } from '@/features/shop/services/Orders/types'

export const isGraphQlOrderError = (
  error: unknown
): error is GraphQlOrderError =>
  isObject(error) &&
  isObject(error.response) &&
  'errors' in error.response &&
  Array.isArray(error.response.errors)
