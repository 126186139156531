import { isObject } from '@/features/shared/utils/isObject'

export const combineCauseStack = (
  error: Error,
  cause: unknown
): string | undefined => {
  if (!(cause instanceof Error) || !cause.stack) {
    return error.stack
  }
  return `${error.stack}\n${cause.name}: ${cause.stack}`
}

export class ErrorWithCause extends Error {
  name = 'ErrorWithCause'

  constructor(message?: string, options?: ErrorOptions) {
    super(message, options)
    // Adding polyfill for pre-ES2022, if
    const cause = options?.cause
    if (cause && !('cause' in this)) {
      this.stack = combineCauseStack(this, cause)
      if (isObject(cause) && cause.cause) {
        this.stack = combineCauseStack(this, cause.cause)
      }
    }
  }
}

export class TrackError extends ErrorWithCause {
  name = 'TrackError'
}
