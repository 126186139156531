import { type GetServerSidePropsContext } from 'next'
import ServerCookies from 'cookies'
import { getSessionInfo } from '@shipt/analytics-member-web'
import { isJson } from '@/features/shared/utils/isJson'
import {
  getCookie,
  setCookie,
  removeCookie,
} from '@/features/shared/utils/cookies'
import { isOnServer } from '@shared/constants/util'

export const MARV_ADVICE_BY_CAMPAIGN = 'marv_advice_by_campaign'

type Props = {
  ssrContext: GetServerSidePropsContext | undefined
  marvAdviceByCampaign: Record<string, string>
}

export const updateMarvAdviceByCampaignCookie = ({
  ssrContext,
  marvAdviceByCampaign,
}: Props) => {
  let marvAdviceObj = { ...marvAdviceByCampaign }

  if (ssrContext) {
    const marvCookieValue = ssrContext.req.cookies?.[MARV_ADVICE_BY_CAMPAIGN]

    if (isJson(marvCookieValue)) {
      marvAdviceObj = { ...JSON.parse(marvCookieValue), ...marvAdviceObj }
    }
    new ServerCookies(ssrContext.req, ssrContext.res).set(
      MARV_ADVICE_BY_CAMPAIGN,
      JSON.stringify(marvAdviceObj),
      {
        httpOnly: false,
        maxAge: 365 * 24 * 60 * 60 * 1000, // 1 year, in milliseconds
      }
    )
  } else {
    const marvCookieValue = getCookie(MARV_ADVICE_BY_CAMPAIGN) ?? {}
    marvAdviceObj = { ...marvCookieValue, ...marvAdviceObj }

    setCookie(MARV_ADVICE_BY_CAMPAIGN, marvAdviceObj, { expires: 365 }) // 1 year, in days
  }
}

export const handleMarvAdviceCookieExpiration = () => {
  if (isOnServer()) return
  // we need to clear the MARV_ADVICE_BY_CAMPAIGN cookie's value if the user's session
  // is expired to ensure we don't send stale marv advice values in tracking events
  if (getSessionInfo()?.isSessionExpired) removeCookie(MARV_ADVICE_BY_CAMPAIGN)
}
