import { type PageRouteData } from '@/features/shared/utils/setPageProps'
import Bugsnag from '@bugsnag/js'

export function setGlobalContextFromRoute(routeData: PageRouteData) {
  const routeName = routeData?.routeName ?? 'UNKNOWN'
  Bugsnag.setContext(routeName)
  Bugsnag.addMetadata('page', {
    path: window.location.pathname,
    pageGroup: routeName,
  })
}
