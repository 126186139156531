import { atom } from 'jotai'
import { type QueryObserverBaseResult } from '@tanstack/react-query'
import { type AristotleExperiments } from '@/features/shared/services/Experiments/types'

type ExperimentsState = Pick<
  QueryObserverBaseResult<AristotleExperiments>,
  'isFetched' | 'isSuccess' | 'isPending' | 'data'
>

export const experimentsAtom = atom<ExperimentsState>({
  isFetched: false,
  isSuccess: false,
  isPending: true,
  data: undefined,
})

experimentsAtom.debugLabel = 'experimentsAtom'
