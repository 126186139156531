import { Children, useRef, useState, type ReactNode } from 'react'
import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Pagination, FreeMode } from 'swiper/modules'
import {
  type Swiper as SwiperType,
  type SwiperOptions,
  type PaginationOptions,
} from 'swiper/types'
import { NavButton } from '@/features/shop/components/Carousel/NavButton'
import styled from 'styled-components'
import { trackElementClicked } from '@/features/shared/analytics/element'
import { type SurfaceOption } from '@shipt/design-system-themes'
import { ThumbsCarousel } from '@/features/shop/components/Carousel/ThumbsCarousel'

SwiperCore.use?.([A11y, Pagination, FreeMode])

declare module 'swiper' {
  interface Swiper {
    // This is a property used internally by Swiper but not exposed in the types.
    slidesSizesGrid?: number[]
  }
}

type Props = Omit<SwiperOptions, 'pagination'> & {
  pagination?: PaginationOptions
  title?: string
  className?: string
  onSlideChange?: () => void
  shelfIndex?: number
  children: ReactNode
  navButtonSize?: 'md' | 'lg'
  surface?: SurfaceOption
  scrollOneCard?: boolean
  store_id?: number
}

const Carousel = ({
  children,
  title = '',
  className = '',
  onSlideChange,
  shelfIndex = 0,
  spaceBetween = 16,
  slidesPerView = 'auto',
  speed = 400,
  pagination,
  simulateTouch = false,
  grabCursor = false,
  freeMode = false,
  centeredSlides = false,
  centeredSlidesBounds = false,
  slidesOffsetAfter = 0,
  slidesOffsetBefore = 0,
  navButtonSize = 'lg',
  surface = 'default',
  scrollOneCard = false,
  breakpoints,
  store_id,
}: Props) => {
  const swiperInstanceRef = useRef<SwiperType | null>(null)
  const [isBeginningOfSlides, setIsBeginningOfSlides] = useState(true)
  const [isEndOfSlides, setIsEndOfSlides] = useState(true)

  const getNavStatus = () => {
    if (swiperInstanceRef.current) {
      setIsBeginningOfSlides(swiperInstanceRef.current.isBeginning)
      setIsEndOfSlides(swiperInstanceRef.current.isEnd)
    }
  }

  const slidesToScrollForward = () => {
    if (!swiperInstanceRef.current) {
      return 0
    }
    const {
      activeIndex,
      slidesSizesGrid = [],
      params: { spaceBetween: currentSpaceBetween = 0 },
    } = swiperInstanceRef.current
    let { width: widthLeft } = swiperInstanceRef.current

    if (
      (slidesSizesGrid[0] !== undefined && widthLeft < slidesSizesGrid[0]) ||
      scrollOneCard
    ) {
      return activeIndex + 1
    }

    return slidesSizesGrid.reduce((acc, slideWidth, index) => {
      if (
        acc < index &&
        (widthLeft -= slideWidth + Number(currentSpaceBetween)) >=
          0 - Number(currentSpaceBetween)
      ) {
        return index
      }
      return acc
    }, activeIndex)
  }

  const slidesToScrollBackward = () => {
    if (!swiperInstanceRef.current) {
      return 0
    }
    const {
      activeIndex,
      slidesSizesGrid = [],
      params: { spaceBetween: currentSpaceBetween = 0 },
    } = swiperInstanceRef.current
    let { width: widthLeft } = swiperInstanceRef.current

    if (
      (slidesSizesGrid[0] !== undefined && widthLeft < slidesSizesGrid[0]) ||
      scrollOneCard
    ) {
      return activeIndex - 1
    }

    return slidesSizesGrid.reduceRight((acc, slideWidth, index) => {
      if (
        acc > index &&
        (widthLeft -= slideWidth + Number(currentSpaceBetween)) >=
          0 - Number(currentSpaceBetween)
      ) {
        return index
      }
      return acc
    }, activeIndex)
  }

  const onNextClick = () => {
    const nextIndex = slidesToScrollForward()
    swiperInstanceRef.current?.slideTo(nextIndex)
    if (title) {
      trackElementClicked({
        type: 'right_arrow',
        shelf_name: title,
        shelf_index: shelfIndex,
        store_id,
      })
    }
  }

  const onPrevClick = () => {
    const prevIndex = slidesToScrollBackward()
    swiperInstanceRef.current?.slideTo(prevIndex)
    if (title) {
      trackElementClicked({
        type: 'left_arrow',
        shelf_name: title,
        shelf_index: shelfIndex,
        store_id,
      })
    }
  }

  return (
    <Wrapper>
      <CarouselSwiper
        className={`swiper ${className}`}
        a11y={{ enabled: true, scrollOnFocus: false }}
        breakpoints={breakpoints}
        centeredSlides={centeredSlides}
        centeredSlidesBounds={centeredSlidesBounds}
        freeMode={freeMode}
        grabCursor={grabCursor}
        onTransitionStart={() => {
          getNavStatus()
          onSlideChange?.()
        }}
        onResize={getNavStatus}
        onSwiper={(swiper) => {
          swiperInstanceRef.current = swiper
          getNavStatus()
        }}
        pagination={pagination}
        simulateTouch={simulateTouch}
        slidesPerView={slidesPerView}
        slidesOffsetAfter={slidesOffsetAfter}
        slidesOffsetBefore={slidesOffsetBefore}
        spaceBetween={spaceBetween}
        speed={speed}
        watchOverflow
      >
        {Children.map(children, (child) => {
          if (child) {
            return <SwiperSlide>{child}</SwiperSlide>
          }
        })}
        {pagination && <div className="swiper-pagination" />}
      </CarouselSwiper>
      <NavButton
        size={navButtonSize}
        hide={isBeginningOfSlides}
        surface={surface}
        direction="left"
        onClick={onPrevClick}
      />
      <NavButton
        size={navButtonSize}
        hide={isEndOfSlides}
        surface={surface}
        direction="right"
        onClick={onNextClick}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  overflow: visible;
`

const CarouselSwiper = styled(Swiper)`
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;

  .swiper-slide {
    display: flex;
    width: auto;
    transform: none;
  }

  .swiper-wrapper {
    display: flex;
    align-items: center;
  }
`

export { Carousel, ThumbsCarousel }
