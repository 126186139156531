import { useRouter } from 'next/router'
import styled from 'styled-components'
import { TitleClaremont1, Body1 } from '@/features/shared/styles/typography'
import { screenSizes } from '@shipt/design-system-themes'
import { routes } from '@shared/constants/routes'
import { Image } from '@/features/shared/elements/Image/NextImage'
import { Button } from '@shipt/design-system-buttons'
import { objectStorageUrl } from '@/features/shared/utils/objectStorageUrl'

type Props = {
  text: string
  subText?: string
  hideButton?: boolean
  buttonText?: string
  buttonAction?: () => void
  imageInfo?: {
    src: string
    alt: string
  }
  emptyStateRoute?: string
  className?: string
}

export const EmptyState = ({
  text,
  className,
  subText = '',
  hideButton = false,
  buttonText = 'Continue shopping',
  buttonAction,
  imageInfo = {
    src: objectStorageUrl('emptyStateBox.webp'),
    alt: 'Empty box',
  },
  emptyStateRoute = routes.SEARCH.url,
}: Props) => {
  const router = useRouter()
  const { src, alt } = imageInfo

  const handleButtonClick = () =>
    buttonAction ? buttonAction() : router.push(emptyStateRoute)

  return (
    <EmptyStateContainer className={className}>
      <EmptyStateImage
        src={src}
        alt={alt}
        width={375}
        height={236}
        style={{ objectFit: 'contain' }}
      />
      <HeaderText as="h2">{text}</HeaderText>
      <SubText>{subText}</SubText>
      {!hideButton && (
        <Button fullWidth onClick={handleButtonClick}>
          {buttonText}
        </Button>
      )}
    </EmptyStateContainer>
  )
}

const EmptyStateContainer = styled.div`
  text-align: center;
  display: flex;
  max-width: 21.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem auto;

  @media ${screenSizes.tablet} {
    margin: 2rem auto;
  }
`

const EmptyStateImage = styled(Image)`
  margin-bottom: 1rem;
`

const HeaderText = styled(TitleClaremont1)`
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.gray900};
`
const SubText = styled(Body1)`
  margin-bottom: 1rem;
  text-align: center;
  max-width: 21.5rem;
  color: ${({ theme }) => theme.gray600};
`
