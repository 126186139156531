import styled from 'styled-components'
import { fontWeights } from '@/features/shared/styles/typography/constants'
import { BaseText } from '@/features/shared/styles/typography/base.styled'

const { book } = fontWeights
/**
 * @deprecated Use { Body } from '@shipt/design-system-typography' instead
 */
export const Legal = styled(BaseText)`
  font-weight: ${book};
  line-height: 1rem;
  font-size: 0.8125rem;
  margin: 0;
`
