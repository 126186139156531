import { type QueryFunctionContext } from '@tanstack/react-query'
import { type CalculateSavingsResponse } from '@/features/shop/services/Coupons/types'
import { type CouponsSavingsQueryKey } from '@/features/shop/services/Coupons/queries'
import { toCouponsById } from '@/features/shop/services/Coupons/utils'
import {
  normalizeClippedCoupon,
  normalizeCoupon,
} from '@/features/shop/utils/normalizeCoupon'
import { apiPost } from '@/features/shared/utils/dataFetching'

export const fetchCouponsSavings = async (
  context: QueryFunctionContext<CouponsSavingsQueryKey>
) => {
  const [, { currentActiveOrderId, storeParams }] = context.queryKey
  const { metro_id, store_id, store_location_id, user_id } = storeParams ?? {}

  const response = await apiPost<CalculateSavingsResponse>({
    config: {
      url: 'coupon_service/v1/coupons.calculateSavings',
      params: {
        user_id,
        ...(currentActiveOrderId
          ? { order_id: currentActiveOrderId }
          : { context: `${store_id}_${metro_id}_${store_location_id}` }),
      },
    },
    context,
    fetcherName: 'fetchCouponsSavings',
  })
  const { clipped_coupons = [], unclipped_coupons = [] } =
    response?.savings ?? {}
  return toCouponsById([
    ...clipped_coupons.map(normalizeClippedCoupon),
    ...unclipped_coupons.map(normalizeCoupon),
  ])
}
