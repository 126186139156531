import {
  type FetchPackagesResponse,
  type DeliveryPlansPackage,
  type UnlimitedPurchaseResponse,
  type PlanMutationPayload,
} from '@/features/shop/services/DeliveryPlans/types'
import {
  type AuxMembershipPlan,
  type AuxPurchasePayload,
  type AuxPurchasePlan,
} from '@/features/account/services/SubscriptionAux/types'
import { apiPost } from '@/features/shared/utils/dataFetching'

export const normalizeSubAuxDeliveryPlans = (
  subAuxPlans: AuxMembershipPlan[]
): FetchPackagesResponse => {
  const packages: DeliveryPlansPackage[] = subAuxPlans.map((plan) => ({
    id: plan.id,
    total_cost_cents: plan.total_cost_cents,
    total_base_cents: plan.total_base_cents,
    unlimited_interval: plan.interval,
    text: {
      auto_renewal: {
        check_box: plan.display?.auto_renewal?.check_box,
        notice: plan.display?.auto_renewal?.notice,
        notes: plan.display?.auto_renewal?.notes,
      },
      promo_expiration: plan.display?.promo_expires_at,
      line_item_title: plan.display?.tracking_name,
    },
    renewal_cost_cents: plan.total_cost_cents,
    trial_period_days: plan.trial_period_days,
    plan_type: plan.plan_type,
    display: {
      includes_refund: plan.display.includes_refund,
      auto_renewal: plan.display.auto_renewal,
    },
  }))

  return {
    data: {
      packages,
    },
  }
}

export const normalizeSubAuxPurchase = (
  auxPurchasePlan: AuxPurchasePlan
): UnlimitedPurchaseResponse => {
  return {
    data: {
      subscription: {
        status: auxPurchasePlan.status,
        plan: auxPurchasePlan.plan,
        current_period_end: auxPurchasePlan.current_period_end,
        current_period_start: auxPurchasePlan.current_period_start,
        cancel_at_period_end: auxPurchasePlan.cancel_at_period_end,
        start: auxPurchasePlan.start,
        refundable: auxPurchasePlan.refundable,
        trial_end: auxPurchasePlan.trial_end,
        trial_start: auxPurchasePlan.trial_start,
        ...(auxPurchasePlan.discount && {
          discount: {
            coupon_id: auxPurchasePlan.discount.coupon_id,
          },
        }),
      },
    },
  }
}

export const getPrepaidAuxAPIConfig = (
  url: string,
  data: PlanMutationPayload | AuxPurchasePayload,
  fetcherName: string
): Parameters<typeof apiPost>[0] => {
  return {
    config: {
      url,
      data: { ...data, platform_source: 'web' },
    },
    options: {
      ignoredMessages: [
        'ineligible for membership upgrade',
        'Payment card was declined.',
      ],
    },
    fetcherName,
  }
}

export const getSubscriptionPurchaseResponse = async (
  isSubscriptionAux: boolean,
  package_id: PlanMutationPayload['package_id'],
  credit_card_id: PlanMutationPayload['credit_card_id'],
  fetcherName:
    | 'useMutationPurchaseDeliveryPlan'
    | 'useMutationUpgradeMembership'
    | 'useMutationDowngradeMembership'
): Promise<UnlimitedPurchaseResponse> => {
  if (isSubscriptionAux) {
    const response = await apiPost<AuxPurchasePlan>(
      getPrepaidAuxAPIConfig(
        `subscription-auxiliary/v1/plans/${package_id}`,
        { credit_card_id: credit_card_id.toString() },
        fetcherName
      )
    )
    return normalizeSubAuxPurchase(response)
  }
  const prepaidUrlRecord = {
    useMutationPurchaseDeliveryPlan: 'prepaid-shops/v1/packages/unlimited/buy',
    useMutationUpgradeMembership:
      'prepaid-shops/v1/packages/unlimited/upgrades',
    useMutationDowngradeMembership:
      'prepaid-shops/v1/packages/unlimited/downgrades',
  } as const
  const url = prepaidUrlRecord[fetcherName] ?? ''

  return apiPost<UnlimitedPurchaseResponse>({
    config: {
      url,
      data: { package_id, credit_card_id, platform_source: 'web' },
    },
    options: {
      ignoredMessages: [
        'ineligible for membership upgrade',
        'Payment card was declined.',
      ],
    },
    fetcherName,
  })
}
