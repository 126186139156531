import { queryOptions, useQuery } from '@tanstack/react-query'
import { minutesToMilliseconds } from 'date-fns/minutesToMilliseconds'
import { type SignupPlansPayload } from '@/features/shop/services/DeliveryPlans/types'
import { fetchSignupPlans } from '@/features/shop/services/DeliveryPlans/fetcher'
import { useSignupPlansQueryKeyParams } from '@/features/shop/services/DeliveryPlans/hooks'
import { fetchSubscriptionAuxEligiblePlans } from '@/features/account/services/SubscriptionAux/fetchers'
import { useFeatureFlags } from '@/features/shared/services/FeatureFlags/useFeatureFlags'
import { useUserId } from '@/features/account/services/User/hooks'

/**
 * useQueryDeliveryPlans
 */
export const DeliveryPlansQuery = 'DeliveryPlans'

const getDeliveryPlansQueryKey = () => [DeliveryPlansQuery] as const

export type DeliveryPlanQueryKey = ReturnType<typeof getDeliveryPlansQueryKey>

const getDeliveryPlansQueryOptions = () => ({
  staleTime: minutesToMilliseconds(15),
})

export const useQueryDeliveryPlans = () => {
  const userId = useUserId()

  const {
    flags: { web_pci_2025_popup_compliance: isSubscriptionAux },
    isFetched,
  } = useFeatureFlags()

  return useQuery({
    queryKey: getDeliveryPlansQueryKey(),
    queryFn: (context) =>
      fetchSubscriptionAuxEligiblePlans(
        context,
        isSubscriptionAux,
        'useQueryDeliveryPlans'
      ),
    ...getDeliveryPlansQueryOptions(),
    enabled: Boolean(userId && isFetched),
  })
}
useQueryDeliveryPlans.getKey = getDeliveryPlansQueryKey
useQueryDeliveryPlans.fetcher = fetchSubscriptionAuxEligiblePlans
useQueryDeliveryPlans.options = getDeliveryPlansQueryOptions

/**
 * useFetchDeliveryPlans
 */
export const SignupPlans = 'Signup plans'

export const getSignupPlansQueryKey = (params: SignupPlansPayload) =>
  [SignupPlans, { ...params }] as const

export type SignupPlansKey = ReturnType<typeof getSignupPlansQueryKey>

export const signupPlansQueryOptions = (params: SignupPlansPayload) =>
  queryOptions({
    queryKey: getSignupPlansQueryKey(params),
    queryFn: fetchSignupPlans,
  })

/**
 * useQuerySignupPlans
 */
export const useQuerySignupPlans = ({
  enabled = false,
}: {
  enabled: boolean
}) => {
  const { getQueryKeyParams } = useSignupPlansQueryKeyParams()
  const params = getQueryKeyParams()

  return useQuery({ ...signupPlansQueryOptions(params), enabled })
}

useQuerySignupPlans.getKey = getSignupPlansQueryKey
useQuerySignupPlans.fetcher = fetchSignupPlans
