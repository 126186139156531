import {
  SignupPlans,
  useQueryDeliveryPlans,
} from '@/features/shop/services/DeliveryPlans/queries'
import { useQueryClient } from '@tanstack/react-query'
import { type SignupPlansResponse } from '@/features/account/services/MembershipPlan/types'
import { shoppingStoresQueryOptions } from '@/features/shop/services/ShoppingStore/queries'
import { useSignupPromotionsParams } from '@/features/authentication/services/SignupPromotions/hooks'
import { useUserEmail } from '@/features/account/services/User/hooks'
import { useStoreParams } from '@/features/shared/utils/dataFetching/storeParams'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import {
  getAnnualPlan,
  getMonthlyPlan,
  getSingleDeliveryFee,
  getSnapEBTPlan,
  getStudentPlan,
  getTrialEligible,
} from '@/features/shop/services/DeliveryPlans/utils'

export const useDeliveryPlans = () => {
  const { data } = useQueryDeliveryPlans()
  return data?.data?.packages ?? []
}

export const useSingleDeliveryFee = () => {
  const { data } = useQueryDeliveryPlans()
  const { singleDeliveryFee } = getSingleDeliveryFee(data)
  return { singleDeliveryFee, isLoading: !data }
}

export const useAnnualPlan = () => {
  const plans = useDeliveryPlans()
  return getAnnualPlan(plans)
}

export const useMonthlyPlan = () => {
  const plans = useDeliveryPlans()
  return getMonthlyPlan(plans)
}

export const useStudentPlan = () => {
  const plans = useDeliveryPlans()
  return getStudentPlan(plans)
}

export const useSnapEBTPlan = () => {
  const plans = useDeliveryPlans()
  return getSnapEBTPlan(plans)
}

export const useTrialEligible = () => {
  const plans = useDeliveryPlans()
  return getTrialEligible(plans)
}

export const useSignupPlans = () => {
  const router = useRouter()
  const queryClient = useQueryClient()
  const { getQueryKeyParams } = useSignupPlansQueryKeyParams()
  const data = queryClient.getQueryData<SignupPlansResponse>([
    SignupPlans,
    getQueryKeyParams(String(router.query.email || '')),
  ])
  return { data }
}

export const useMetroIdForSignupPlans = () => {
  const queryClient = useQueryClient()
  const { zipParam } = useSignupPromotionsParams()
  const data = queryClient.getQueryData(
    shoppingStoresQueryOptions({ address_zip_code: zipParam }).queryKey
  )
  return data?.delivery_stores?.[0]?.metro_id ?? 0
}

/** used for during sign up and log in only where sign up params are derived from just URL query params */
export const useGetInitialSignupQueryParams = () => {
  const router = useRouter()
  const {
    param = '',
    referrerUrl = '',
    zip: zipParam = '',
    zipcode = '',
  } = router.query

  const getInitialSignupParams = useCallback(
    (email = '') => {
      return {
        email: email.toLowerCase(),
        zip_code: String(zipParam || zipcode),
        param: String(param),
        referring_url: String(referrerUrl),
      }
    },
    [param, referrerUrl, zipParam, zipcode]
  )

  return { getInitialSignupParams }
}

/** used for post sign up and log in only where sign up params are derived from both URL query params and user data */
export const useSignupPlansQueryKeyParams = () => {
  const { getInitialSignupParams } = useGetInitialSignupQueryParams()
  const storeParams = useStoreParams()
  const { metro_id, zip } = storeParams
  const userEmail = useUserEmail()
  const metroId = useMetroIdForSignupPlans() || metro_id

  const getQueryKeyParams = useCallback(
    (email = '') => {
      const initialSignupParams = getInitialSignupParams(email)
      return {
        ...initialSignupParams,
        email: userEmail || initialSignupParams.email,
        metro_id: metroId,
        zip_code: initialSignupParams.zip_code || zip,
      }
    },
    [getInitialSignupParams, metroId, userEmail, zip]
  )

  return { getQueryKeyParams }
}
