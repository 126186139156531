import { type CreditCard } from '@/features/purchase/services/CreditCards/types'

type BasePaymentMethod = {
  paymentMethodId: string
  external_id?: string
  verification_required?: boolean
}

export type PaymentMethod =
  | (BasePaymentMethod & {
      option: 'payment_wallet'
      paymentWalletType: PaymentWalletType
    })
  | (CreditCard & BasePaymentMethod & { option: 'credit_card' })

export enum PaymentWalletType {
  GooglePay = 'Google Pay',
  ApplePay = 'Apple Pay',
}

export enum CardBrand {
  DinersClub = 'Diners Club',
  Mastercard = 'Mastercard',
  UnionPay = 'UnionPay',
  Visa = 'Visa',
  AmericanExpress = 'American Express',
  Discover = 'Discover',
  JCB = 'JCB',
  EBT = 'EBT',
  TargetMastercard = 'Target Mastercard',
  Link = 'link',
  Klarna = 'klarna',
  CashApp = 'cashapp',
}
