import { ExternalLink, NextLink } from '@/features/shared/elements/Link'
import { Body1 } from '@/features/shared/styles/typography'
import { type FooterData } from '@/features/shared/components/Footers/MarketingFooter/types'

type Props = {
  links: FooterData['sections'][number]['links']
}

export const MarketingFooterLinks = ({ links }: Props) => (
  <>
    {links.map(({ name, url, target, external }) =>
      external ? (
        <ExternalLink key={name} href={url} target={target} $underline="none">
          <Body1 color="plum">{name}</Body1>
        </ExternalLink>
      ) : (
        <NextLink key={name} href={url} target={target} prefetch={false}>
          <Body1 color="plum">{name}</Body1>
        </NextLink>
      )
    )}
  </>
)
