import { useQuery, queryOptions } from '@tanstack/react-query'
import { type QueryOptions } from '@/features/shared/utils/dataFetching/types'
import { fetchOptOutRestrictions } from '@/features/account/services/DataPrivacy/fetchers'
import { useGlobalDefaultShoppingAddress } from '@/features/account/services/Addresses/hooks'
import { useSession } from '@/features/authentication/utils/authentication/hooks'
import { useUser } from '@/features/account/services/User/hooks'
import { userIsDoNotShareSellTA } from '@/features/account/services/DataPrivacy/utils'

type OptoutRestrictionsParams = { state?: string; zip_code?: string }

export type OptoutRestrictionsQueryKey = ReturnType<
  typeof getOptoutRestrictionsQueryKey
>

const getOptoutRestrictionsQueryKey = (params: OptoutRestrictionsParams) =>
  ['opt-out-restrictions', params] as const

export const restrictionsQueryOptions = (params: OptoutRestrictionsParams) =>
  queryOptions({
    queryKey: getOptoutRestrictionsQueryKey(params),
    queryFn: fetchOptOutRestrictions,
  })

export const useQueryOptOutRestrictions = (options?: QueryOptions) => {
  const { zip_code, state } = useGlobalDefaultShoppingAddress()
  const { isSessionValid } = useSession()
  const user = useUser()
  const userHasNotOptedOut = !userIsDoNotShareSellTA()

  const isQueryEnabled =
    userHasNotOptedOut &&
    Boolean(!isSessionValid || (user?.id && !user.default_shopping_address_id))

  return useQuery({
    ...restrictionsQueryOptions({ state, zip_code }),
    ...options,
    enabled: isQueryEnabled,
  })
}
