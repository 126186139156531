import { screenSizes } from '@shipt/design-system-themes'
import { Body1, TitleClaremont1 } from '@/features/shared/styles/typography'
import { Image } from '@/features/shared/elements/Image/NextImage'
import styled from 'styled-components'
import { AppBadges } from '@/features/shared/components/AppBadges'
import { objectStorageUrl } from '@/features/shared/utils/objectStorageUrl'

export const MobileAppDownload = () => {
  return (
    <SectionWrapper>
      <ImageWrapper>
        <Image
          alt="App Download Image"
          src={objectStorageUrl('phone-check.webp')}
          width={1108}
          height={1108}
          style={{ width: '100%', height: '100%' }}
        />
      </ImageWrapper>
      <ContentContainer>
        <ContentWrapper>
          <Title>
            Cross off shopping from your to-do list. Download Shipt.
          </Title>
          <Description>
            Whatever you need from wherever you shop, get it delivered with
            Shipt.
          </Description>
          <MobileAppBadges openInNewTab isShopper={false} />
        </ContentWrapper>
      </ContentContainer>
    </SectionWrapper>
  )
}

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 0;

  @media ${screenSizes.tablet} {
    flex-direction: row;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-basis: 50%;

  @media ${screenSizes.lgDesktop} {
    width: 50%;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-basis: 50%;
  align-items: center;

  @media ${screenSizes.lgDesktop} {
    padding-left: 3rem;
    width: 50%;
  }
`

const ContentWrapper = styled.div`
  margin: 0 1.5rem;

  @media ${screenSizes.tablet} {
    width: 31.4375rem;
    margin-left: 3.6875rem;
  }

  @media ${screenSizes.only_tablet} {
    width: 17.3125rem;
  }

  @media ${screenSizes.smDesktop} {
    margin-left: 5.0625rem;
  }

  @media ${screenSizes.lgDesktop} {
    margin-left: 6.875rem;
  }
`

const Title = styled(TitleClaremont1)`
  color: ${({ theme }) => theme.plum};
  margin-bottom: 1rem;

  @media ${screenSizes.max_mobile} {
    margin-top: 3.125rem;
  }

  @media ${screenSizes.tablet} {
    max-width: 23.125rem;
  }

  @media ${screenSizes.smDesktop} {
    font-size: 2.5rem;
    line-height: 3rem;
    max-width: 26.25rem;
  }
`

const Description = styled(Body1)`
  color: ${({ theme }) => theme.plum};

  @media ${screenSizes.smDesktop} {
    max-width: 22.5rem;
  }
`

const MobileAppBadges = styled(AppBadges)`
  margin-top: 1.5rem;
  margin-bottom: 3.125rem;

  @media ${screenSizes.tablet} {
    margin-bottom: 0;
  }
`
