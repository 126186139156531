export const claimNamespace = 'https://www.shipt.com/'

export const customClaims = {
  ONBOARDED: `${claimNamespace}member_onboarded`,
  SHIPT_USER_ID: `${claimNamespace}shipt_user_id`,
  DESTINATION_URL: `${claimNamespace}destination_url`,
}

// Keep in sync with `eslint-plugin-shipt/no-hardcoded-routes.js`
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable shipt/no-hardcoded-routes */
export const authRoutes = {
  LOGIN: '/api/auth/login',
  LOGOUT: '/api/auth/logout',
  SIGN_UP: '/api/auth/signup',
  TOKEN: '/api/auth/token',
  ME: '/api/auth/me',
  MFA_TOKEN: '/api/auth/mfa_token',
} as const

export enum Auth0Scope {
  OPENID = 'openid',
  PROFILE = 'profile',
  EMAIL = 'email',
  IS_CUSTOMER = 'is:Customer',
  MANAGE_ACCOUNT = 'manage:account',
}

export const MFA_STEP_UP_ERROR_MESSAGE =
  'The user signed into a different account during MFA step-up'
